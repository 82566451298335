import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';



export const useFetchTicketAll = (startDate, endDate) => {
  return useQuery(
    ['getFetchTicketAll', startDate, endDate], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/ticket/get-tickets-all?startDate=${startDate}&endDate=${endDate}`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useFetchTicketCustomerAll = (startDate, endDate) => {
  return useQuery(
    ['getFetchTicketCustomerAll', startDate, endDate], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/ticket/get-tickets-customer-all?startDate=${startDate}&endDate=${endDate}`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useMutationCancelTicket = () => {
  return useMutation(["cancel-ticket"],
    async (payload) => {
      console.log(payload)
      const endPoin = `/api/v2/ticket/cancel-ticket`;
      await POST(endPoin,payload);
    },
    {
      cacheTime: 0,
    }
  );
};
