import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import takeketLogo from "../../assets/images/logo/takeket.png";
import { useMutationLogin } from '../../hooks/useAuthen';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const { mutate: fetchLogin, isLoading: isLoadingLogin } = useMutationLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, []);

  const onFinish = async (values) => {
    try {
      fetchLogin(values);
    } catch (error) {
      message.error(error?.response?.data?.message||error.message);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forget-password');  // นำทางไปยังหน้าลืมรหัสผ่าน
  };

  return (
    <div className="bg-gray-50">
      <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
        <div className="max-w-md w-full">
          <div className="flex justify-center items-center mr-4 mb-10">
            <a href="#">
              <img
                src={takeketLogo}
                alt="logo"
                className="w-[230px]"
              />
            </a>
          </div>
          <div className="flex justify-center items-center mb-4">
            <div className="text-center">
              <p className="text-xl">เข้าสู่ระบบบัญชีของคุณ</p>
              <p className="text-gray-500 text-lg">ยินดีต้อนรับกลับ กรุณากรอกรายละเอียดของคุณ</p>
            </div>
          </div>
          <div className="p-8 rounded-2xl bg-white shadow">
            <Form
              name="login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่อีเมล์ของคุณ!',
                  },
                  {
                    type: 'email',
                    message: 'กรุณากรอกฟอร์แมตอีเมลที่ถูกต้อง!',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon h-10" />}
                  placeholder="อีเมล"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "กรุณาใส่รหัสผ่านของคุณ!" }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon h-10" />}
                  type="password"
                  placeholder="รหัสผ่าน"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button w-full h-10 text-lg"
                  loading={isLoadingLogin}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>

              {/* ปุ่มลืมรหัสผ่าน */}
              <Form.Item>
                <Button
                  type="link"
                  className="w-full text-lg"
                  onClick={handleForgotPassword}
                >
                  ลืมรหัสผ่าน?
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
