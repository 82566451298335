import axios from 'axios';
import { getCookies } from '../store/useCookies';

export const CONNECT_API = process.env.REACT_APP_DOMAIN;
export const CONNECT_API_UPLOAD_IMAGE = process.env.REACT_APP_UPLAOD;


const api = axios.create({
  baseURL: CONNECT_API,
//   withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000, // 10 seconds
});

const apiupload = axios.create({
  baseURL: CONNECT_API_UPLOAD_IMAGE,
//   withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj,token = localStorage.getItem('token')) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.statusText });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = localStorage.getItem('token')) =>
  new Promise((resolve, reject) => {
    api
      .get(path, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.statusText });
        }
      })
      .catch((err) => reject(err));
  });

  export const DELETE = (path, token = localStorage.getItem('token')) =>
    new Promise((resolve, reject) => {
      api
        .delete(path, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            resolve(response.data);
          } else {
            response.data
              ? reject(response.data)
              : reject({ success: false, message: response.statusText });
          }
        })
        .catch((err) => reject(err));
    });

  /**
 * ฟังก์ชั่นสำหรับ อัปเดตข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const PUT = (path, obj, token = localStorage.getItem('token')) =>
  new Promise((resolve, reject) => {
    api
      .put(path, obj, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.statusText });
        }
      })
      .catch((err) => reject(err));
  });


/**
 * ฟังก์ชั่นสำหรับ อัพโหลดไฟล์
 * @param {string} path
 * @param {FormData} formdata
 * @return {{}}
 */
export const UPLOAD = (path, formdata,token = localStorage.getItem('token')) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata, {
        // headers: {
        //   Authorization: token,
        // },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.statusText });
        }
      })
      .catch((err) => reject(err));
  });

// import { create } from 'apisauce';
// import { getCookies } from '../store/useCookies';
// export const ip = 'http://192.168.1.169:2000';

// export const CONNECT_API = 'http://api.one2tickets.com/api'
// const api = create({
//   baseURL: CONNECT_API,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   timeout: 10000, // 10 seconds
// });

// const apiupload = create({
//   baseURL: CONNECT_API,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'multipart/form-data',
//   },
// });

// /**
//  * ฟังก์ชั่นสำหรับ ยิงข้อมูล
//  * @param {string} path
//  * @param {{}} obj
//  * @return {{}}
//  */
// export const POST = (path, obj, token = getCookies('accessToken')) =>
//   new Promise((resolve, reject) => {
//     api
//       .post(path, obj, {
//         headers: {
//           Authorization: token,
//         },
//       })
//       .then((response) => {
//         if (response.ok) {
//           resolve(response.data);
//         } else {
//           response.data
//             ? reject(response.data)
//             : reject({ success: false, message: response.problem });
//         }
//       })
//       .catch((err) => reject(err));
//   });

// /**
//  * ฟังก์ชั่นสำหรับ ดึงข้อมูล
//  * @param {string} path
//  *
//  */
// export const GET = (path, token = getCookies('accessToken')) =>
//   new Promise((resolve, reject) => {
//     api
//       .get(
//         path,
//         {},
//         {
//           headers: {
//             Authorization: token,
//           },
//         },
//       )
//       .then((response) => {
//         if (response.ok) {
//           resolve(response.data);
//         } else {
//           response.data
//             ? reject(response.data)
//             : reject({ success: false, message: response.problem });
//         }
//       })
//       .catch((err) => reject(err));
//   });

// export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
//   new Promise((resolve, reject) => {
//     apiupload
//       .post(path, formdata, {
//         headers: {
//           Authorization: token,
//         },
//       })
//       .then((response) => {
//         if (response.ok) {
//           resolve(response.data);
//         } else {
//           response.data
//             ? reject(response.data)
//             : reject({ success: false, message: response.problem });
//         }
//       })
//       .catch((err) => reject(err));
//   });
