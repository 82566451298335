import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';

export const useFetchPromotionAll = () => {
    return useQuery(["getPromotionAll"], async () => {
      const endPoin = `/api/v1/discountCodes/get-discount-codes-all`;
      const { result } = await GET(endPoin);
      // const {result} = scheduleData
      return result;
    },{
      cacheTime:0
    }
  );
};


export const useMutationPromotion = () => {
  return useMutation(["addPromotion"],
    async (payload) => {
      const endPoin = `/api/v1/discountCodes/add-discount-codes`
      await POST(endPoin,payload);
    },
  );
};

export const useMutationUpdatePromotion = () => {
  return useMutation(["updatePromotion"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v1/discountCodes/${id}`;
      await PUT(endPoin,body);
    }
  );
};

export const useMutationDeletePromotion = () => {
  return useMutation(["deletePromotion"],
    async (payload) => {
      const endPoin = `/api/v1/discountCodes/${payload}`;
      await DELETE(endPoin);
    }
  );
};