import React from 'react';
import { FiShoppingBag, FiUsers, FiUser, FiMapPin, FiPercent,FiTag } from 'react-icons/fi';
import { RiContactsLine, RiMoneyDollarCircleLine, RiBarChart2Line, RiFileTextLine, RiBusLine, RiTimeLine } from 'react-icons/ri';
import { AiOutlineBarChart, AiOutlineTeam,AiOutlineTicket,AiOutlineFieldTime,AiOutlineUserSwitch,AiOutlineUser,AiOutlineUserAdd} from 'react-icons/ai';
import { RiPieChartLine, RiBankLine, RiShoppingCart2Line, RiHistoryLine } from 'react-icons/ri';
import { MdCommute } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import { BsBarChart,BsCardChecklist,BsCoin } from 'react-icons/bs';
import { IoTrendingUpSharp } from 'react-icons/io5';










export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        route: 'dashboard',
        name: 'ข้อมูล',
        icon: <BsBarChart className='text-xl' />,
      },
    ],
  },
  {
    title: 'Pages',
    links: [
      {
        route:'payments',
        name: 'การเงิน',
        icon: <BsCoin  className='text-xl'/>,
        subLinks: [
          {
            route: 'payment-info',
            name: 'ข้อมูลยอดเงิน',
            icon: <RiPieChartLine  className='text-xl'/>,
          },
          {
            route: 'payment-history-receiving-money',
            name: 'ประวัติการรับเงิน',
            icon: <RiHistoryLine  className='text-xl'/>,
          },
          {
            route: 'payment-bank-info',
            name: 'ข้อมูลธนาคาร',
            icon: <RiBankLine className='text-xl' />,
          },
          {
            route: 'payment-sale-info',
            name: 'ข้อมูลการขาย',
            icon: <IoTrendingUpSharp  className='text-xl'/>,
          },
        ],
      },
      {
        route: 'employees',
        name: 'พนักงาน',
        icon: <HiUsers  className='text-xl'/>,
      },
      {
        route: 'customers',
        name: 'ลูกค้า',
        icon: <FiUser  className='text-xl'/>,
      },
      {
        name: 'ตัวแทนขาย',
        icon: <AiOutlineTeam className='text-xl' />,
        subLinks: [
          {
            route: 'my-agent',
            name: 'ตัวแทนขายของฉัน',
            icon:<AiOutlineUser  className='text-xl'/>,
          },
          {
            route: 'agent',
            name: 'รายชื่อตัวแทนขายทั้งหมด',
            icon: <AiOutlineUserSwitch className='text-xl' />,
          },
          {
            route: 'agent-requests',
            name: 'รายการขอ',
            icon: <AiOutlineUserAdd  className='text-xl'/>,
          },
        ],
      },
      {
        route: 'location',
        name: 'สถานที่',
        icon: <FiMapPin  className='text-xl'/>,
      },
      {
        route: 'vehicles',
        name: 'ยานพาหนะ',
        icon: <MdCommute  className='text-xl'/>,
      },
      {
        route: 'schedule',
        name: 'รอบโดยสาร',
        icon: <AiOutlineFieldTime  className='text-xl'/>,
      },
      {
        route: 'ticket',
        name: 'บัตรโดยสาร',
        icon: <BsCardChecklist  className='text-xl'/>,
      },
      {
        route: 'promotion',
        name: 'โปรโมชั่น ส่วนลด',
        icon: <FiPercent  className='text-xl'/>,
      },
    ],
  },
];



export const linksDefault = [
  {
    title: '',
    links: [
      {
        route: 'dashboard',
        name: 'ข้อมูล',
        icon: <BsBarChart className='text-xl' />,
      },
    ],
  },
  {
    title: 'Pages',
    links: [
      {
        route: '',
        name: 'พนักงาน',
        icon: <HiUsers  className='text-xl'/>,
      },
    ],
  },
];
