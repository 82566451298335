import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';
import { message } from 'antd';

export const useFetchCustomerAll = (startDate, endDate) => {
  return useQuery(
    ['getCustomerAll'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/customer/get-customer-all`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useMutationAddCustomer = () => {
  return useMutation(["addCustomer"],
    async (payload) => {
     const path = `/api/v2/customer/create-customer`
      const endPoin = path;
      await POST(endPoin,payload);
    }
  );
};

export const useMutationDeleteCustomer = () => {
  return useMutation(["deleteCustomer"],
    async (payload) => {
      const endPoin = `/api/v2/customer/delete-customer/${payload}`;
      await DELETE(endPoin);
    }
  );
};



export const useMutationUpdateCustomer = () => {
  return useMutation(["updateCustomer"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v2/customer/update-customer/${id}`;
      await PUT(endPoin,body);
    }
  );
};