import React from 'react';
import { Form, Input, Select, Upload,Button } from 'antd';
import {provinces} from "../../data/provinces";
import { UploadOutlined } from '@ant-design/icons';
import UploadImageAvatar from "../Upload/UploadImage/UploadImageAvatar";

const { Option } = Select;

function FormCustomer({ formRef, onFinish, uploadImage, initialValues = {} }) {
  return (
    <Form name="customer" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues} className="w-full">
     
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Form.Item
              name="type"
              label="ประเภท"
              rules={[{ required: true, message: 'กรุณาเลือกประเภท' }]}
              className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
            >
          <Select >
            <Option value="company">บริษัท</Option>
            <Option value="persons">นิติบุคคล</Option>
          </Select>
        </Form.Item>

        <Form.Item name="picture">
           <UploadImageAvatar
           title='รูปภาพ'
            key={1}
            onUpload={uploadImage}
            file={initialValues?.picture}
            listType='picture-card'
          />
        </Form.Item>
       </ div>
       <div className="grid grid-cols-1">
         <Form.Item
          name="company"
          label="บริษัท"
          rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
        <Input
            id="tradername"
            placeholder="บริษัท"
          />
        </Form.Item>
       </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Form.Item
          name="name"
          label="ชื่อ"
          rules={[{ required: true, message: 'กรุณาระบุชื่อ' }]}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Input placeholder="ชื่อ" />
        </Form.Item>

        <Form.Item
          name="surname" 
          label="นามสกุล"
          rules={[{ required: true, message: 'กรุณาระบุ นามสกุล' }]}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="address"
          label="ที่อยู่"
          rules={[{ required: true, message: 'กรุณาระบุที่อยู่' }]}
          className="col-span-1 md:col-span-2 block mb-2 text-sm font-medium text-gray-700"
        >
          <Input.TextArea placeholder="ที่อยู่" />
        </Form.Item>

        <Form.Item
          name="taxId"
          label="เลขประจำตัวผู้เสียภาษี/เลขบัตรประชาชน"
          // rules={[{ required: true, message: 'กรุณาระบุเลขประจำตัวผู้เสียภาษี/เลขบัตรประชาชน' }]}
          className="col-span-1 md:col-span-2 block mb-2 text-sm font-medium text-gray-700"
        >
          <Input placeholder="เลขประจำตัวผู้เสียภาษี/เลขบัตรประชาชน" />
        </Form.Item>

        <Form.Item
          name="certDocument"
          label="เอกสาร หนังสือรับรอง/บัตรประชาชน"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Upload name="certDocument" listType="picture">
          <Button  disabled={true} icon={<UploadOutlined />}>คลิกเพื่ออัปโหลด</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="contractDocument"
          label="เอกสารสัญญา"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Upload name="contractDocument" listType="picture">
          <Button  disabled={true} icon={<UploadOutlined />}>คลิกเพื่ออัปโหลด</Button>
          </Upload>
        </Form.Item>

          <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="province" label="จังหวัด" rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}>        
            <Select
            placeholder="เลือกจังหวัด"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {provinces.map(province => (
              <Select.Option key={province} value={province}>
                {province}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="businessType"
          label="ประเภทธุรกิจ"
          rules={[{ required: true, message: 'กรุณาเลือกประเภทธุรกิจ' }]}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Select >
            <Option value="เรือโดยสารข้ามฝาก">เรือโดยสารข้ามฝาก</Option>
            {/* เพิ่มประเภทธุรกิจอื่นๆ */}
          </Select>
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email', message: 'กรุณาระบุอีเมลที่ถูกต้อง' }]}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Input placeholder="Email"  />
        </Form.Item>

        <Form.Item
          name="mobile"
          label="โทรศัพท์"
          rules={[{ required: true, message: 'กรุณาระบุเบอร์โทรศัพท์' }]}
          className="col-span-1 block mb-2 text-sm font-medium text-gray-700"
        >
          <Input addonBefore="+66" placeholder="811234567" />
        </Form.Item>
      </div>

    
    </Form>
  );
}

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export default FormCustomer;

// import React from 'react';
// import { Form, Input, Select, Checkbox, InputNumber } from 'antd';
// import {provinces} from "../../data/provinces";
// import UploadImageAvatar from "../Upload/UploadImage/UploadImageAvatar";

// function FormCustomer({ formRef, onFinish, uploadImage,initialValues={},edit=false }) {
//   console.log(edit)

//   return (
//     <Form name="picture" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
//        <div className='flex justify-center items-center'>
//         <Form.Item name="picture">
//           <UploadImageAvatar
//           title='รูปภาพ'
//             key={1}
//             onUpload={uploadImage}
//             file={initialValues?.picture}
//           />
//           </Form.Item>
//        </div>
//       <div className='grid lg:gap-x-5 lg:grid-cols-1 '>

       
//         <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="name" label="ชื่อ" rules={[{ required: true, message: 'กรุณาระบุชื่อ' }]}>
//         <Input
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="ชื่อ"
            
//         />
//         </Form.Item>
//         <Form.Item className='className="block mb-2 text-sm font-medium text-gray-700' name="surname" label="นามสกุล" rules={[{ required: true, message: 'กรุณาระบุนามสกุล' }]}>
//           <Input 
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="นามสกุล"
//             />
//         </Form.Item>

//         <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="company" label="บริษัท" rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}>
//           <Input
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="บริษัท"
//           />
//         </Form.Item>

//            {/* เพิ่ม Select สำหรับเลือกจังหวัด */}
//         <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="province" label="จังหวัด" rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}>
//           <Select
//             placeholder="เลือกจังหวัด"
//             className="w-full h-10 rounded-lg" 
//             showSearch
//             optionFilterProp="children"
//             filterOption={(input, option) =>
//               option.children.toLowerCase().includes(input.toLowerCase())
//             }
//           >
//             {provinces.map(province => (
//               <Select.Option key={province} value={province}>
//                 {province}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>

//         <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="email" label="อีเมล์" rules={[{ required: true, message: 'กรุณาระบุอีเมล์' }]}>
//           <Input
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="อีเมล์"
//           />
//         </Form.Item>
//        {/* <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="password" label="รหัสผ่าน" rules={[{ required: edit? false:true, message: 'กรุณาระบุรหัสผ่าน' }]}>
//           <Input.Password
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="รหัสผ่าน"
//           />
//         </Form.Item> */}


       
//       </div>
     
//     </Form>
//   );
// }

// export default FormCustomer;
