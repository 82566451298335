import React, { useState } from 'react';
import { Table, Space, Popconfirm, Select } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';

const { Option } = Select;

const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const BankTable = ({ data, onEdit, onDelete }) => {
  const { currentColor } = useStateContext();

  // State for filtering
  const [filteredCompany, setFilteredCompany] = useState('ทั้งหมด');
  const [filteredProvince, setFilteredProvince] = useState('ทั้งหมด');

  // Get unique companies and provinces for filters
  const companies = ['ทั้งหมด', ...new Set(data?.map((item) => item.nameCompany))];
  const provinces = ['ทั้งหมด', ...new Set(data?.map((item) => item.province))];

  // Filtered data
  const filteredData = data?.filter((item) => {
    return (
      (filteredCompany === 'ทั้งหมด' || item.nameCompany === filteredCompany) &&
      (filteredProvince === 'ทั้งหมด' || item.province === filteredProvince)
    );
  });

  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'ชื่อบริษัท', 
      align: 'center',
      dataIndex: 'nameCompany', 
      key: 'nameCompany', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'จังหวัด', 
      align: 'center', 
      dataIndex: 'province', 
      key: 'province',
      onHeaderCell: () => ({ style: headerStyle }) 
    },
    { 
      title: 'ค่าคอมมิชชั่น (%)', 
      align: 'center', 
      dataIndex: 'commission', 
      key: 'commission',
      onHeaderCell: () => ({ style: headerStyle }) 
    },
    { 
      title: 'ธนาคาร', 
      align: 'center', 
      dataIndex: 'name', 
      key: 'name',
      onHeaderCell: () => ({ style: headerStyle }) 
    },
    { 
      title: 'เลขบัญชีธนาคาร', 
      align: 'center', 
      dataIndex: 'accountNumber', 
      key: 'accountNumber',
      onHeaderCell: () => ({ style: headerStyle }) 
    },
    { 
      title: 'เจ้าของบัญชี', 
      align: 'center', 
      dataIndex: 'accountHolderName', 
      key: 'accountHolderName',
      onHeaderCell: () => ({ style: headerStyle }) 
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>
          <Popconfirm
            title="ลบข้อมูลธนาคาร"
            description="ยืนยันการลบข้อมูลธนาคาร ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
            <div className="text-red-500 cursor-pointer">
              ลบ
            </div>
          </Popconfirm>
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return (
    <div>
      <div className="mb-4 flex space-x-4 justify-end">
        <Select
          placeholder="เลือกบริษัท"
          onChange={(value) => setFilteredCompany(value)}
          value={filteredCompany}
          style={{ width: 200 }}
        >
          {companies.map((company) => (
            <Option key={company} value={company}>
              {company}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="เลือกจังหวัด"
          onChange={(value) => setFilteredProvince(value)}
          value={filteredProvince}
          style={{ width: 200 }}
        >
          {provinces.map((province) => (
            <Option key={province} value={province}>
              {province}
            </Option>
          ))}
        </Select>
      </div>
      <div className='overflow-x-auto'>
      <Table
        className="border-1 rounded-lg border-gray-200"
        dataSource={genNumberTable(filteredData)}
        columns={columns}
        pagination={{ pageSize: 8 }}
        rowKey={'id'}
        scroll={{ x: 'max-content' }}  
      />
      </div>
    </div>
  );
};
//

export default BankTable;
