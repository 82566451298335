import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import PaymentInfoTable from '../../components/Table/PaymentInfoTable';
import PaymentInfoAdminTable from '../../components/Table/PaymentInfoAdminTable';
import { useFetchPaymentAll,useFetchPaymentAdminAll } from "../../hooks/useFetchPayment";
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

const { RangePicker } = DatePicker;

function Payment() {
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const { data: paymentAdminAll, isLoading: isLoadingPaymentAdminAll, refetchPaymentAdminAll } = useFetchPaymentAdminAll();
  
  const { data: paymentAll, isLoading: isLoadingPaymentAll, refetch } = useFetchPaymentAll(
    dateRange[0].format('YYYY-MM-DD'), 
    dateRange[1].format('YYYY-MM-DD')
  );
  
  const titlePage = 'ข้อมูลยอดเงิน';
  const { currentColor ,btnColor,getProfile} = useStateContext();

  const componentRef = useRef();
  const navigate =  useNavigate()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `
  });

  const onDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  useEffect(() => {
    refetch(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
  }, [dateRange]);



  const handleInfo = (recode)=>{
    navigate(`/payment-history/${recode.companyId}`)
  }




  const handlePay = (recode)=>{
    //  navigate(`/payment-pay-detail/${recode.id}`)
    navigate(`/payment-pay-info-detail/${recode.companyId}`)
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between items-center mb-5'>
        <Header category="App" title={`${titlePage}`} />
        {getProfile?.role === 'customer' ? 
         <div className='flex items-center space-x-4'>
         <DatePickerProvider>
           <RangePicker 
             defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
             format="YYYY-MM-DD"
             onChange={onDateChange}
           />
         </DatePickerProvider>
         <Button 
           type="primary" 
           icon={<PrinterOutlined />} 
           onClick={handlePrint}
           style={{ backgroundColor: btnColor, borderColor: btnColor }}
         >
           Print
         </Button>
       </div>
         :null}
       
      </div>
      <div className="normal-view">
      {getProfile?.role === 'customer' ? 
          <PaymentInfoTable data={paymentAll} loading={false} forPrint={true} />:<PaymentInfoAdminTable data={paymentAdminAll} loading={isLoadingPaymentAdminAll} onPay={handlePay}  onInfo={handleInfo}/>
          }
      </div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>{titlePage}</h1>
          {getProfile?.role === 'customer' ? 
          <PaymentInfoTable data={paymentAll} loading={false} forPrint={true} />:<PaymentInfoAdminTable data={paymentAdminAll} loading={isLoadingPaymentAdminAll} />
          }
        </div>
      </div>
    </div>
  );
}

export default Payment;