import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';

const colors = [
  '#3b82f6',
   '#14b8a6',
    '#f97316', 
    '#ef4444',
    'rgb(255, 223, 186)', 
  'rgb(255, 255, 186)',
  'rgb(186, 255, 201)', 
  'rgb(186, 225, 255)'  ]

const colorsSales = [
  '#F05323', // Pink
  '#083050', // Orange
];


export const useFetchCardSummarySale = (type) => {
  return useQuery(
    ['getPCardSummarySale',type], // Pass parameters to the query key
    async () => {
      console.log('fetch',type)
      const endpoint = `/api/v2/dashboard/card/summary-sales/${type}`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchBarSummaryPassenger = (year) => {
  return useQuery(
    ['getSummaryPassenger',year], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/dashboard/bar/summary-passenger/${year}`;
      const { result } = await GET(endpoint);
      const a = result?.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: colors[index % colors.length],
        borderColor: colors[index % colors.length],
        fill: false,
        }));
      return {
        datasets:a,
        labels:result?.labels
      };
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchBarSummarySales = (year) => {
  return useQuery(
    ['getBarSummarySales',year], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/dashboard/bar/summary-sales/${year}`;
      const { result } = await GET(endpoint);
      const a = result?.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: colorsSales[index % colors.length],
        borderColor: colorsSales[index % colors.length],
        fill: false,
        }));
      return {
        datasets:a,
        labels:result?.labels
      };
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchPieSummaryPassenger = (date) => {
  return useQuery(
    ['getSummaryPassenger',date], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/dashboard/pie/summary-passenger/${date}`;
      const { result } = await GET(endpoint);
      console.log(result)
      return{
        labels: result.labels,
        datasets: [
          {
            data: result.datasets[0].data,
            backgroundColor: ['#3b82f6', '#14b8a6', '#f97316', '#ef4444','rgb(255, 223, 186)', 
  'rgb(255, 255, 186)',
  'rgb(186, 255, 201)', 
  'rgb(186, 225, 255)'  ],
          },
        ],
      };
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchPieSummarySales = (date) => {
  return useQuery(
    ['getSummarySales ',date], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/dashboard/pie/summary-sales/${date}`;
      const { result } = await GET(endpoint);
      console.log(result)
      return{
        labels: result.labels,
        datasets: [
          {
            data: result.datasets[0].data,
            backgroundColor: ['#F05323', '#083050'],
          },
        ],
      };
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchTableSchedule= (date) => {
  return useQuery(
    ['tableSchedule',date], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/dashboard/table/schedule/${date}`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};
// export const useFetchVehicleAll = () => {
//     return useQuery(["getVehicleAll"], async () => {
//       const endPoin = `/api/v2/vehicle/get-vehicle-all`;
//       const { result } = await GET(endPoin);
//       // const {result} = scheduleData
//       return result.vehicle;
//     },{
//       cacheTime:0
//     }
  
//   );
// };

// export const useFetchVehicleByCompany = () => {
//   return useMutation(["getVehicleByCompany"],
//     async (payload) => {
//       const endPoin = `/api/v2/vehicle/get-vehicle-by-company/${payload}`;
//       const { result } = await GET(endPoin);
//       return result?.vehicle;
//     }
//   );
// };


// export const useMutationVehicleByID = () => {
//   return useMutation(["getVehicleByID"],
//     async (payload) => {
//       const endPoin = `/api/v2/schedule/get-vehicle-by-id/?id=${payload}`;
//       const { result } = await GET(endPoin);
//       return result.vehicle;
//     }
//   );
// };

// export const useMutationVehicle = ()=> {
//   return useMutation(["addVehicle"],
//     async (payload) => {
//       const endPoin = `/api/v2/vehicle/add-vehicle`;
//       await POST(endPoin,payload);
//     },
//   );
// };

// export const useMutationUpdateVehicle = () => {
//   return useMutation(["updateVehicle"],
//     async (payload) => {
//       const {id,body} =  payload
//       const endPoin = `/api/v2/vehicle/${id}`;
//       await PUT(endPoin,body);
//     }
//   );
// };

// export const useMutationDeleteVehicle = () => {
//   return useMutation(["deleteVehicle"],
//     async (payload) => {
//       const endPoin = `/api/v2/vehicle/${payload}`;
//       await DELETE(endPoin);
//     }
//   );
// };