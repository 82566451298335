import { UPLOAD } from '../services/index';
import { message } from 'antd';
export const uploadImage = async (fileImage) => {
  try {
    console.log(fileImage)
   if(fileImage !== undefined ) {
    const data = new FormData();
    data.append('file', fileImage);
    const {imageUrl}  = await UPLOAD(`/upload`, data); 
    const imageName = imageUrl[0].split(`uploads/`);
    return {
      imageName: imageName[1],
      fullpath: imageUrl[0]
    };
  }
  else{
    return {
      imageName: null,
      fullpath: null
    };
  }
  } catch (error) {
    console.error("Error uploading image:", error);
    message.error(`ไม่สามารถบันทึกรูปภาพได้ ${error.message}`)
  }
};
