import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from '../../components';
import { Button } from 'antd';
import { useStateContext } from '../../contexts/ContextProvider';
import BankInfoCusotmer from '../../components/Payment/BankInfoCusotmer';
import BankInfoAdmin from '../../components/Payment/BankInfoAdmin/index';

function BankInfo() {
  const { currentColor,getProfile} = useStateContext();
  const titlePage = "ข้อมูลธนาคาร"
  
  return (
    <>
      {getProfile?.role ===  'customer' ? <BankInfoCusotmer titlePage={titlePage}/> :<BankInfoAdmin titlePage={titlePage}/>}
    </>
  );
}

export default BankInfo;