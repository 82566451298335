
// App.js
import React, { useEffect,useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { DatePicker, Space } from 'antd';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import 'chart.js/auto'; // Necessary to avoid registering charts manually
import { useFetchBarSummaryPassenger, useFetchBarSummarySales,useFetchPieSummaryPassenger, useFetchTableSchedule ,useFetchPieSummarySales} from '../hooks/useFetchDashboard';
import MonthlyChart from '../components/Dashboard/MonthlyChart';
import PassengerPieChart from '../components/Dashboard/PassengerPieChart';
import ScheduleTable from '../components/Dashboard/ScheduleTable';
import dayjs from 'dayjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the ChartDataLabels plugin
Chart.register(ChartDataLabels);
const Ecommerce = () => {

  const currentYear = new Date().getFullYear();
  const [selectionType, setSelectionType] = useState('day'); // 'year', 'month', 'day'
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectionDate, setSelectionDate] = useState(dayjs().format('YYYY-MM-DD')); // Initialize with today's date in the correct format
  const {data:tableSchedule,isLoading:isLoadingTableSchedule,refetch:refetchFetchTableSchedule} =  useFetchTableSchedule(selectionDate)
  const {data:barSummaryPassenger,isLoading:isLoadingBarSummaryPassenger,refetch:refetchFetchBarSummaryPassenger} =  useFetchBarSummaryPassenger(selectedYear)
  const {data:barSummarySales,isLoading:isLoadingBarSummarySales,refetch:refetchFetchBarSummarySales} =  useFetchBarSummarySales(selectedYear)

  const {data:pieSummaryPassenger,isLoading:isLoadingPieSummaryPassenger,refetch:refetchFetchPieSummaryPassenger} =  useFetchPieSummaryPassenger(selectionDate)
  const {data:pieSummarySales,isLoading:isLoadingPieSummarySales,refetch:refetchFetchPieSummarySales} =  useFetchPieSummarySales(selectionDate)

  const totalPassengers = pieSummaryPassenger?.datasets?.[0]?.data?.reduce((acc, val) => acc + val, 0) || 0;
  const totalSales= pieSummarySales?.datasets?.[0]?.data?.reduce((acc, val) => acc + val, 0) || 0;

  // console.log(pieSummaryPassenger)
  const onChange = (date, dateString) => {
    if (dateString) {
      const formattedDate = dayjs(dateString).format('YYYY-MM-DD'); // Format the selected date
      setSelectionDate(formattedDate); // Update state with the selected date
      refetchFetchTableSchedule(formattedDate); // Fetch table data 
      refetchFetchPieSummaryPassenger(formattedDate)
      refetchFetchPieSummarySales(formattedDate)
    }
  }

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    refetchFetchBarSummaryPassenger(selectedYear)
    refetchFetchBarSummarySales(selectedYear)
  };

  const years = [];
  for (let i = currentYear; i >= 2000; i--) {
    years.push(i);
  }



  const ticketData = {
    labels: ['ทำเรือหน้าบ้าน', 'ทำเรือเกาะล้าน', 'ทำเรือเกาะลอย', 'ทำเรือเกาะสีชัง'],
    datasets: [
      {
        data: [70, 39, 100, 500],
        backgroundColor: ['#3b82f6', '#14b8a6', '#f97316', '#ef4444'],
      },
    ],
  };


  // {
  //   "labels": [
  //       "ท่าเรือเกาะลอย",
  //       "แหลมบาลีฮาย"
  //   ],
  //   "datasets": [
  //       {
  //           "data": [
  //               0,
  //               0
  //           ]
  //       }
  //   ]
  // }

  

  const salesData = {
    labels: ['ยอดขายออนไลน์', 'ยอดขายออฟไลน์'],
    datasets: [
      {
        data: [24180, 14820],
        backgroundColor: ['#F05323', '#083050'],
      },
    ],
  };

  const monthlySalesData = {
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    datasets: [
      {
        label: 'ยอดขายออนไลน์',
        data: [0],
        backgroundColor: '#F05323',
      },
      {
        label: 'ยอดขายออฟไลน์',
        data: [0],
        backgroundColor: '#083050',
      },
    ],
  };

  const ticketCountData = {
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    datasets: [
      {
        label: 'ทำเรือหน้าบ้าน',
        data: [1000, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 25],
        borderColor: '#3b82f6',
        fill: false,
      },
      {
        label: 'ทำเรือเกาะล้าน',
        data: [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 25, 12],
        borderColor: '#14b8a6',
        fill: false,
      },
      {
        label: 'ทำเรือเกาะลอย',
        data: [800, 700, 600, 500, 400, 300, 200, 100, 50, 25, 12, 6],
        borderColor: '#f97316',
        fill: false,
      },
      {
        label: 'ทำเรือเกาะสีชัง',
        data: [700, 600, 500, 400, 300, 200, 100, 50, 25, 12, 6, 3],
        borderColor: '#ef4444',
        fill: false,
      },
    ],
  };

  return (
    <div className="p-6">
        <div className="flex items-center justify-end mt-10 md:mt-0  ">
          <DatePicker onChange={onChange}  defaultValue={dayjs()}/>
        </div>
      <div className="grid grid-cols-1 md:grid-cols-3 mt-4 gap-6 mb-6">
        {/* Table */}
        <div className="bg-white p-4 rounded-lg shadow-md mt-10 md:mt-0 ">
          <h2 className="text-lg font-light mb-4">สถานะ</h2>
          <ScheduleTable data={tableSchedule}  />
        </div>

       {/* Ticket Count Chart */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-light mb-4">ยอดจำนวนบัตรโดยสาร</h2>
          <div className="flex flex-col items-center">
            <div className="relative w-56 h-56 mb-4"> {/* Adjusted size */}
              <Doughnut
                data={pieSummaryPassenger||ticketData}
                options={{
                  cutout: '65%',
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false, // Keep legend hidden
                    },
                    datalabels: {
                      color: '#fff', // Set the text color to white
                      font: {
                        size: 12,
                      },
                      formatter: (value) => value, // Display the data value
                    },
                  },
                }}
              />
           <div className="absolute inset-0 flex items-center justify-center">                 
            <div className="flex flex-col items-center"> {/* เพิ่ม flex-col เพื่อจัดให้อยู่ในแนวตั้ง */} 
              <p className="text-s text-gray-500 font-light">ยอดทั้งหมด</p> 
              <p className="text-xl font-semibold">{totalPassengers.toLocaleString() || 0}</p> {/* ข้อมูลแบบ dynamic */}
            </div>               
          </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-2">
              {pieSummaryPassenger?.labels.map((label, index) => (
                <div key={index} className="flex items-center justify-center">
                  <div
                    className="w-4 h-4 mr-2 rounded-full border-4"
                    style={{ borderColor: pieSummaryPassenger?.datasets[0].backgroundColor[index] }}
                  > 
                  </div>
                  <span className="text-sm">{label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Sales Chart */}
          <div className="bg-white p-4 rounded-lg shadow-md">
        
          <h2 className="text-lg font-light mb-4">ยอดขาย (บาท)</h2>
            <div className="flex flex-col items-center">
              <div className="relative w-56 h-56 mb-4"> {/* Adjusted size */}
              <Doughnut
                data={pieSummarySales||salesData}
                options={{
                  cutout: '55%',
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false, // Keep legend hidden
                    },
                    datalabels: {
                      color: '#fff', // Set the text color to white
                      font: {
                        size: 12,
                      },
                      formatter: (value) => value, // Display the data value
                    },
                  },
                }}
              />
              <div className="absolute inset-0 flex items-center justify-center">                 
              <div className="flex flex-col items-center"> {/* เพิ่ม flex-col เพื่อจัดให้อยู่ในแนวตั้ง */} 
                <p className="text-s text-gray-500 font-light">ยอดทั้งหมด</p> 
                <p className="text-xl font-semibold">{totalSales.toLocaleString() || 0}</p> {/* ข้อมูลแบบ dynamic */}
              </div>               
            </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-2">
              {pieSummarySales?.labels?.map((label, index) => (
                <div key={index} className="flex items-center justify-center ">
                  <div
                    className="w-4 h-4 mr-2 rounded-full border-4 "
                    style={{ borderColor: pieSummarySales?.datasets[0]?.backgroundColor[index] }}
                  ></div>
                  <span className="text-sm">{label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>


        {/* <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-4">ยอดขาย (บาท)</h2>
          <div className="flex justify-center">
            <div className="relative w-40 h-40">
              <Doughnut data={salesData} options={{ cutout: '70%', responsive: true, maintainAspectRatio: false }} />
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-xl font-semibold">39,000</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {/* Monthly Sales Bar Chart */}
  
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-light">ยอดขาย (บาท)</h2>
          <div className="flex justify-end items-center  mb-4">
            <select  className="p-1 border rounded-lg" id="year-select"  value={selectedYear} onChange={handleYearChange}>
                {years.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
            </select>
          </div>
        </div>

        <div className="flex items-center justify-start space-x-4 ml-4"> {/* Adjust space-x-4 for spacing */}
              {salesData?.labels?.map((label, index) => (
                <div key={index} className="flex items-center">
                  <div
                    className="w-4 h-4 mr-2 rounded-full border-4"
                    style={{ borderColor: salesData?.datasets[0]?.backgroundColor[index] }}
                  ></div>
                  <span className="text-sm">{label}</span>
                </div>
              ))}
        </div>
        <div className="h-64">
          {/* <MonthlyChart labels={barSummaryPassenger?.labels}  datasets={barSummaryPassenger?.datasets}/> */}
          <Bar data={barSummarySales ||monthlySalesData} 
          options={
              {   
                plugins: { 
                    datalabels: {
                      display: false, // Disable the labels inside the bars
                    },
                    legend: {
                      position: 'top', // Set the legend position (top, bottom, left, right, etc.)
                      align: 'start',  // Align the legend items to the start (left)
                      labels: {
                        boxWidth: 10, // Set the width of the legend boxes
                        padding: 10,  // Space between legend items
                        font: {
                          size: 12, // Adjust the font size if needed
                        },
                      },
                      display: false,
                    },
                  }, 
                  responsive: true, 
                  maintainAspectRatio: false, 
                  scales: { x: {
                    barThickness: 3, // ปรับขนาดความกว้างของแท่ง
                    maxBarThickness: 20, // กำหนดขนาดสูงสุดของแท่ง
                  },
                  y: { 
                    beginAtZero: true 
                  } 
                } ,
                  categoryPercentage: 0.5, // ปรับลดความกว้างของกลุ่มแท่งกราฟ
                }  
          } 
          />
        </div>
      </div>

      {/* Ticket Count Line Chart */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-light">ยอดจำนวนบัตรโดยสาร</h2>
          <div className="flex justify-end items-center  mb-4">
            <select  className="p-1 border rounded-lg" id="year-select"  value={selectedYear} onChange={handleYearChange}>
                {years.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
            </select>
          </div>
        </div>
        <div className="ml-4 space-y-2 md:flex md:items-center md:justify-start md:space-x-4">
  <div className="grid grid-cols-1 gap-4 md:flex md:space-x-4">
    {barSummaryPassenger?.datasets?.map((item, index) => (
      <div key={index} className="flex items-center">
        <div
          className="w-4 h-4 mr-2 rounded-full border-4"
          style={{ borderColor: item.backgroundColor }}
        ></div>
        <span className="text-sm">{item.label}</span>
      </div>
    ))}
  </div>
</div>
        <div className="h-64">
          <Line data={barSummaryPassenger || ticketCountData} options={{ plugins: { legend: { display: false}}, responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
        </div>
      </div>
    </div>
  );
};



export default Ecommerce;


// import React, { useState } from 'react';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
// import { useStateContext } from '../contexts/ContextProvider';
// import StatCards from '../components/Dashboard/StatCards';
// import { useFetchBarSummaryPassenger, useFetchCardSummarySale,useFetchPieSummaryPassenger, useFetchTableSchedule } from '../hooks/useFetchDashboard';
// import MonthlyChart from '../components/Dashboard/MonthlyChart';
// import PassengerPieChart from '../components/Dashboard/PassengerPieChart';
// import ScheduleTable from '../components/Dashboard/ScheduleTable';

// ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

// function Ecommerce() {
//   const currentYear = new Date().getFullYear();
//   const [selectionType, setSelectionType] = useState('day'); // 'year', 'month', 'day'
//   const [selectedYear, setSelectedYear] = useState(currentYear);
//   const {data:cardSummarySaler,isLoading:isLoadingCardSummarySaler,refetch:refetchFetchCardSummarySale} =  useFetchCardSummarySale(selectionType)
//   const {data:barSummaryPassenger,isLoading:isLoadingBarSummaryPassenger,refetch:refetchFetchBarSummaryPassenger} =  useFetchBarSummaryPassenger(selectedYear)
//   const {data:pieSummaryPassenger,isLoading:isLoadingPieSummaryPassenger,refetch:refetchFetchPieSummaryPassenger} =  useFetchPieSummaryPassenger()
//   const {data:tableSchedule,isLoading:isLoadingTableSchedule,refetch:refetchFetchTableSchedule} =  useFetchTableSchedule()


//   const handleYearChange = (e) => {
//     setSelectedYear(e.target.value);
//     // refetchFetchCardSummarySale(selectedYear)
//   };

//   const years = [];
//   for (let i = currentYear; i >= 2000; i--) {
//     years.push(i);
//   }

//   const handleSelectionTypeChange = (e) => {
//     setSelectionType(e.target.value);
//     // console.log(e.target.value)
//     refetchFetchCardSummarySale(e.target.value)
//   };

//   return (
//     <div className="p-4 bg-gray-100">
//        {/* Select สำหรับเลือกประเภท วัน, เดือน หรือปี */}
//        <div className="flex justify-end items-center mb-4">
//         <label className="mr-2" htmlFor="selection-type">เลือกประเภท:</label>
//         <select
//           className="p-1 border rounded-lg"
//           id="selection-type"
//           value={selectionType}
//           onChange={handleSelectionTypeChange}
//         >
//           <option value="day">วัน</option>
//           <option value="month">เดือน</option>
//           <option value="year">ปี</option>


//         </select>
//       </div>
  
//       <StatCards  online={cardSummarySaler?.totalOnline} offline={cardSummarySaler?.totalOffline} total={cardSummarySaler?.totalTickets} />
//       <div className="flex justify-end items-center  mb-4">
//         <label className="mr-2" htmlFor="year-select">เลือกปี:</label>
//         <select  className="p-1 border rounded-lg" id="year-select"  value={selectedYear} onChange={handleYearChange}>
//           {years.map((year) => (
//             <option key={year} value={year}>{year}</option>
//           ))}
//         </select>
//       </div>
//       <MonthlyChart labels={barSummaryPassenger?.labels}  datasets={barSummaryPassenger?.datasets}/>
//       <div className="flex flex-col lg:flex-row mt-4 md:gap-3">
//         <ScheduleTable data={tableSchedule}  />
//         <PassengerPieChart labels={pieSummaryPassenger?.labels} datasets={pieSummaryPassenger?.datasets}/>
//       </div>
//     </div>
//   );
// }


// export default Ecommerce;


