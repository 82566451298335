import { useFetchBankByCompany } from "../../hooks/useFetchPayment";
import Header from "../Header";

 function BankInfoCusotmer({titlePage}) {

    const {data:bank, isLoading:isLoadingBankByCompany} = useFetchBankByCompany()
    console.log(bank)
    const nameBank = bank?.name || "ไม่มีข้อมูล"
    const accountNumber = bank?.accountNumber || "ไม่มีข้อมูล"
    const accountHolderName = bank?.accountHolderName || "ไม่มีข้อมูล"
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
      </div>
          <p>ท่านสามารถตรวจสอบและแก้ไขข้อมูลบัญชีของท่านได้ที่นี่</p>
          <h2 className="text-lg font-semibold mt-4">การรับยอดชำระจาก takeket.co</h2>
          <p>เราจะโอนยอดชำระไปที่บัญชีธนาคารที่ระบุ</p>
          <div className="mt-6 bg-zinc-50 p-4 rounded-lg ">
            <h3 className="font-bold">ข้อมูลธนาคาร</h3>
            <p>รหัสธนาคาร 3 หลัก + รหัสสาขา 4 หลัก: 0145681</p>
            <br/>
            <p className="font-bold">เลขที่บัญชี:<span className="font-light pl-3">{accountNumber}</span> </p>
            <p className="font-bold">ธนาคาร:<span className="font-light pl-3">{nameBank}</span> </p>
            <p className="font-bold">ชื่อเจ้าของบัญชี: <span className="font-light pl-3">{accountHolderName}</span></p>
          </div>
          <footer className="mt-6 text-muted-foreground dark:text-muted-foreground">
            <p>แก้ไขข้อมูลธนาคาร ติดต่อ <a href="mailto:contact@one2tickets.com" className="text-accent hover:text-accent/80">contact@one2tickets.com</a> / โทรศัพท์ 083-998-7899</p>
          </footer>
   
      </div>
        
    )
}

export default BankInfoCusotmer;