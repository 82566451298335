import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';
import {boatData} from '../data/mock/boat'
import {scheduleData} from '../data/mock/schedule'

export const useFetchLocationAll = () => {
    return useQuery(["getLocationAll"], async () => {
      const endPoin = `/api/v1/schedule/get-location-all`;
      const { result } = await GET(endPoin);
      return result.location;
    },{
      cacheTime:0
    }
  );
};


export const useFetchScheduleAll = (startDate, endDate) => {
  return useQuery(["getScheduleAll",startDate, endDate], async () => {
    const endPoin = `/api/v2/schedule/get-schedule-all?startDate=${startDate}&endDate=${endDate}`;
    const { result } = await GET(endPoin);
    // const {result} = scheduleData
    return result.schedules;
  },{
    cacheTime:0
  }

);
};

// export const useFetchScheduleByID = () => {
//   return useQuery(["getScheduleByID"], async (id) => {
//     const endPoin = `/api/v1/scheduleget-schedule-by-id/?id=${id}`;
//     const { result } = await GET(endPoin);
//     // const {result} = scheduleData
//     return result.schedules;
//   }
// );
// };


export const useMutationScheduleByID = () => {
  return useMutation(["getScheduleByID"],
    async (payload) => {
      const endPoin = `/api/v2/schedule/get-schedule-by-id/?id=${payload}`;
      const { result } = await GET(endPoin);
      return result.schedules;
    }
  );
};

export const useMutationSchedule = () => {
  return useMutation(["addSchedule"],
    async (payload) => {
      const endPoin = `/api/v2/schedule/add-schedule`;
      await POST(endPoin,payload);
    },
  );
};

export const useMutationUpdateSchedule = () => {
  return useMutation(["updateSchedule"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v2/schedule/${id}`;
      await PUT(endPoin,body);
    }
  );
};

export const useMutationDeleteSchedule = () => {
  return useMutation(["deleteSchedule"],
    async (payload) => {
      const endPoin = `/api/v2/schedule/${payload}`;
      await DELETE(endPoin);
    }
  );
};