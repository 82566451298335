import React from 'react';
import { Table, Space,Avatar,Popconfirm } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const CustomerTable = ({ data, onEdit, onDelete ,loading}) => {
  const { currentColor,setGetProfile} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'รูปภาพ', 
      align: 'center',
      width: 50, 
      dataIndex: 'picture', 
      key: 'picture', 
      render: (text) => <Avatar icon={<UserOutlined />} className='object-cover rounded-full shadow-lg size-14' src={text} alt="Boat" /> ,onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'รหัสลูกค้า', 
      align: 'center', 
      dataIndex: 'customerId', 
      key: 'customerId',
      width: 'auto',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'บริษัท', 
      align: 'center', 
      dataIndex: 'company', 
      key: 'company',
      width: 'auto',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'ชื่อ', 
      align: 'center', 
      dataIndex: 'name', 
      key: 'name',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'นามสกุล', 
      align: 'center', 
      dataIndex: 'surname', 
      key: 'surname',
      width: 'auto',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'อีเมล์', 
      align: 'center', 
      dataIndex: 'email', 
      key: 'email' ,
      width: 'auto',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'จังหวัด', 
      align: 'center', 
      dataIndex: 'province', 
      key: 'province' ,
      width: 'auto',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>

          <Popconfirm
            title="ลบข้อมูลลูกค้า"
            description="ยืนยันการลบข้อมูลลูกค้า ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
              <div className="text-red-500 cursor-pointer" >
                ลบ
              </div>

           </Popconfirm>
      
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return ( 
    <div className='overflow-x-auto'>
    <Table 
      className=' border-1 rounded-lg border-gray-200' 
      dataSource={genNumberTable(data)}
      columns={columns}  
      pagination={{ pageSize: 8}} 
      rowKey={'CustomerID'}
      scroll={{ x: 'max-content' }}  
      // scroll={{ x: 'max-content' }}  
      loading={loading}   
      />
      </div>
    );
};

export default CustomerTable;
