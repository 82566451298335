import React, { useState } from 'react';
import { Table, Space, Avatar, Popconfirm, Button, Switch,Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
const { Option } = Select;
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const AgentTable = ({ data, onClickBtn, isInvite = false, isSwitch = false, onSwitchChange }) => {
  const { currentColor } = useStateContext();
    // State for filtering
    const [filteredProvince, setFilteredProvince] = useState('ทั้งหมด');
  

    const provinces = ['ทั้งหมด', ...new Set(data?.map((item) => item.province))];

      // Filtered data
  const filteredData = data?.filter((item) => {
    return (
      (filteredProvince === 'ทั้งหมด' || item.province === filteredProvince)
    );
  });
  
  const columns = [
    {
      title: 'ลำดับ',
      align: 'center',
      width: 60,
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'รูปภาพ',
      align: 'center',
      width: 50,
      dataIndex: 'picture',
      key: 'picture',
      render: (text) => <Avatar icon={<UserOutlined />} className='object-cover rounded-full shadow-lg size-14' src={text} alt="Boat" />,
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'รหัสตัวแทน',
      align: 'center',
      dataIndex: 'agentId',
      key: 'customerId',
      width: 'auto',
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'ชื่อ',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'นามสกุล',
      align: 'center',
      dataIndex: 'surname',
      key: 'surname',
      width: 'auto',
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'อีเมล์',
      align: 'center',
      dataIndex: 'email',
      key: 'email',
      width: 'auto',
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'จังหวัด',
      align: 'center',
      dataIndex: 'province',
      key: 'province',
      width: 'auto',
      onHeaderCell: () => { return { style: headerStyle } }
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      width: 180, // ปรับความกว้างเพื่อให้พอใส่ปุ่มหรือสวิตช์
      render: (record) => (
        <Space size="middle">
          {isSwitch ? (
            // แสดง Switch หาก isSwitch เป็น true
            <Switch
              checkedChildren="เปิด"
              unCheckedChildren="ปิด"
              defaultChecked={record.active}
              value={record.active}
              onChange={(checked) => onSwitchChange(record, checked)}
              style={{
                backgroundColor: record.active ? currentColor : '#d9d9d9',
                borderColor: record.active ? currentColor : '#d9d9d9',
              }}
            />
          ) : (
            // แสดง Button หาก isSwitch เป็น false
            <Popconfirm
              title={isInvite ? "อนุมัติตัวแทนขาย" : `${!record.invite ? "เชิญ":"ยกเลิก"}ตัวแทนขาย`}
              description={isInvite ? "ยืนยันการอนุมัติตัวแทนขาย?" : `ยืนยันการ${!record.invite ? "เชิญ":"ยกเลิก"}ตัวแทนขาย ?`}
              onConfirm={() => onClickBtn(record)}
              okText="ยืนยัน"
              cancelText="ยกเลิก"
              okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
            >
              <Button
                style={{ backgroundColor: '#F05323', borderColor: '#F05323', color: '#fff', width: '80px' }}
                size="small"
              >
                {isInvite ? "อนุมัติ" : !record.invite ? "เชิญ":"ยกเลิก"}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return (
    <div>
       <div className="mb-4 flex space-x-4 justify-end">
       <Select
          placeholder="เลือกจังหวัด"
          onChange={(value) => setFilteredProvince(value)}
          value={filteredProvince}
          style={{ width: 160 }}
        >
          {provinces.map((province) => (
            <Option key={province} value={province}>
              {province}
            </Option>
          ))}
        </Select>

       </div>
       <div className='overflow-x-auto'>
      <Table
        className=' border-1 rounded-lg border-gray-200'
        dataSource={genNumberTable(filteredData)}
        columns={columns}
        pagination={{ pageSize: 8 }}
        rowKey={'CustomerID'}
        scroll={{ x: 'max-content' }}
      />
    </div>
    </div>
 
  );
};

export default AgentTable;


// import React from 'react';
// import { Table, Space,Avatar,Popconfirm ,Button} from 'antd';
// import { UserOutlined } from '@ant-design/icons';
// import { genNumberTable } from '../../function/genNumberTable';
// import { useStateContext } from '../../contexts/ContextProvider';
// const headerStyle = {
//   color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
// };

// const CustomerTable = ({ data,onClickBtn,isInvite=false }) => {
//   const { currentColor,setGetProfile} = useStateContext();
//   const columns = [
//     { 
//       title: 'ลำดับ',
//       align: 'center',  
//       width: 60, 
//       dataIndex: 'number',
//       key: 'number',
//       onHeaderCell: () =>{return {style:headerStyle}}
//     },
//     { 
//       title: 'รูปภาพ', 
//       align: 'center',
//       width: 50, 
//       dataIndex: 'picture', 
//       key: 'picture', 
//       render: (text) => <Avatar icon={<UserOutlined />} className='object-cover rounded-full shadow-lg size-14' src={text} alt="Boat" /> ,onHeaderCell: () =>{return {style:headerStyle}}
//     },
//     { 
//       title: 'รหัสตัวแทน', 
//       align: 'center', 
//       dataIndex: 'agentId', 
//       key: 'customerId',
//       width: 'auto',
//       onHeaderCell: () =>{return {style:headerStyle}} 
//     },
//     { 
//       title: 'ชื่อ', 
//       align: 'center', 
//       dataIndex: 'name', 
//       key: 'name',
//       onHeaderCell: () =>{return {style:headerStyle}} 
//     },
//     { 
//       title: 'นามสกุล', 
//       align: 'center', 
//       dataIndex: 'surname', 
//       key: 'surname',
//       width: 'auto',
//       onHeaderCell: () =>{return {style:headerStyle}} 
//     },
//     {
//       title: 'อีเมล์', 
//       align: 'center', 
//       dataIndex: 'email', 
//       key: 'email' ,
//       width: 'auto',
//       onHeaderCell: () =>{return {style:headerStyle}}
//     },
//     {
//       title: 'จังหวัด', 
//       align: 'center', 
//       dataIndex: 'province', 
//       key: 'province' ,
//       width: 'auto',
//       onHeaderCell: () =>{return {style:headerStyle}}
//     },
//     {
//       title: 'Actions',
//       fixed: 'right',
//       key: 'actions',
//       align: 'center',
//       width: 100, 
//       render: (record) => (
//         <Space size="middle">
//           <Popconfirm
//             title={isInvite ? "อนุมัติตัวแทนขาย" : "เชิญตัวแทนขาย"}
//             description={isInvite ? "ยืนยันการอนุมัติตัวแทนขาย?" : "ยืนยันการเชิญตัวแทนขาย?"}
//             onConfirm={() => onClickBtn(record)}
//             okText="ยืนยัน"
//             cancelText="ยกเลิก"
//             okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
//           >
//             <Button
//               style={{ backgroundColor: '#F05323', borderColor: '#F05323', color: '#fff',width:'80px' }}
//               size="small"
//             >
//               {isInvite ? "อนุมัติ" : "เชิญ"}
//             </Button>
//           </Popconfirm>
//         </Space>
//       ),
//       onHeaderCell: () => ({ style: headerStyle }),
//     }
    
//   ];

//   return ( 
//     <div className='overflow-x-auto'>
//     <Table 
//       className=' border-1 rounded-lg border-gray-200' 
//       dataSource={genNumberTable(data)}
//       columns={columns}  
//       pagination={{ pageSize: 8}} 
//       rowKey={'CustomerID'}
//       scroll={{ x: 'max-content' }}  
//       // scroll={{ x: 'max-content' }}  
//       />
//       </div>
//     );
// };

// export default CustomerTable;
