import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Spin } from 'antd';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Ecommerce, Orders, Calendar, Employees, Stacked, Pyramid, Kanban, Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping, Editor } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import Vehicle from './pages/Vehicle';
import EditVehicle from './pages/Vehicle/Edit';
import AddVehicle from './pages/Vehicle/Add';
import Employee from './pages/Employee';
import Schedule from './pages/Schedule';
import Login from './pages/Login';
import Promotion from './pages/Promotion';
import Payment from './pages/Payment'; 
import PayDetailAdmin from './pages/Payment/PayDetailAdmin'; 
import PaymentSaleInfo from './pages/Payment/SaleInfo'; 
import PaymentBankInfo from './pages/Payment/BankInfo'; 
import PaymentHistory from './pages/Payment/PaymentHistory'; 
import PaymentSaleHistory from './pages/Payment/SaleHistory'; 
import HistoryReceivingMoney from './pages/Payment/HistoryReceivingMoney'; 
import PayDetail from './pages/Payment/PayDetail'; 
import AccountSettings from './pages/AccountSettings';
import Customer from './pages/Customer';
import Location from './pages/Location';
import Ticket from './pages/Ticket';
import Verify from './pages/Verify';

import ChangPassword from './pages/ChangPassword';
import ForgetPassword from './pages/ForgetPassword'
import StatusMessage from './pages/ChangPassword/statusMessage';

import Agent from './pages/Agent';
import AgentRequests from './pages/Agent/agentRequests';
import MyAgent from './pages/Agent/myAgent';

import PrivateRoute from './components/Authen/PrivateRoute';
import {useFetchGetProfile} from './hooks/useAuthen';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode,setGetProfile } = useStateContext();
  const { data: getProfile,isLoading:isLoadingGetprofile,error} = useFetchGetProfile();


  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  useEffect(() => {
    setGetProfile(getProfile)
  }, [getProfile,isLoadingGetprofile,setGetProfile]);

  if (error || !getProfile && !isLoadingGetprofile) {
    localStorage.removeItem('token');
  }

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
      <AppContent />
      </BrowserRouter>
    </div>
  );
};

const AppContent = () => {
  const location = useLocation();
  const { activeMenu, currentMode, currentColor, themeSettings, setThemeSettings } = useStateContext();
  const isLoginRoute = location.pathname === '/login';
  const isVerifyRoute = location.pathname.startsWith('/verify');
  const isChangPasswordRoute = location.pathname.startsWith('/chang-password');
  const isChangPasswordSuccessRoute = location.pathname.startsWith('/chang-password-sucess');
  const isForgetPasswordRoute = location.pathname.startsWith('/forget-password');
  
  return (
    <div className="flex relative dark:bg-main-dark-bg">
             {!isLoginRoute && !isChangPasswordRoute && !isChangPasswordSuccessRoute &&  !isForgetPasswordRoute && !isVerifyRoute && activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white z-999">
          <Sidebar />
        </div>
      ) : (
        !isLoginRoute &&  !isChangPasswordRoute && !isChangPasswordSuccessRoute &&  !isForgetPasswordRoute && !isVerifyRoute && (
          <div className="w-0 dark:bg-secondary-dark-bg z-999">
            <Sidebar />
          </div>
        )
      )}
      {!isLoginRoute && !isChangPasswordRoute && !isChangPasswordSuccessRoute &&  !isForgetPasswordRoute && !isVerifyRoute && (
       <div className="fixed right-4 bottom-4"> {/* Higher z-index for the settings button */}
          {/* <TooltipComponent content="Settings" position="Top">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              style={{ background: currentColor, borderRadius: '50%' }}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
            >
              <FiSettings />
            </button>
          </TooltipComponent> */}
        </div>
      )}
 
      <div
        className={
          activeMenu && !isLoginRoute &&   !isChangPasswordRoute && !isChangPasswordSuccessRoute &&  !isForgetPasswordRoute &&  !isVerifyRoute 
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  overflow-hidden '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            
        }
      >
        {!isLoginRoute && !isChangPasswordRoute && !isChangPasswordSuccessRoute &&  !isForgetPasswordRoute && !isVerifyRoute && (
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
        )}
        <div>
          {themeSettings && <ThemeSettings />}

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/verify" element={<Verify/>}/> 
            <Route path="/chang-password" element={<ChangPassword/>}/> 
            <Route path="/chang-password-success" element={<StatusMessage isSuccess={true}/>}/> 
            <Route path="/chang-password-failed" element={<StatusMessage isSuccess={false}/>}/> 

            <Route path="/forget-password" element={<ForgetPassword/>}/> 
            
            {/* Protected routes */}
            <Route path="/" element={<PrivateRoute><Ecommerce /></PrivateRoute>} />
            <Route path="/account-settings" element={<PrivateRoute><AccountSettings/></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Ecommerce /></PrivateRoute>} />
            <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route path="/employees" element={<PrivateRoute><Employee /></PrivateRoute>} />
            <Route path="/customers" element={<PrivateRoute><Customer /></PrivateRoute>} />
            <Route path="/vehicles" element={<PrivateRoute><Vehicle /></PrivateRoute>} />
            <Route path="vehicles/edit/:id" element={<PrivateRoute><EditVehicle /></PrivateRoute>} />
            <Route path="vehicles/add" element={<PrivateRoute><AddVehicle /></PrivateRoute>} />
            <Route path="/schedule" element={<PrivateRoute><Schedule /></PrivateRoute>} />
            <Route path="/promotion" element={<PrivateRoute><Promotion/></PrivateRoute>} />
            <Route path="/payment-info" element={<PrivateRoute><Payment/></PrivateRoute>} />
            <Route path="/payment-pay-info-detail/:id" element={<PrivateRoute><PayDetailAdmin/></PrivateRoute>} /> 
            <Route path="/payment-sale-info" element={<PrivateRoute><PaymentSaleInfo/></PrivateRoute>} />
            <Route path="/payment-bank-info" element={<PrivateRoute><PaymentBankInfo/></PrivateRoute>} />
            <Route path="/payment-history-sale/:id" element={<PrivateRoute><PaymentSaleHistory/></PrivateRoute>} />
            <Route path="/payment-history/:id" element={<PrivateRoute><PaymentHistory/></PrivateRoute>} />
            <Route path="/payment-pay-detail/:id" element={<PrivateRoute><PayDetail/></PrivateRoute>} />
            <Route path="/payment-history-receiving-money" element={<PrivateRoute><HistoryReceivingMoney/></PrivateRoute>} />
            <Route path="/location" element={<PrivateRoute><Location/></PrivateRoute>} /> 
            <Route path="/ticket" element={<PrivateRoute><Ticket/></PrivateRoute>} /> 
            <Route path="/agent" element={<PrivateRoute><Agent/></PrivateRoute>} /> 
            <Route path="/agent-requests" element={<PrivateRoute><AgentRequests/></PrivateRoute>} />  
            <Route path="/my-agent" element={<PrivateRoute><MyAgent/></PrivateRoute>} /> 
            {/* apps */}
            {/* <Route path="/kanban" element={<PrivateRoute><Kanban /></PrivateRoute>} />
            <Route path="/editor" element={<PrivateRoute><Editor /></PrivateRoute>} />
            <Route path="/calendar" element={<PrivateRoute><Calendar /></PrivateRoute>} />
            <Route path="/color-picker" element={<PrivateRoute><ColorPicker /></PrivateRoute>} /> */}

            {/* charts */}
            {/* <Route path="/line" element={<PrivateRoute><Line /></PrivateRoute>} />
            <Route path="/area" element={<PrivateRoute><Area /></PrivateRoute>} />
            <Route path="/bar" element={<PrivateRoute><Bar /></PrivateRoute>} />
            <Route path="/pie" element={<PrivateRoute><Pie /></PrivateRoute>} />
            <Route path="/financial" element={<PrivateRoute><Financial /></PrivateRoute>} />
            <Route path="/color-mapping" element={<PrivateRoute><ColorMapping /></PrivateRoute>} />
            <Route path="/pyramid" element={<PrivateRoute><Pyramid /></PrivateRoute>} />
            <Route path="/stacked" element={<PrivateRoute><Stacked /></PrivateRoute>} /> */}
          </Routes>
        </div>
        {!isLoginRoute && <Footer />}
      </div>
    </div>
  );
};

export default App;

