import React from 'react';
import { Table, Space, Tag,Popconfirm } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import dayjs from 'dayjs';

const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const summaryStyle = {
  fontWeight: 'bold',
  backgroundColor: '#f0f0f0',
};


const PaymentInfoTable = ({ data, onPay, onInfo,loading,forPrint = false }) => {
  const { currentColor} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'ชื่อบริษัท', 
      align: 'center', 
      dataIndex: 'name', 
      key: 'name',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'จำนวนตั๋ว', 
      align: 'center', 
      dataIndex: 'totalTickets', 
      key: 'totalTickets' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'ยอดเงิน', 
      align: 'center', 
      dataIndex: 'totalAmount', 
      key: 'totalAmount' ,
      onHeaderCell: () =>{return {style:headerStyle}},
      render: (text) => text?.toLocaleString(),
    },
    {
      title: 'เปอร์เซ็นคอมมิชชั่น(%)',
      align: 'center',
      dataIndex: 'percentCommission',
      key: 'percentCommission',
      onHeaderCell: () => ({ style: headerStyle }),
      render: (text) => text?.toLocaleString(),
    },
    { 
      title: 'คอมมิชชั่น', 
      align: 'center', 
      dataIndex: 'commissionAmount', 
      key: 'commissionAmount',
      onHeaderCell: () =>{return {style:headerStyle}} ,
      render: (text) => text?.toLocaleString(),
    },
    { 
      title: 'ยอดตัดรับโอน', 
      align: 'center', 
      dataIndex: 'netAmountDarf', 
      key: 'netAmountDarf',
      onHeaderCell: () =>{return {style:headerStyle}} ,
      render: (text) => text?.toLocaleString(),
    },
    { 
      title: 'ยอดคงค้าง', 
      align: 'center', 
      dataIndex: 'outstandingBalance', 
      key: 'outstandingBalance',
      onHeaderCell: () =>{return {style:headerStyle}} ,
      render: (text) => text?.toLocaleString(),
    },
    { 
      title: 'ยอดสุทธิ', 
      align: 'center', 
      dataIndex: 'netAmount', 
      key: 'netAmount',
      onHeaderCell: () =>{return {style:headerStyle}} ,
      fixed: 'right',
      render: (text) => text?.toLocaleString(),
    },
    {
      title: 'ชำระเงิน',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onPay(record)}>
            ชำระ
          </div>
          <div className="text-blue-500 cursor-pointer" onClick={() => onInfo(record)}>
            ประวัติ
          </div>
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
 
  ];
// ฟังก์ชันช่วยในการแปลงค่าเป็นตัวเลขและคำนวณผลรวม
const sumNumeric = (arr, key) => arr?.reduce((sum, curr) => sum + (Number(curr[key]) || 0), 0);

// คำนวณผลรวมสำหรับคอลัมน์ที่ต้องการ
const totalPassengers = sumNumeric(data, 'totalPassengers');
const totalAmount = sumNumeric(data, 'totalAmount');
const totalCommission = sumNumeric(data, 'commissionAmount');
const totalNet = sumNumeric(data, 'totalAmount');

// ฟังก์ชันช่วยในการแสดงผลตัวเลขทศนิยม 2 ตำแหน่ง
const formatNumber = (number) => Number(number).toFixed(2);


  return (
    <div className="payment-info-table">
     <style jsx>{`
        .payment-info-table .ant-table-summary > tr > td {
          background-color: #f0f0f0 !important;
        }
        .payment-info-table .ant-table-summary > tr > td.ant-table-cell {
          font-weight: bold !important;
        }
        @media print {
          .payment-info-table .ant-table-summary > tr > td {
            background-color: #f0f0f0 !important;
          }
          .payment-info-table .ant-table-summary > tr > td.ant-table-cell {
            font-weight: bold !important;
          }
        }
      `}</style>
         <div className='overflow-x-auto'>
      <Table 
        className='border-1 rounded-lg border-gray-200'   
        loading={loading}    
        dataSource={genNumberTable(data)} 
        columns={columns}  
        pagination={forPrint ? false : { pageSize: 4 }}
        rowKey={'id'} 
        scroll={{ x: 'max-content' }}  
        // summary={() => (
        //   <Table.Summary fixed={true}>
        //     <Table.Summary.Row>
        //       <Table.Summary.Cell index={0} colSpan={4} style={summaryStyle}>รวมทั้งหมด</Table.Summary.Cell>
        //       <Table.Summary.Cell index={4} align="center" style={summaryStyle}>{totalPassengers}</Table.Summary.Cell>
        //       <Table.Summary.Cell index={5} align="center" style={summaryStyle}>{formatNumber(totalAmount)}</Table.Summary.Cell>
        //       <Table.Summary.Cell index={6} align="center" style={summaryStyle}>{formatNumber(totalCommission)}</Table.Summary.Cell>
        //       <Table.Summary.Cell index={9} align="center" style={summaryStyle}>{formatNumber(totalNet)}</Table.Summary.Cell>
        //     </Table.Summary.Row>
        //   </Table.Summary>
        // )}
      />
      </div>
    </div>
  );
};

export default PaymentInfoTable;
