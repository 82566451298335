import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Button, message ,Modal} from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { formatDateToYMD } from '../../function/formatDateToYMD';
import dayjs from 'dayjs';
import { useMutationInsertPaymentTransection, useMutationUpdateDarf } from '../../hooks/useFetchPayment';
import { useModal } from "react-modal-hook";

const headerStyle = {
  color: '#525252',
};

const PaymentDetailTable = ({ data = [], onDarft, onPay, loading, forPrint = false }) => {
  const { currentColor } = useStateContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // สำหรับเก็บข้อมูลที่ถูกเลือก
 
  // ตั้งค่า selectedRowKeys เมื่อ data เปลี่ยนแปลง
  useEffect(() => {
    const draftKeys = data
      .filter(item => item.draft === 1)
      .map(item => item.id);
    setSelectedRowKeys(draftKeys);
  }, [data]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      const allKeys = data.map(item => item.id);
      setSelectedRowKeys(allKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'หมายเลขการจอง', 
      align: 'center', 
      dataIndex: 'bookingID', 
      key: 'bookingID',
      onHeaderCell: () => ({ style: headerStyle }) 
    },
    {
      title: 'ยานพาหนะ', 
      align: 'center', 
      dataIndex: 'nameTh', 
      key: 'nameTh',
      onHeaderCell: () => ({ style: headerStyle })
    },
    {
      title: 'วันที่', 
      align: 'center', 
      dataIndex: 'date', 
      key: 'date',
      render: (text) => formatDateToYMD(text),
      onHeaderCell: () => ({ style: headerStyle })
    },
    {
      title: 'วันที่จอง', 
      align: 'center', 
      dataIndex: 'createDate', 
      key: 'createDate',
      render: (text) => formatDateToYMD(text),
      onHeaderCell: () => ({ style: headerStyle })
    },
    {
      title: 'สถานีต้นทาง', 
      align: 'center', 
      dataIndex: 'originStation', 
      key: 'originStation',
      // render: (text) => formatDateToYMD(text),
      onHeaderCell: () => ({ style: headerStyle })
    },
    {
      title: 'สถานีปลายทาง', 
      align: 'center', 
      dataIndex: 'destinationStation', 
      key: 'destinationStation',
      // render: (text) => formatDateToYMD(text),
      onHeaderCell: () => ({ style: headerStyle })
    },
    {
      title: 'รอบโดยสาร', 
      align: 'center', 
      dataIndex: 'startTime', 
      key: 'startTime',
      render: (text) => dayjs(text, 'HH:mm:ss').format('HH:mm'),
      onHeaderCell: () => ({ style: headerStyle })
    },
    {
      title: 'ราคา', 
      align: 'center', 
      dataIndex: 'costTotlePrice', 
      key: 'costTotlePrice',
      render: (text) => Number(text)?.toLocaleString(),  
      onHeaderCell: () => ({ style: headerStyle }),
      fixed: 'right'
    },
    // { 
    //   title: () => (
    //     <Checkbox
    //       indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
    //       checked={selectedRowKeys.length === data.length}
    //       onChange={selectAll}
    //     >
    //       ชำระเงิน
    //     </Checkbox>
    //   ),
    //   key: 'payment',
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={selectedRowKeys.includes(record.id)}
    //       onChange={(e) => {
    //         const selectedKeys = [...selectedRowKeys];
    //         if (e.target.checked) {
    //           if (!selectedKeys.includes(record.id)) {
    //             selectedKeys.push(record.id);
    //           }
    //         } else {
    //           const index = selectedKeys.indexOf(record.id);
    //           if (index > -1) {
    //             selectedKeys.splice(index, 1);
    //           }
    //         }
    //         setSelectedRowKeys(selectedKeys);
    //       }}
    //     />
    //   ),
    //   onHeaderCell: () => ({ style: headerStyle })
    // }
  ];

  const handlePay = () => {
    // ใช้ selectedRowKeys เพื่อนำข้อมูลออกมา
    const selectedData = data.filter(item => selectedRowKeys.includes(item.id));
    onPay(selectedData)
  };
  const handleDraft = () => {
    const draftData = data.map(item => {
      // ถ้า item ถูกเลือกอยู่ใน selectedRowKeys ให้ตั้ง draft เป็น 1
      if (selectedRowKeys.includes(item.id)) {
        return { ...item, draft: 1 };
      }

      // ถ้าไม่ได้ถูกเลือก ให้ใช้ค่าดั้งเดิม
      return { ...item, draft: 0 };
    });

    onDarft(draftData)
  };
  
  return (
    <div className="payment-info-table">
      <div className='mt-3 mb-3 flex justify-end'>
        <Button onClick={handleDraft} type="default" style={{ marginRight: 8 }}>
          บันทึกแบบร่าง
        </Button>
        <Button onClick={handlePay} type="primary">
          ชำระ
        </Button>
      </div>
      <div className='overflow-x-auto'>
      <Table 
        className='border-1 rounded-lg border-gray-200'   
        loading={loading}    
        dataSource={genNumberTable(data)} 
        columns={columns}  
        pagination={forPrint ? false : { pageSize: 30 }}
        rowKey='id'
        rowSelection={rowSelection} // เพิ่ม rowSelection เพื่อตั้งค่า checkbox selection
        scroll={{ x: 'max-content' }}  
      />
      </div>
    
    </div>
  );
};

export default PaymentDetailTable;


// import React, { useState, useEffect } from 'react';
// import { Table, Checkbox, Button, message ,Modal} from 'antd';
// import { genNumberTable } from '../../function/genNumberTable';
// import { useStateContext } from '../../contexts/ContextProvider';
// import { formatDateToYMD } from '../../function/formatDateToYMD';
// import dayjs from 'dayjs';
// import { useMutationInsertPaymentTransection, useMutationUpdateDarf } from '../../hooks/useFetchPayment';
// import { useModal } from "react-modal-hook";

// const headerStyle = {
//   color: '#525252',
// };

// const PaymentDetailTable = ({ data = [], onInfo, onPay, loading, forPrint = false }) => {
//   const { currentColor } = useStateContext();
//   const [selectedRowKeys, setSelectedRowKeys] = useState([]); // สำหรับเก็บข้อมูลที่ถูกเลือก
//   const { isLoading: isLoadingfetchUpdateDarf, mutate:fetchUpdateDarf } = useMutationUpdateDarf();
//   const { isLoading: isLoadingfetchInsertPaymentTransection, mutate:fetchInsertPaymentTransection } = useMutationInsertPaymentTransection();
//   const [dataPay, setDataPay] = useState(null); // สำหรับเก็บข้อมูลที่ถูกเลือก

//   const [showAddModal, hideAddModal] = useModal(() => {   
//     console.log(dataPay)

//     const generalTickets = dataPay?.filter(item => item.isLocalPrice === 0).length;
//     const localTickets = dataPay?.filter(item => item.isLocalPrice === 1).length;
//     // Calculate the total cost
//     const totalCost = dataPay.reduce((sum, item) => sum + parseFloat(item.costTotlePrice), 0);

//     const handleOnOk = async () => {
//       const summaryPayment = {generalTickets,localTickets,totalCost:totalCost.toFixed(2)}
//       const payload = {summaryPayment,paymentDetail:dataPay}
//       console.log(payload)
//       await fetchInsertPaymentTransection(payload, {
//         onSuccess:() => {
//           message.success('ชำระเงินสำเร็จ')
//           hideAddModal()
//         },
//         onError: (error) => {
//           message.error(error.message)
//         },
//       })
//     }
//     return (
//       <Modal
//       title="สรุปการชำระเงิน"
//       open={showAddModal}
//       onOk={handleOnOk}
//       onCancel={hideAddModal}
//       okText="ยืนยัน"
//       cancelText="ยกเลิก"
//       loading={isLoadingfetchInsertPaymentTransection}
//   >
//       <p>{`จำนวนตั๋วทั้งหมด ${generalTickets+localTickets} ใบ`}</p>
//       <p>{`จำนวนตั๋ว ทั่วไป (${generalTickets}) ท้องถิ่น (${localTickets}) ใบ`}</p>
//       <p>{`ยอดที่ต้องชำระ ${totalCost.toFixed(2)} บาท`}</p>
//   </Modal>
//     )
//   },[dataPay,isLoadingfetchInsertPaymentTransection]);

//   // ตั้งค่า selectedRowKeys เมื่อ data เปลี่ยนแปลง
//   useEffect(() => {
//     const draftKeys = data
//       .filter(item => item.draft === 1)
//       .map(item => item.id);
//     setSelectedRowKeys(draftKeys);
//   }, [data]);

//   const onSelectChange = (selectedRowKeys) => {
//     setSelectedRowKeys(selectedRowKeys);
//   };

//   const selectAll = (e) => {
//     if (e.target.checked) {
//       const allKeys = data.map(item => item.id);
//       setSelectedRowKeys(allKeys);
//     } else {
//       setSelectedRowKeys([]);
//     }
//   };

//   const rowSelection = {
//     selectedRowKeys,
//     onChange: onSelectChange,
//   };

//   const columns = [
//     { 
//       title: 'ลำดับ',
//       align: 'center',  
//       width: 60, 
//       dataIndex: 'number',
//       key: 'number',
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'bookingId', 
//       align: 'center', 
//       dataIndex: 'bookingID', 
//       key: 'bookingID',
//       onHeaderCell: () => ({ style: headerStyle }) 
//     },
//     {
//       title: 'ยานพาหนะ', 
//       align: 'center', 
//       dataIndex: 'nameTh', 
//       key: 'nameTh',
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     {
//       title: 'วันที่', 
//       align: 'center', 
//       dataIndex: 'createDate', 
//       key: 'createDate',
//       render: (text) => formatDateToYMD(text),
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     {
//       title: 'รอบโดยสาร', 
//       align: 'center', 
//       dataIndex: 'startTime', 
//       key: 'startTime',
//       render: (text) => dayjs(text, 'HH:mm:ss').format('HH:mm'),
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     {
//       title: 'ราคา', 
//       align: 'center', 
//       dataIndex: 'costTotlePrice', 
//       key: 'costTotlePrice',
//       // render: (text) => dayjs(text, 'HH:mm:ss').format('HH:mm'),
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     // { 
//     //   title: () => (
//     //     <Checkbox
//     //       indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
//     //       checked={selectedRowKeys.length === data.length}
//     //       onChange={selectAll}
//     //     >
//     //       ชำระเงิน
//     //     </Checkbox>
//     //   ),
//     //   key: 'payment',
//     //   render: (_, record) => (
//     //     <Checkbox
//     //       checked={selectedRowKeys.includes(record.id)}
//     //       onChange={(e) => {
//     //         const selectedKeys = [...selectedRowKeys];
//     //         if (e.target.checked) {
//     //           if (!selectedKeys.includes(record.id)) {
//     //             selectedKeys.push(record.id);
//     //           }
//     //         } else {
//     //           const index = selectedKeys.indexOf(record.id);
//     //           if (index > -1) {
//     //             selectedKeys.splice(index, 1);
//     //           }
//     //         }
//     //         setSelectedRowKeys(selectedKeys);
//     //       }}
//     //     />
//     //   ),
//     //   onHeaderCell: () => ({ style: headerStyle })
//     // }
//   ];

//   const handlePay = () => {
//     // ใช้ selectedRowKeys เพื่อนำข้อมูลออกมา
//     const selectedData = data.filter(item => selectedRowKeys.includes(item.id));

//     if (selectedData.length === 0){
//       message.error("กรุณาเลือก bookingId ในการชำระเงิน")
//       return
//     }
//     // console.log('Selected Data for Export:', selectedData);
//     setDataPay(selectedData)
//     showAddModal()
//     // ทำงานเพิ่มเติมที่นี่ เช่น ส่งข้อมูลไปที่เซิร์ฟเวอร์ หรือสร้างไฟล์ดาวน์โหลด
//   };
//   const handleDraft = () => {
//     const draftData = data.map(item => {
//       // ถ้า item ถูกเลือกอยู่ใน selectedRowKeys ให้ตั้ง draft เป็น 1
//       if (selectedRowKeys.includes(item.id)) {
//         return { ...item, draft: 1 };
//       }

//       // ถ้าไม่ได้ถูกเลือก ให้ใช้ค่าดั้งเดิม
//       return { ...item, draft: 0 };
//     });
//     console.log('Draft Data:', draftData);
//     const payload = {booking:draftData}
//     fetchUpdateDarf(payload,{
//     onSuccess:() => {
//       message.success('บันทึกแบบร่างสำเร็จ')
//     },
//     onError: (error) => {
//       message.error(error.message)
//     }})
//     // ทำงานเพิ่มเติมที่นี่ เช่น ส่งข้อมูลไปที่เซิร์ฟเวอร์ หรือบันทึกข้อมูลแบบร่าง
//   };
  
//   return (
//     <div className="payment-info-table">
//       <div className='mt-3 mb-3 flex justify-end'>
//         <Button onClick={handleDraft} type="default" style={{ marginRight: 8 }}>
//           บันทึกแบบร่าง
//         </Button>
//         <Button onClick={handlePay} type="primary">
//           ชำระ
//         </Button>
//       </div>
//       <Table 
//         className='border-1 rounded-lg border-gray-200'   
//         loading={loading}    
//         dataSource={genNumberTable(data)} 
//         columns={columns}  
//         pagination={forPrint ? false : { pageSize: 30 }}
//         rowKey='id'
//         rowSelection={rowSelection} // เพิ่ม rowSelection เพื่อตั้งค่า checkbox selection
//       />

    
//     </div>
//   );
// };

// export default PaymentDetailTable;
