// import React, { useState } from 'react';
// import { Table, Space, Popconfirm, Select, DatePicker,Tag,Input } from 'antd';
// import { genNumberTable } from '../../function/genNumberTable';
// import { useStateContext } from '../../contexts/ContextProvider';
// import { formatDateToYMD } from '../../function/formatDateToYMD';
// import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
// import dayjs from 'dayjs';
// const { Option } = Select;
// const headerStyle = {
//   color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
// };

// const { RangePicker } = DatePicker;

// const TicketsTable = ({ data, onEdit, onDelete ,onDateChange}) => {
//   const { currentColor } = useStateContext();

//   // State for filtering
//   const [filteredCompany, setFilteredCompany] = useState('ทั้งหมด');
//   const [filteredProvince, setFilteredProvince] = useState('ทั้งหมด');

//   // Get unique companies and provinces for filters
//   const companies = ['ทั้งหมด', ...new Set(data?.map((item) => item.nameCompany))];
//   const provinces = ['ทั้งหมด', ...new Set(data?.map((item) => item.province))];
//   const [searchBookingId, setSearchBookingId] = useState(''); // State for searching bookingId

//   // Filtered data
//   const filteredData = data?.filter((item) => {
//     return (
//       (filteredCompany === 'ทั้งหมด' || item.nameCompany === filteredCompany) &&
//       (filteredProvince === 'ทั้งหมด' || item.province === filteredProvince) &&
//       (searchBookingId === '' || item.bookingID.toString().includes(searchBookingId)) // Filter by bookingId
//     );
//   });
//   const columns = [
//     // { 
//     //   title: 'ลำดับ',
//     //   align: 'center',  
//     //   width: 60, 
//     //   dataIndex: 'number',
//     //   key: 'number',
//     //   onHeaderCell: () => ({ style: headerStyle })
//     // },
//     { 
//       title: 'หมายเลขการจอง',
//       align: 'center',  
//       dataIndex: 'bookingID',
//       key: 'bookingID',
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'ชื่อผู้โดยสาร',
//       align: 'center',  
//       dataIndex: 'name',
//       key: 'name',
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'สัญชาติ',
//       align: 'center',  
//       dataIndex: 'nationality',
//       key: 'nationality',
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     // { 
//     //   title: 'ชื่อบริษัท', 
//     //   align: 'center',
//     //   dataIndex: 'nameCompany', 
//     //   key: 'nameCompany', 
//     //   onHeaderCell: () => ({ style: headerStyle })
//     // },
//     { 
//       title: 'ต้นทาง', 
//       align: 'center',
//       dataIndex: 'originLocation', 
//       key: 'originLocation', 
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'ปลายทาง', 
//       align: 'center',
//       dataIndex: 'destinationLocation', 
//       key: 'destinationLocation', 
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'วันโดยสาร', 
//       align: 'center',
//       dataIndex: 'date', 
//       key: 'date', 
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'วันที่จอง', 
//       align: 'center',
//       dataIndex: 'createDate', 
//       key: 'createDate', 
//       render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm'),
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'จำนวนผู้โดยสาร', 
//       align: 'center',
//       dataIndex: 'totalPassengers', 
//       key: 'totalPassengers', 
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'ยอดเงิน', 
//       align: 'center',
//       dataIndex: 'costTotlePrice', 
//       key: 'costTotlePrice', 
//       onHeaderCell: () => ({ style: headerStyle }),
//       render: (text) => Number(text)?.toLocaleString(),  
//     },
//     { 
//       title: 'จองตั๋วจาก', 
//       align: 'center',
//       dataIndex: 'createFrom', 
//       key: 'createFrom', 
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'สร้างโดย', 
//       align: 'center',
//       dataIndex: 'createBy', 
//       key: 'createBy', 
//       onHeaderCell: () => ({ style: headerStyle })
//     },
//     { 
//       title: 'จังหวัด', 
//       align: 'center', 
//       dataIndex: 'province', 
//       key: 'province',
//       onHeaderCell: () => ({ style: headerStyle }) 
//     },
//     { 
//       title: 'สถานะ', 
//       align: 'center', 
//       dataIndex: 'status', 
//       key: 'status',
//       fixed: 'right',
//       onHeaderCell: () => ({ style: headerStyle }),
//       render: (status) => {
//         let color = '';
//         switch (status) {
//           case 'ยกเลิก':
//             color = 'red'; // สีสำหรับสถานะยกเลิก
//             break;
//           case 'ไม่แสดงตัว':
//               color = 'red'; // สีสำหรับสถานะยกเลิก
//               break;
//           case 'ยืนยัน':
//             color = 'green'; // สีสำหรับสถานะยืนยัน
//             break;
//           case 'รอ':
//             color = 'orange'; // สีสำหรับสถานะรอ
//             break;
//           default:
//             color = 'gray'; // สีสำหรับสถานะที่ไม่ตรงเงื่อนไข
//         }
//         return <Tag color={color}>{status}</Tag>;
//       }
//     },
//   ];

//   return (
//     <div>
//       <div className="mb-4 flex space-x-4 justify-between">
//         <div>
//         <Input
//           placeholder="ค้นหา Booking ID"
//           value={searchBookingId}
//           onChange={(e) => setSearchBookingId(e.target.value)}
//           style={{ width: 200 }}
//         />
//         </div>
//         <div>
//        <DatePickerProvider>
//            <RangePicker 
//             // defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
//             defaultValue={[dayjs(), dayjs().endOf('month')]}
//              format="YYYY-MM-DD"
//              onChange={onDateChange}
//            />
//          </DatePickerProvider>
//         <Select
//           placeholder="เลือกบริษัท"
//           onChange={(value) => setFilteredCompany(value)}
//           value={filteredCompany}
//           style={{ width: 160 }}
//         >
//           {companies.map((company) => (
//             <Option key={company} value={company}>
//               {company}
//             </Option>
//           ))}
//         </Select>
//         <Select
//           placeholder="เลือกจังหวัด"
//           onChange={(value) => setFilteredProvince(value)}
//           value={filteredProvince}
//           style={{ width: 160 }}
//         >
//           {provinces.map((province) => (
//             <Option key={province} value={province}>
//               {province}
//             </Option>
//           ))}
//         </Select>
//         </div>
//       </div>
//       <div className='overflow-x-auto'>
//       <Table
//         className="border-1 rounded-lg border-gray-200"
//         dataSource={genNumberTable(filteredData)}
//         columns={columns}
//         pagination={{ pageSize: 8 }}
//         rowKey={'id'}
//         scroll={{ x: 'max-content' }}  
//       />
//       </div>
//     </div>
//   );
// };
// //

// export default TicketsTable;

import React, { useState } from 'react';
import { Table, Select, DatePicker, Tag, Input } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { formatDateToYMD } from '../../function/formatDateToYMD';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;

const TicketsTable = ({ data, onEdit, onDelete, onDateChange }) => {
  const { currentColor } = useStateContext();

  // State for filtering
  const [filteredCompany, setFilteredCompany] = useState('ทั้งหมด');
  const [filteredProvince, setFilteredProvince] = useState('ทั้งหมด');
  const [searchBookingId, setSearchBookingId] = useState(''); // State for searching bookingId

  // Get unique companies and provinces for filters
  const companies = ['ทั้งหมด', ...new Set(data?.map((item) => item.nameCompany))];
  const provinces = ['ทั้งหมด', ...new Set(data?.map((item) => item.province))];

  // Filtered data
  const filteredData = data?.filter((item) => {
    return (
      (filteredCompany === 'ทั้งหมด' || item.nameCompany === filteredCompany) &&
      (filteredProvince === 'ทั้งหมด' || item.province === filteredProvince) &&
      (searchBookingId === '' || item.bookingID.toString().includes(searchBookingId)) // Filter by bookingId
    );
  });

  const columns = [
    { 
      title: 'หมายเลขการจอง',
      align: 'center',  
      dataIndex: 'bookingID',
      key: 'bookingID',
    },
    { 
      title: 'ชื่อผู้โดยสาร',
      align: 'center',  
      dataIndex: 'name',
      key: 'name',
    },
    { 
      title: 'สัญชาติ',
      align: 'center',  
      dataIndex: 'nationality',
      key: 'nationality',
    },
    { 
      title: 'ต้นทาง', 
      align: 'center',
      dataIndex: 'originLocation', 
      key: 'originLocation',
    },
    { 
      title: 'ปลายทาง', 
      align: 'center',
      dataIndex: 'destinationLocation', 
      key: 'destinationLocation',
    },
    { 
      title: 'วันโดยสาร', 
      align: 'center',
      dataIndex: 'date', 
      key: 'date',
    },
    { 
      title: 'วันที่จอง', 
      align: 'center',
      dataIndex: 'createDate', 
      key: 'createDate', 
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm'),
    },
    { 
      title: 'จำนวนผู้โดยสาร', 
      align: 'center',
      dataIndex: 'totalPassengers', 
      key: 'totalPassengers',
    },
    { 
      title: 'ยอดเงิน', 
      align: 'center',
      dataIndex: 'costTotlePrice', 
      key: 'costTotlePrice', 
      render: (text) => Number(text)?.toLocaleString(),  
    },
    { 
      title: 'จองตั๋วจาก', 
      align: 'center',
      dataIndex: 'createFrom', 
      key: 'createFrom',
    },
    { 
      title: 'สร้างโดย', 
      align: 'center',
      dataIndex: 'createBy', 
      key: 'createBy',
    },
    { 
      title: 'จังหวัด', 
      align: 'center', 
      dataIndex: 'province', 
      key: 'province',
    },
    { 
      title: 'สถานะ', 
      align: 'center', 
      dataIndex: 'status', 
      key: 'status',
      fixed: 'right',
      render: (status) => {
        let color = '';
        switch (status) {
          case 'ยกเลิก':
          case 'ไม่แสดงตัว':
            color = 'red';
            break;
          case 'ยืนยัน':
            color = 'green';
            break;
          case 'รอ':
            color = 'orange';
            break;
          default:
            color = 'gray';
        }
        return <Tag color={color}>{status}</Tag>;
      }
    },
  ];

  return (
    <div>
      <div className="mb-4 flex flex-col md:flex-row justify-between w-full">
        <div className="flex-grow mb-2 md:mb-0 w-full">
          <Input
            className=' md:w-[250px]'
            placeholder="ค้นหา หมายเลขการจอง"
            value={searchBookingId}
            onChange={(e) => setSearchBookingId(e.target.value)}
          />
        </div>
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 md:justify-end w-full">
          <DatePickerProvider>
            <RangePicker
              defaultValue={[dayjs(), dayjs().endOf('month')]}
              format="YYYY-MM-DD"
              onChange={onDateChange}
              className="mb-2 md:mb-0 md:w-[250px]" 
            />
          </DatePickerProvider>
          <Select
            placeholder="เลือกบริษัท"
            onChange={(value) => setFilteredCompany(value)}
            value={filteredCompany}
           className="mb-2 md:mb-0 md:w-[170px]"
          >
            {companies.map((company) => (
              <Option key={company} value={company}>
                {company}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="เลือกจังหวัด"
            onChange={(value) => setFilteredProvince(value)}
            value={filteredProvince}
            className="mb-2 md:mb-0 md:w-[170px]"
          >
            {provinces.map((province) => (
              <Option key={province} value={province}>
                {province}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className='overflow-x-auto'>
        <Table
          className="border-1 rounded-lg border-gray-200"
          dataSource={genNumberTable(filteredData)}
          columns={columns}
          pagination={{ pageSize: 8 }}
          rowKey={'id'}
          scroll={{ x: 'max-content' }}  
        />
      </div>
    </div>
  );
  
  
};

export default TicketsTable;
