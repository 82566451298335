import React from 'react';
import FromSchedule from '../../components/Form/FromSchedule';
function Add({ formRef }) {
  const initialValues={
    time: [
      { startTime: null, duration:null  }
    ],
    price:300,
    localPrice:280,
    day:null,
    scheduleType:"everyday"
  }
  return (
    <div className='mt-5 pb-3'>
      <FromSchedule  formRef={formRef}  initialValues={initialValues}/>
    </div>
  );
}
export default Add





// {
//   "rangPicker": [
//       "2024-07-24T17:00:00.000Z",
//       "2024-08-20T17:00:00.000Z"
//   ],
//   "scheduleType": "everyday",
//   "vehicle": 2,
//   "originLocation": 2,
//   "originStation": 4,
//   "destinationLocation": 2,
//   "destinationStation": 4,
//   "price": 300,
//   "localPrice": 280,
//   "time": [
//       {
//           "startTime": "2024-07-25T02:00:00.000Z",
//           "duration": "2024-07-24T21:00:00.000Z"
//       }
//   ],
//   "day": null
// }

// {
//   "rangPicker": [
//       "2024-07-24T17:00:00.000Z",
//       "2024-08-18T17:00:00.000Z"
//   ],
//   "scheduleType": "specificDays",
//   "vehicle": 2,
//   "originLocation": 1,
//   "originStation": 2,
//   "destinationLocation": 2,
//   "destinationStation": 3,
//   "price": 300,
//   "localPrice": 280,
//   "time": [
//       {
//           "startTime": "2024-07-25T02:00:00.000Z",
//           "duration": "2024-07-24T20:00:00.000Z"
//       }
//   ],
//   "day": [
//       "Mon",
//       "Tue",
//       "Wed"
//   ]
// }