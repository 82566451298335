import React from 'react';
import ReactDOM from 'react-dom';
import { ModalProvider } from "react-modal-hook";
import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
const queryClient = new QueryClient();


ReactDOM.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
    <ContextProvider>
      <ModalProvider>
        <App />
      </ModalProvider>,
    </ContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
