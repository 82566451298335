import React,{useState,useRef} from 'react';
import { Header } from '../../components';
import { Button,message } from 'antd';
import BoatTable from '../../components/Table/BoatTable';
import { boatData } from '../../data/mock/boat';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal } from "../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import { useFetchBoatAll} from "../../hooks/useFetchBoat";
import { useFetchVehicleAll,useMutationVehicleByID,useMutationVehicle,useMutationDeleteVehicle,useMutationUpdateVehicle} from "../../hooks/useFetchVehicle";
import Add from './Add';
import Edit from './Edit';
import { transformData} from './response';
import { validateFieldsImage} from '../../function/validateFieldsImage';

const Vehicle = () => {
  const titlePage = 'ยานพาหนะ'
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const { currentColor,btnColor} = useStateContext();
  const [dataEdit, setDataEdit] = useState(null);
  const { data: boat, isLoading:isLoadingBoat,refetch:refetchBoat } = useFetchBoatAll();

  const { data: vehicleAll, isLoading:isLoadingVehicleAll,refetch:refetchVehicleAll} = useFetchVehicleAll();
  // const { isLoading:isLoadingScheduleByID,mutate:mutateScheduleByID} = useMutationScheduleByID();
  const { isLoading:isLoadingUpdateVehicle,mutate:mutateUpdateVehicle} = useMutationUpdateVehicle();
  const { isLoading:isLoadingDeleteVehicle, mutate:mutateDeleteVehicle} = useMutationDeleteVehicle();
  const { isLoading:isLoadingAddVehicle , mutate:fetchAddVehicle} = useMutationVehicle();




  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      try {
        const value =  await formAddRef.current.validateFields();
        console.log(value)
        const isImageVehicleValid = await validateFieldsImage(value?.imageVehicle);
        if (!isImageVehicleValid) {
            return;
        }
        const body = transformData(value)
        await fetchAddVehicle(body, {
          onSuccess:() => {
            refetchVehicleAll()
            message.success('เพิ่มข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
        await formAddRef.current.submit();
        hideAddModal();
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='สร้าง' cancelText='ยกเลิก' width={500}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading={isLoadingAddVehicle}>
        <Add formRef={formAddRef} />
      </Modal>
    )
  });

  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {
      try {
        const value =  await formEditRef.current.validateFields();
        const isImageVehicleValid = await validateFieldsImage(value?.imageVehicle);
        if (!isImageVehicleValid) {
           return;
        }
        console.log(value)
        const id = dataEdit.id
        const body = transformData(value)
        const payload = {id, body}
        await mutateUpdateVehicle(payload, {
          onSuccess:() => {
            refetchVehicleAll()
            message.success('แก้ไขข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
        await formEditRef.current.submit();
        hideEditModal()
      } catch (error) {
        console.error('Form validation error:', error);
      }
    
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={500}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading={isLoadingUpdateVehicle}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
  )},[dataEdit]);
  //#endregion

  const handleEdit = (record) => {
    setDataEdit(record);
    showEditModal()
  };

  const handleDelete = (record) => {
    console.log('Delete action for:', record);

    mutateDeleteVehicle(record.id,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchVehicleAll()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
        {/* <Button onClick={showAddModal} color="white" bgColor={currentColor} text="เพิ่ม" size="[8px]" borderRadius="8px" high="[30px]" width="[90px]" /> */}
          <Button onClick={showAddModal} style={{ backgroundColor: btnColor ,color:'#FFFF'}}>เพิ่ม</Button>
      </div>
      <BoatTable data={vehicleAll} onEdit={handleEdit} onDelete={handleDelete} loading = {isLoadingVehicleAll} />
    </div>
  );
};

export default Vehicle;
