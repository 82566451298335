import React from 'react';
import { useNavigate, Link,useParams } from "react-router-dom";
import { Header } from '../../components';
import { Button,Breadcrumb ,Typography} from 'antd';
import { useStateContext } from '../../contexts/ContextProvider';
import PaymentHistoryTable from '../../components/Table/PaymentHistoryTable';
import PaymentHistoryAdminTable from '../../components/Table/PaymentHistoryAdminTable';
import { useFetchPaymentHistory,useFetchPaymentCompanyHistory } from '../../hooks/useFetchPayment';

function PaymentHistory() {
const { currentColor,getProfile} = useStateContext();

  const { Text } = Typography;
  const titlePage = "ประวัติการชำระ"
  const {id} = useParams();
  // console.log(id)
  const { data: paymentHistory, isLoading: isLoadingPaymentHistory, refetch:refetchPaymentHistory } = useFetchPaymentHistory(id);
  const { data: paymentCompanyHistory, isLoading: isLoadingPaymentCompanyHistory, refetch:refetchPaymentCompanyHistory } = useFetchPaymentCompanyHistory(id);
  
  const breadcrumbItems = [
    { title:  getProfile?.role === 'customer' ? <Link to="/payment-sale-info">{'ข้อมูลการขาย'}</Link> :<Link to="/payment-info">{'ข้อมูลยอดเงิน'}</Link>},
    { title: <Text strong>{titlePage}</Text> },
  ];

  return (
   <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
      </div>
      <Breadcrumb style={{ margin: "1rem 0" }} items={breadcrumbItems} />
        {  getProfile?.role === 'customer' ?   <PaymentHistoryTable data={paymentHistory}  loading={isLoadingPaymentHistory}  /> :  <PaymentHistoryAdminTable data={paymentCompanyHistory}  loading={isLoadingPaymentCompanyHistory}/> }
      
    </div>
  );
}

export default PaymentHistory;
