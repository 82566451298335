import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';
import { message } from 'antd';

export const useFetchEmployeeAll = (startDate, endDate) => {
  return useQuery(
    ['getEmployeeAll'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/employee/get-employee-all`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useMutationEmployeeByCompany = () => {
  return useMutation(
    ['getEmployeeByCompany'],
    async (payload) => {
      const endpoint = `/api/v2/employee/get-employee-by-company/${payload}`;
      const { result } = await GET(endpoint);
      return result;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useMutationAddEmployee = () => {
  return useMutation(["addEmployee"],
    async (payload) => {
     const path = `/api/v2/employee/create-employee`
      const endPoin = path;
      await POST(endPoin,payload);
    }
  );
};

export const useMutationDeleteEmployee = () => {
  return useMutation(["deleteEmployee"],
    async (payload) => {
      const endPoin = `/api/v2/employee/delete-employee/${payload}`;
      await DELETE(endPoin);
    }
  );
};



export const useMutationUpdateEmployee = () => {
  return useMutation(["updateEmployee"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v2/employee/update-employee/${id}`;
      await PUT(endPoin,body);
    }
  );
};