import React,{useState,useRef} from 'react';
import { Header } from '../../components';
import { Button,message } from 'antd';
import CustomerTable from '../../components/Table/CustomerTable';
import { boatData } from '../../data/mock/boat';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal } from "../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import { useFetchCustomerAll,useMutationAddCustomer,useMutationDeleteCustomer,useMutationUpdateCustomer} from "../../hooks/useFetchCustomer";
import { useFetchVehicleAll,useMutationVehicleByID,useMutationVehicle,useMutationDeleteVehicle,useMutationUpdateVehicle} from "../../hooks/useFetchVehicle";
import Add from './Add';
import Edit from './Edit';
import { transformData} from './response';
import { validateFieldsImage} from '../../function/validateFieldsImage';

const Customer = () => {
  const titlePage = 'ลูกค้า'
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const { currentColor,btnColor} = useStateContext();
  const [dataEdit, setDataEdit] = useState(null);
  const { data: customerAll, isLoading:isLoadingCustomerAll,refetch:refetchCustomerAll } = useFetchCustomerAll();

  const { data: vehicleAll, isLoading:isLoadingVehicleAll,refetch:refetchVehicleAll} = useFetchVehicleAll();
  const { isLoading:isLoadingAddCustomer,mutate:mutateAddCustomer} = useMutationAddCustomer();
  const { isLoading:isLoadingUpdateCustomer,mutate:mutateUpdateCustomere} = useMutationUpdateCustomer();
  const { isLoading:isLoadingDeleteCustomer, mutate:mutateDeleteCustomer} = useMutationDeleteCustomer();


  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      try {
        const value =  await formAddRef.current.validateFields();
        const body = transformData(value)
        console.log('body',body)
        await mutateAddCustomer(body, {
          onSuccess:() => {
            refetchCustomerAll()
            message.success('เพิ่มข้อมูลสำเร็จ')
          },
          onError: (error) => {
            console.log(error)
            message.error(error?.response.data?.message)
          },
        })
        await formAddRef.current.submit();
        hideAddModal();
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='ส่งอีเมลเชิญผู้ใช้งาน' cancelText='ยกเลิก' width={800}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading={isLoadingAddCustomer}>
        <Add formRef={formAddRef} />
      </Modal>
    )
  },[isLoadingAddCustomer]);

  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {
      try {
        const value =  await formEditRef.current.validateFields();
        // console.log(value)
        const id = dataEdit.id
        const body = transformData(value)
        const payload = {id, body}
        await mutateUpdateCustomere(payload, {
          onSuccess:() => {
            refetchCustomerAll()
            formEditRef.current.submit();
            hideEditModal()
            message.success('แก้ไขข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.error(error?.response.data?.message)
          },
        })
    
      } catch (error) {
        console.error('Form validation error:', error);
      }
    
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={800}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading={isLoadingUpdateCustomer}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
  )},[dataEdit,isLoadingUpdateCustomer]);
  //#endregion

  const handleEdit = (record) => {
    setDataEdit(record);
    showEditModal()
  };

  const handleDelete = (record) => {
    console.log('Delete action for:', record);

    mutateDeleteCustomer(record.id,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchCustomerAll()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
        {/* <Button onClick={showAddModal} color="white" bgColor={currentColor} text="เพิ่ม" size="[8px]" borderRadius="8px" high="[30px]" width="[90px]" /> */}
          <Button onClick={showAddModal} style={{ backgroundColor: btnColor ,color:'#FFFF'}}>เพิ่ม</Button>
      </div>
      <CustomerTable data={customerAll} onEdit={handleEdit} onDelete={handleDelete} loading = {isLoadingVehicleAll} />
    </div>
  );
};

export default Customer;
