import React from 'react';
import { Form, Input, Button, Checkbox,message } from 'antd';
import { useNavigate } from 'react-router-dom';
import takeketLogo from "../../assets/images/logo/takeket.png";
import { useMutationForgetPassword } from '../../hooks/useAuthen';

function ForgetPassword (){
  const navigate = useNavigate();
  //http://localhost:3000/chang-password-success?token=

  const {mutate:fetchForgetPassword,isLoading:isLoadingForgetPassword} = useMutationForgetPassword();
  const onFinish = (values) => {
    console.log('Success:', values);
    const body = {
      email:values.email,
    }
    fetchForgetPassword( { body },{
      onSuccess: async () => {
        await message.success('ส่งคำร้องสำเร็จ')
        await navigate('/login');
      },
      onError: (error) => {
        console.log(error)
        message.error(error?.response?.data?.message||error.message)
      },
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };



  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img
                src={takeketLogo}
                alt="logo"
                className="w-[230px]"
              />
        </a>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            รีเซ็ตรหัสผ่าน
          </h2>
          <Form
            name="changePassword"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            layout="vertical"
          >
             <Form.Item
              label="อีเมล์"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
                {
                  type: 'email',
                  message: 'Please enter a valid email address!',
                },
              ]}
            >
                <Input placeholder="name@company.com" />

            </Form.Item>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-3">
               กรุณาใส่อีเมล์ของคุณ เราจะทำการส่งลิ้งค์เพื่อตั้งรหัสผ่านใหม่ไปยังอีเมล์ของคุณ
              </p>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="w-full" loading={isLoadingForgetPassword}>
                ยืนยัน
              </Button>
            </Form.Item>
            <Form.Item>
                <Button
                  type="link"
                  className="w-full text-lg"
                  onClick={() => navigate('/login')} 
                >
                 เข้าสู่ระบบ ?
                </Button>
              </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
