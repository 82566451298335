
export function transformData(data) {
  return {
    ...data,
    picture:data?.picture ||"",
  }
}
// export function transformData(data) {
//   if (data.day ) {
//     // รูปแบบที่มีฟิลด์ day
//     return {data:data.time.flatMap(timeEntry => 
//       data.day.map(day => ({
//         startDate: data.rangPicker[0],
//         endDate: data.rangPicker[1],
//         scheduleType: data.scheduleType,
//         vehicleId: data.vehicle,
//         originLocationId: data.originLocation,
//         originStationId: data.originStation,
//         destinationLocationId: data.destinationLocation,
//         destinationStationId: data.destinationStation,
//         price: data.price,
//         localPrice: data.localPrice,
//         startTime: timeEntry.startTime,
//         duration: timeEntry.duration,
//         day: day
//       }))
//     )};
//   } else {
//     // รูปแบบที่ไม่มีฟิลด์ day
//     return {data:data.time.map(timeEntry => ({
//       startDate: data.rangPicker[0],
//       endDate: data.rangPicker[1],
//       scheduleType: data.scheduleType,
//       vehicleId: data.vehicle,
//       originLocationId: data.originLocation,
//       originStationId: data.originStation,
//       destinationLocationId: data.destinationLocation,
//       destinationStationId: data.destinationStation,
//       price: data.price,
//       localPrice: data.localPrice,
//       startTime: timeEntry.startTime,
//       duration: timeEntry.duration,
//       day:null
//     }))};
//   }
// }


// export function convertBackData(transformedData) {
//   // Extract the data array from the transformed data
//   const dataArray = transformedData.data;

//   // Check if the data has day values
//   const hasDayField = dataArray.some(item => item.day !== null);

//   if (hasDayField) {
//     // Original format with 'day' field
//     return {
//       rangPicker: [dataArray[0].startDate, dataArray[0].endDate],
//       scheduleType: dataArray[0].scheduleType,
//       vehicle: dataArray[0].vehicleId,
//       originLocation: dataArray[0].originLocationId,
//       originStation: dataArray[0].originStationId,
//       destinationLocation: dataArray[0].destinationLocationId,
//       destinationStation: dataArray[0].destinationStationId,
//       price: dataArray[0].price,
//       localPrice: dataArray[0].localPrice,
//       time: dataArray.map(item => ({
//         startTime: item.startTime,
//         duration: item.duration
//       })),
//       day: [...new Set(dataArray.map(item => item.day))] // Unique days
//     };
//   } else {
//     // Original format without 'day' field
//     return {
//       rangPicker: [dataArray[0].startDate, dataArray[0].endDate],
//       scheduleType: dataArray[0].scheduleType,
//       vehicle: dataArray[0].vehicleId,
//       originLocation: dataArray[0].originLocationId,
//       originStation: dataArray[0].originStationId,
//       destinationLocation: dataArray[0].destinationLocationId,
//       destinationStation: dataArray[0].destinationStationId,
//       price: dataArray[0].price,
//       localPrice: dataArray[0].localPrice,
//       time: dataArray.map(item => ({
//         startTime: item.startTime,
//         duration: item.duration
//       })),
//       day: null
//     };
//   }
// }
