import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FormCustomer from '../../components/Form/FormCustomer';
import { uploadImage } from '../../function/uploadImage';


function Edit({ formRef,data}) {

  const handleUploadImage = async (values) => {
    const fileImage = values[0];
    const uploadData = await uploadImage(fileImage)
    console.log(uploadData)
    formRef.current.setFieldsValue({ picture: uploadData?.fullpath });
  }

  return (
    <div className='mt-5 pb-3'>
      <FormCustomer
        formRef={formRef}
        uploadImage={handleUploadImage}
        initialValues={data}
        edit={true}
      />
    </div>
  );
}
export default Edit