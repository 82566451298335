import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';
import {boatData} from '../data/mock/boat'
import {scheduleData} from '../data/mock/schedule'

export const useFetchVehicleAll = () => {
    return useQuery(["getVehicleAll"], async () => {
      const endPoin = `/api/v2/vehicle/get-vehicle-all`;
      const { result } = await GET(endPoin);
      // const {result} = scheduleData
      return result.vehicle;
    },{
      cacheTime:0
    }
  
  );
};

export const useFetchVehicleByCompany = () => {
  return useMutation(["getVehicleByCompany"],
    async (payload) => {
      const endPoin = `/api/v2/vehicle/get-vehicle-by-company/${payload}`;
      const { result } = await GET(endPoin);
      return result?.vehicle;
    }
  );
};


export const useMutationVehicleByID = () => {
  return useMutation(["getVehicleByID"],
    async (payload) => {
      const endPoin = `/api/v2/schedule/get-vehicle-by-id/?id=${payload}`;
      const { result } = await GET(endPoin);
      return result.vehicle;
    }
  );
};

export const useMutationVehicle = ()=> {
  return useMutation(["addVehicle"],
    async (payload) => {
      const endPoin = `/api/v2/vehicle/add-vehicle`;
      await POST(endPoin,payload);
    },
  );
};

export const useMutationUpdateVehicle = () => {
  return useMutation(["updateVehicle"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v2/vehicle/${id}`;
      await PUT(endPoin,body);
    }
  );
};

export const useMutationDeleteVehicle = () => {
  return useMutation(["deleteVehicle"],
    async (payload) => {
      const endPoin = `/api/v2/vehicle/${payload}`;
      await DELETE(endPoin);
    }
  );
};