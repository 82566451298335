import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link,useParams } from "react-router-dom";
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import PaymentDetailTable from '../../components/Table/PaymentDetailTable';
import { useFetchPaymentDetailByEmployee,useMutationUpdateDarf,useMutationInsertPaymentTransection } from "../../hooks/useFetchPayment";

import { DatePicker, Button ,Breadcrumb,Typography,message,Modal} from 'antd';
import dayjs from 'dayjs';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useModal } from "react-modal-hook";
const { RangePicker } = DatePicker;

function PayDetail() {
  const {id} = useParams();
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const { data: payment, isLoading: isLoadingPayment, refetch:refetchPayment } = useFetchPaymentDetailByEmployee(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'),id);
  const { isLoading: isLoadingfetchUpdateDarf, mutate:fetchUpdateDarf } = useMutationUpdateDarf();
  const { isLoading: isLoadingfetchInsertPaymentTransection, mutate:fetchInsertPaymentTransection } = useMutationInsertPaymentTransection();
  const [dataPay, setDataPay] = useState(null); // สำหรับเก็บข้อมูลที่ถูกเลือก
  
  const titlePage = 'ชำระเงิน';
  const { Text } = Typography;
  const { currentColor,btnColor} = useStateContext();
  const navigate =  useNavigate()
  const componentRef = useRef();



  const breadcrumbItems = [
    { title: <Link to="/payment-sale-info">{'ข้อมูลการขาย'}</Link> },
    { title: <Text strong>{titlePage}</Text> },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `
  });


  const [showAddModal, hideAddModal] = useModal(() => {   
    console.log(dataPay)
    const generalTickets = dataPay?.filter(item => item.isLocalPrice === 0).length;
    const localTickets = dataPay?.filter(item => item.isLocalPrice === 1).length;
    const totalCost = dataPay.reduce((sum, item) => sum + parseFloat(item.costTotlePrice), 0);
    const handleOnOk = async () => {
      const summaryPayment = {generalTickets,localTickets,totalCost:totalCost.toFixed(2),employeeId:id}
      const payload = {summaryPayment,paymentDetail:dataPay,}
      console.log(payload)
      await fetchInsertPaymentTransection(payload, {
        onSuccess:() => {
          message.success('ชำระเงินสำเร็จ')
          refetchPayment(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
          hideAddModal()
        },
        onError: (error) => {
          message.error(error.message)
        },
      })
    }
    return (
      <Modal
      title="สรุปการชำระเงิน"
      open={showAddModal}
      onOk={handleOnOk}
      onCancel={hideAddModal}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
      loading={isLoadingfetchInsertPaymentTransection}
  >
      <p>{`จำนวนตั๋วทั้งหมด ${generalTickets+localTickets} ใบ`}</p>
      <p>{`จำนวนตั๋ว ทั่วไป (${generalTickets}) ท้องถิ่น (${localTickets}) ใบ`}</p>
      <p>{`ยอดที่ต้องชำระ ${Number(totalCost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท`}</p>

  </Modal>
    )
  },[dataPay,isLoadingfetchInsertPaymentTransection]);






  const onDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  useEffect(() => {
    refetchPayment(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
  }, [dateRange]);


  const handlePay = (recode)=>{
    console.log(recode)

    if (recode.length === 0){
      message.error("กรุณาเลือก bookingId ในการชำระเงิน")
      return
    }
    setDataPay(recode)
    showAddModal()
  }


  const handleDarft = (recode)=>{
    console.log('handleDarft',recode)
    const payload = {booking:recode}
    fetchUpdateDarf(payload,{
    onSuccess:() => {
      message.success('บันทึกแบบร่างสำเร็จ')
    },
    onError: (error) => {
      message.error(error.message)
    }})
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between items-center mb-5'>
        <Header category="App" title={`${titlePage}`} />
        <div className='flex items-center space-x-4'>
          <DatePickerProvider>
            <RangePicker 
              defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
              format="YYYY-MM-DD"
              onChange={onDateChange}
            />
          </DatePickerProvider>
          <Button 
            type="primary" 
            icon={<PrinterOutlined />} 
            onClick={handlePrint}
            style={{ backgroundColor: btnColor, borderColor: btnColor }}
          >
            Print
          </Button>

        </div>
      </div>
      <Breadcrumb style={{ margin: "1rem 0" }} items={breadcrumbItems} />
      <div className="normal-view">
        <PaymentDetailTable data={payment} onPay={handlePay} onDarft={handleDarft} loading={isLoadingPayment} />
      </div>
    </div>
  );
}

export default PayDetail;