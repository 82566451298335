import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import TicketsTable from '../../components/Table/TicketsTable';
import TicketsCustomerTable from '../../components/Table/TicketsCustomerTable';
import PaymentInfoAdminTable from '../../components/Table/PaymentInfoAdminTable';
import { useFetchTicketAll,useFetchTicketCustomerAll ,useMutationCancelTicket} from "../../hooks/useFetchTicket";
import { DatePicker, Button,message } from 'antd';
import dayjs from 'dayjs';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";

const { RangePicker } = DatePicker;

function Ticket() {
  // const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs().endOf('month')]); //;วันนี้ถึงสิ้นเดือน
  const { getProfile } = useStateContext();
  
  const { data: ticketsAll, isLoading: isLoadingTicketAll, refetch:refetchTicketAll } = useFetchTicketAll(
    dateRange[0].format('YYYY-MM-DD'), 
    dateRange[1].format('YYYY-MM-DD')
  );

  const { data: ticketsCustomerAll, isLoading: isLoadingTicketCustomerAll, refetch:refetchTicketCustomerAll } = useFetchTicketCustomerAll(
    dateRange[0].format('YYYY-MM-DD'), 
    dateRange[1].format('YYYY-MM-DD')
  );
  const { mutate: cancelTicket } = useMutationCancelTicket();
  
  const titlePage = 'บัตรโดยสาร';

  const onDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  useEffect(() => {
    refetchTicketAll(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
  }, [dateRange]);

  const handleCancel = (recode) => {
    console.log('cancel',recode)
    message.loading('กรุณารอซักครู่...', 0);
    const payload = {
      bookingId: recode.bookingID
    }        
    cancelTicket(payload,{
      onSuccess: async () => {
        message.destroy(); 
        message.success('ยกเลิกการจองสำเร็จ')
        refetchTicketCustomerAll(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
      },
      onError: (error) => {
        message.destroy(); 
        message.error(error.response.data.message)
      },
    })
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between items-center mb-5'>
        <Header category="App" title={`${titlePage}`} />
         <div className='flex items-center space-x-4'>
       </div>
      </div>
      <div className="normal-view">
         {getProfile?.role === 'one2ticketEmployee' ?  
          <TicketsTable data={ticketsAll} loading={isLoadingTicketAll} forPrint={false}  onDateChange={onDateChange}/>
         :
         <TicketsCustomerTable data={ticketsCustomerAll} loading={isLoadingTicketCustomerAll} forPrint={false}  onCancel={handleCancel}  onDateChange={onDateChange}/>
         }
        
      </div>
    </div>
  );
}

export default Ticket;