import React, { useState } from 'react';
import { Form, Input, Select, InputNumber, DatePicker, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {provinces} from "../../data/provinces";
import { useMutationCheckStationInschedule} from '../../hooks/useFetchLocation';
function FormLocation({ formRef, onFinish, uploadImage,initialValues={},edit=false }) {
  console.log(edit)
  const {mutate:fetchCheckStationInschedule} = useMutationCheckStationInschedule();

  return (
    <Form name="picture" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
    
      <div className='grid lg:gap-x-5 lg:grid-cols-1 '>
           {/* เพิ่ม Select สำหรับเลือกจังหวัด */}
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="province" label="จังหวัด" rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}>
          <Select
            placeholder="เลือกจังหวัด"
            className="w-full h-10 rounded-lg" 
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {provinces.map(province => (
              <Select.Option key={province} value={province}>
                {province}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="nameLoactionTh" label="ชื่อสถานที่ (ไทย)" rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}>
          <Input
            id="tradername"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="ชื่อ"
          />
        </Form.Item>
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="nameLocationEng" label="ชื่อสถานที่ (อังกฤษ)" rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}>
          <Input
            id="tradername"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="ชื่อ"
          />
        </Form.Item>


      <p className="ml-3 mb-2 mt-5 block text-sm font-medium text-gray-700">ท่าเรือ</p>
      <Form.List name="stationList">
  {(fields, { add, remove }) => (
    <>
      <div className="max-h-[300px] overflow-y-auto pr-3">
        {fields.map(({ key, name, ...restField }) => (
          <Form.Item
            {...restField}
            key={key}
            rules={[{ required: true, message: 'Missing time' }]}
            className="m-0 p-0 w-full"
          >
            <div className="flex items-center gap-3">
              <Form.Item
                className="flex-[2]"  // Flex of 2 for this input
                label={key === 0 ? 'ชื่อ(ไทย)' : ''}
                name={[name, 'nameStationTh']}
                rules={[{ required: true, message: 'กรุณาระบุชื่อท่าเรือ (ไทย)' }]}
              >
                <Input
                  className="w-full"
                  placeholder="ชื่อ"
                />
              </Form.Item>
              <Form.Item
                className="flex-[2]"  // Flex of 2 for this input
                label={key === 0 ? 'ชื่อ(อังกฤษ)' : ''}
                name={[name, 'nameStationEng']}
                rules={[{ required: true, message: 'กรุณาระบุชื่อท่าเรือ (อังกฤษ)' }]}
              >
                <Input
                  className="w-full"
                  placeholder="ชื่อ"
                />
              </Form.Item>
              <Form.Item
                className="flex-[4]"  // Flex of 4 for this input to make it the longest
                label={key === 0 ? 'พิกัด' : ''}
                name={[name, 'map']}  // Use a descriptive name for the field
                rules={[{ required: true, message: 'กรุณาระบุพิกัด' }]}
              >
                <Input
                  className="w-full"
                  placeholder="JCGR+XV ตำบลเพ อำเภอเมืองระยอง ระยอง"
                />
              </Form.Item>
              {key !== 0 ? (
                <MinusCircleOutlined
                  className="mt-[-20px]"
                  onClick={ async() => {  

                      remove(name)
                    }
                  }
                />
              ) : null}
            </div>
          </Form.Item>
        ))}
      </div>
      <Form.Item>
        <Button
          className="w-[170px]"
          type="dashed"
          onClick={() => add()}
          icon={<PlusOutlined />}
        >
          เพิ่ม
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>

     
      </div>
    </Form>
  );
}

export default FormLocation;
