import React from 'react';
import { Table, Space, Tag,Popconfirm } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import dayjs from 'dayjs';
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const PromotionTable = ({ data, onEdit, onDelete,loading }) => {
  const { currentColor} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'ชื่อโปรโมชั่น', 
      align: 'center', 
      dataIndex: 'name', 
      key: 'name',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'รหัสโค้ด', 
      align: 'center', 
      dataIndex: 'code', 
      key: 'code' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'ส่วนลด', 
      align: 'center', 
      dataIndex: 'discount', 
      key: 'discount',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'โควต้า', 
      align: 'center', 
      dataIndex: 'quota', 
      key: 'quota',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'ใช้สิทธิ์ไปแล้ว', 
      align: 'center', 
      dataIndex: 'totalUseQty', 
      key: 'totalUseQty',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'วันเริ่มต้น',
      dataIndex: 'startDate',
      align: 'center',
      key: 'startDate',
      onHeaderCell: () => ({ style: headerStyle }),
      render: (text) => dayjs(text).format('D/M/YYYY')
    },
    {
        title: 'วันสิ้นสุด',
        align: 'center',
        dataIndex: 'endDate',
        key: 'endDate',
        onHeaderCell: () => ({ style: headerStyle }),
        render: (text) => dayjs(text).format('D/M/YYYY') 
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>

          <Popconfirm
            title="ลบข้อมูลยานพาหนะ"
            description="ยืนยันการลบข้อมูลยานพาหนะ ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
              <div className="text-red-500 cursor-pointer" >
                ลบ
              </div>

           </Popconfirm>
      
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return(
    <div className='overflow-x-auto'>
  <Table 
    className=' border-1 rounded-lg border-gray-200'   
    loading={loading}    
    dataSource={genNumberTable(data)} 
    columns={columns}  
    pagination={{ pageSize: 10}} 
    rowKey={'id'} 
    scroll={{ x: 'max-content' }}  
    />
    </div>)
};

export default PromotionTable;
