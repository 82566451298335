import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { Spin } from 'antd';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { RiContactsLine, RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import LogoOne2trickets from '../assets/images/logo/takeket.png';
import { links } from '../data/sidebar';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize, getProfile } = useStateContext();
  const [dropdownOpen, setDropdownOpen] = useState({});

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const toggleDropdown = (title) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  // console.log(getProfile)
  // Filter out links based on role
  const filteredLinks = links.map((item) => {
    if (getProfile?.role === 'customer') {
      return {
        ...item,
        links: item.links.filter((link) => link.route !== 'promotion'  && link.route !== 'customers' && link.route !== 'location'),
      };
    }

    if (getProfile?.role === 'customerEmployee') {
      return {
        ...item,
        links: item.links.filter((link) => {
          // ตรวจสอบค่าใน getProfile.sidebar และกรองเฉพาะลิงก์ที่มีค่า true
          const sidebarPermissions = getProfile?.sidebar || {};
          const routeKey = link.route; // ใช้ route เป็นคีย์ในการตรวจสอบ
          return sidebarPermissions[routeKey] === true;
        })
      };
    }

    if (getProfile?.role === 'one2ticketEmployee' && getProfile?.position !== 'Admin') {
      return {
        ...item,
        links: item.links
          .filter((link) => {
            // ตรวจสอบค่าใน getProfile.sidebar และกรองเฉพาะลิงก์ที่มีค่า true
            const sidebarPermissions = getProfile?.sidebar || {};
            const routeKey = link.route; // ใช้ route เป็นคีย์ในการตรวจสอบ
            return sidebarPermissions[routeKey] === true;
          })
          .map((link) => {
            // ตรวจสอบว่าลิงก์ที่มีชื่อ 'การเงิน' มี subLinks หรือไม่
            if (link.name === 'การเงิน' && link.subLinks) {
              return {
                ...link,
                subLinks: link.subLinks.filter(
                  (subLink) =>
                    subLink.route !== 'payment-sale-info' &&
                    subLink.route !== 'payment-history-receiving-money'
                ),
              };
            }
            return link; // คืนค่า link เดิมถ้าไม่ใช่ 'การเงิน'
          }),
      };
    }
    

   if (getProfile?.role === 'one2ticketEmployee' &&  getProfile?.position === 'Admin') {
      return {
        ...item,
        links: item.links.map((link) => {
          if (link.name === 'การเงิน' && link.subLinks) {
            return {
              ...link,
              subLinks: link.subLinks.filter((subLink) => 
                subLink.route !== 'payment-sale-info' && 
                subLink.route !== 'payment-history-receiving-money'
              ),
            };
          }
          // เพิ่มการกรองสำหรับ link ที่ชื่อ 'ตัวแทนขาย'
          if (link.name === 'ตัวแทนขาย') {
            return null; // ลบ link นี้ออก
          }
          return link; 
        }).filter(link => link !== null) // กรองลิงก์ที่ถูกลบ
      };
    }


 

    // if (getProfile?.role === 'one2ticketEmployee') {
    //   return {
    //     ...item,
    //     links: item.links.map((link) => {
    //       if (link.name === 'การเงิน' && link.subLinks) {
    //         return {
    //           ...link,
    //           subLinks: link.subLinks.filter((subLink) => 
    //             subLink.route !== 'payment-sale-info' && 
    //             subLink.route !== 'payment-history-receiving-money'
    //           ),
    //         };
    //       }



    //       // เพิ่มการกรองสำหรับ link ที่ชื่อ 'ตัวแทนขาย'
    //       if (link.name === 'ตัวแทนขาย') {
    //         return null; // ลบ link นี้ออก
    //       }
    //       return link; 
    //     }).filter(link => link !== null) // กรองลิงก์ที่ถูกลบ
    //   };
    // }


    return item;
  
  });

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 relative">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <img className='ml-2 w-[180px]' src={LogoOne2trickets} alt="Logo" />
              {/* <span className='text-[#cc2036]'>one2tickets</span> */}
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            {getProfile ? filteredLinks.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  link.subLinks ? (
                    <div key={link.name}>
                      <div onClick={() => toggleDropdown(link.name)} className={`${normalLink} cursor-pointer flex justify-between items-center `}>
                        <div className='flex h-50 items-center'>
                          {link.icon}
                          <span className="capitalize ml-5">{link.name}</span>
                        </div>
                        {dropdownOpen[link.name] ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                      </div>
                      {dropdownOpen[link.name] && (
                        <div className='pl-3 pr-3'>
                          <div className="bg-white shadow rounded-lg z-10 pt-1 pb-1">
                            {link.subLinks.map((subLink) => (
                              <NavLink
                                to={`/${subLink.route}`}
                                key={subLink.name}
                                onClick={handleCloseSideBar}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive ? currentColor : '',
                                })}
                                className={({ isActive }) => (isActive ? activeLink : normalLink)}
                              >
                                {subLink.icon}
                                <span className="capitalize">{subLink.name}</span>
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <NavLink
                      to={`/${link.route}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : '',
                      })}
                      className={({ isActive }) => (isActive ? activeLink : normalLink)}
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  )
                ))}
              </div>
            )):<div className='flex justify-center items-center h-[90vh]'><Spin /></div>}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
