import React, { useEffect ,useState} from "react";
import { Modal as AntdModal ,Result,Button} from 'antd';
import { useStateContext } from '../../contexts/ContextProvider';
import {
  WarningOutlined
} from '@ant-design/icons';
// import "antd/dist/antd.min.css"
import './Modal.css'

const Modal = ({okText='ตกลง',cancelText='ยกเลิก',title, open, onOk, onCancel ,children ,width,loading = false }) => {
  const { currentColor} = useStateContext();

  return (
    <AntdModal
      className="custom-modal"
      width={width}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } ,loading}}
      cancelButtonProps={{ className: 'ant-btn-cancel', style: { '--cancel-button-color-hover': currentColor } }}
    >
      <hr className='line-header' />
      {children}
    </AntdModal>
  );
};


const ModalResult = ({ open,title ,icon,time}) => {
  const [modal, contextHolder] = AntdModal.useModal();
  const [isModalVisible, setIsModalVisible] = useState(open);

  const countDown = () => {
    let secondsToGo = time;
    const instance = modal.info({
      content:(
        <Result
          icon={icon}
          title={<span style={{ fontSize: '16px' }}>{title}</span>} 
          style={{ padding:0}}
        />),
      icon: null,
      okButtonProps: { style: { display: 'none' }},
      width: '320px', // Adjust the width here,
      height: '140px' // Adjust the width here
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
      setIsModalVisible(!open); // ปิด Modal เมื่อนับถอยหลังเสร็จสิ้น
    }, secondsToGo * 1000);
  };

  useEffect(() => {
    setIsModalVisible(open); // เมื่อ Component ถูกโหลดขึ้นมาให้เปิด Modal เลย
    if (open) {
      countDown(); // เริ่มนับถอยหลังทันทีเมื่อ Modal ถูกเปิด
    }
    setIsModalVisible(!open)
  }, [open]);

  return (<>{contextHolder} </>);
};


const ModalDelete = ({ open,onCancel,onOk}) => {
    return (
      <AntdModal
        className="modal-delete-custome"
        open={open}
        footer={null}
        width={320}
        height={400}
      >
        <Result
          className="result-delete"
          icon={<WarningOutlined style={{color:'#FF4545'}} />}
          title={<span style={{ fontSize: '16px'}}>คุณต้องการลบข้อมูลนี้ ?</span>} 
          extra={[
            <Button key="cancle" style={{width:'130px', height:'40px'}} onClick={onCancel} >ยกเลิก</Button>,
            <Button key="ok" style={{width:'130px' ,height:'40px',backgroundColor:'#FF4545',color:'#FFFF'}} onClick={onOk}>
              ลบ
            </Button>,
          ]}
        />
      </AntdModal>
    );
  };

export  {Modal ,ModalResult,ModalDelete};




