import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import SaleInfoInfoTable from '../../components/Table/SaleInfoInfoTable';
import { useFetchPaymentSaleInfoAll } from "../../hooks/useFetchPayment";
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

const { RangePicker } = DatePicker;

function SaleInfo() {
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const { data: paymentAll, isLoading: isLoadingPaymentAll, refetch } = useFetchPaymentSaleInfoAll();
  
  const titlePage = 'ข้อมูลการขาย';
  const { currentColor } = useStateContext();
  const navigate =  useNavigate()


  const handleInfo = (recode)=>{
    navigate(`/payment-history/${recode.id}`)
  }

  const handleHistorySale = (recode)=>{
    navigate(`/payment-history-sale/${recode.id}`)
  }


  const handlePay = (recode)=>{
     navigate(`/payment-pay-detail/${recode.id}`)
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between items-center mb-5'>
        <Header category="App" title={`${titlePage}`} />
      </div>
      <div className="normal-view">
        <SaleInfoInfoTable data={paymentAll} onPay={handlePay} onInfo={handleInfo}  onHistorySale={handleHistorySale} loading={isLoadingPaymentAll} />
      </div>
    </div>
  );
}

export default SaleInfo;