import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import FormBankInfoAdmin from '../../../components/Form/FormBankInfoAdmin';

function Edit({ formRef,data}) {
  return (
    <div className='mt-5 pb-3'>
      <FormBankInfoAdmin
        formRef={formRef}
        initialValues={data}
        edit={true}
      />
    </div>
  );
}
export default Edit