import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import FormBankInfoAdmin from '../../../components/Form/FormBankInfoAdmin';

function Add({ formRef }) {

  const initialValues = {
  };

  return (
    <div className='mt-5 pb-3'>
      <FormBankInfoAdmin
        formRef={formRef}
        initialValues={initialValues}
      />
    </div>
  );
}
export default Add