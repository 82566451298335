import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { useNavigate,useLocation } from 'react-router-dom';
import takeketLogo from "../../assets/images/logo/takeket.png";
import { useMutationChangePassword } from '../../hooks/useAuthen';

function ChangePassword (){
  const location = useLocation();

  //http://localhost:3000/chang-password-success?token=
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // ดึงค่า token จาก URL
  const navigate = useNavigate(); // ใช้ us

  const {mutate:fetchChangePassword,isLoading:isLoadingChangePassword} = useMutationChangePassword();
  const onFinish = (values) => {
    console.log('Success:', values);
    const body = {
      password:values.confirmPassword,
      confirmPassword:values.password
    }
    fetchChangePassword( { body, token },{
      onSuccess: () => {
        navigate('/chang-password-success');
      },
      onError: (error) => {
        console.log(error)
        navigate('/chang-password-failed');
       
      },
    })
    // fetchChangePassword(body,token)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const validatePassword = (_, value) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);

    if (!value) {
      return Promise.reject('กรุณา กรอกข้อมูลรหัสผ่านใหม่');
    }
    if (value.length < minLength) {
      return Promise.reject('รหัสผ่านของคุณต้องมีความยาว 8 ตัวอักษรขึ้นไป');
    }
    if (!hasUppercase) {
      return Promise.reject('รหัสผ่านของคุณต้องมีตัวอักษรพิมพ์ใหญ่');
    }
    if (!hasLowercase) {
      return Promise.reject('รหัสผ่านของคุณต้องมีตัวอักษรพิมพ์เล็ก');
    }
    return Promise.resolve();
  };
  

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img
                src={takeketLogo}
                alt="logo"
                className="w-[230px]"
              />
        </a>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            เปลี่ยนรหัสผ่าน
          </h2>
          <Form
            name="changePassword"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            layout="vertical"
          >
            <Form.Item
              label="รหัสผ่านใหม่"
              name="password"
              rules={[
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>

            <Form.Item
              label="ยืนยันรหัสผ่าน"
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'กรุณา กรอกข้อมูลยืนยันรหัสผ่าน',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('ข้อมูลรหัสผ่านไม่ตรงกัน'));
                  },
                }),
              ]}
            >
              <Input placeholder="••••••••" />
             
            </Form.Item>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-3">
                รหัสผ่านของคุณต้องมีความยาว 8 ตัวอักษรขึ้นไป และต้องมีทั้งตัวอักษรพิมพ์เล็กและตัวพิมพ์ใหญ่
              </p>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="w-full" loading={isLoadingChangePassword}>
                เปลี่ยนรหัสผ่าน
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
