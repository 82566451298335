import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFetchVerify } from '../../hooks/useAuthen';
import { Spin } from 'antd';

function Verify() {
  const location = useLocation();

  // ฟังก์ชันสำหรับดึง query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // ดึงค่า token จาก URL
  const { data: verify, isLoading: isLoadingVerify } = useFetchVerify(token);
  const { email, agentId } = verify?.result || {};

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
      {isLoadingVerify ? (
        <Spin size="large" tip="กรุณารอซักครู่..." />
      ) : verify?.success ? (
        <>
          <h1 className="text-2xl font-bold text-primary">สมัครสมาชิกสำเร็จ</h1>
          <div className="mt-4 text-center">
            <p className="text-muted-foreground">
              คุณได้สมัครเป็นสมาชิกใหม่ในระบบสำเร็จแล้ว
              <br />
              กรุณาตรวจสอบข้อมูลด้านล่างนี้
            </p>
          </div>
          <div className="mt-6">
            <svg viewBox="0 0 24 24" className="text-green-500 w-16 h-16 mx-auto my-6">
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
          </div>
          <div className="mt-4 border border-border p-4 rounded-lg">
            <p className="text-muted-foreground">
              ID: <span className="font-semibold">{agentId}</span>
            </p>
            <p className="text-muted-foreground">
              Email: <span className="font-semibold">{email}</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-2xl font-bold text-red-500">การสมัครสมาชิกไม่สำเร็จ</h1>
          <div className="mt-4 text-center">
            <p className="text-muted-foreground">
              ขอโทษด้วย เกิดข้อผิดพลาดในการยืนยันการสมัครสมาชิก
              <br />
              โปรดลองอีกครั้ง หรือสอบถามเจ้าหน้าที่หากพบปัญหา
            </p>
          </div>
          <div className="mt-6">
            <svg viewBox="0 0 24 24" className="text-red-500 w-16 h-16 mx-auto my-6">
              <path
                fill="currentColor"
                d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 21.6c-5.291 0-9.6-4.309-9.6-9.6S6.709 2.4 12 2.4 21.6 6.709 21.6 12 17.291 21.6 12 21.6zm-.815-14.235c-.466 0-.84.374-.84.84v5.555c0 .465.374.84.84.84h1.63c.466 0 .84-.375.84-.84V8.205c0-.466-.374-.84-.84-.84h-1.63zm.815 10.605c-.774 0-1.4-.626-1.4-1.4s.626-1.4 1.4-1.4 1.4.626 1.4 1.4-.626 1.4-1.4 1.4z"
              ></path>
            </svg>
          </div>
          <div className="mt-4 border border-border p-4 rounded-lg">
            <p className="text-muted-foreground">
              หากคุณมีปัญหา โปรดติดต่อฝ่ายบริการลูกค้าเพื่อรับความช่วยเหลือ
            </p>
            <p className="text-muted-foreground">
              เบอร์:086-348-7888 
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default Verify;
