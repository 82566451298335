import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FormLocation from '../../components/Form/FormLocation';



function Edit({ formRef,data}) {

  return (
    <div className='mt-5 pb-3'>
      <FormLocation
        formRef={formRef}
        initialValues={data}
      />
    </div>
  );
}
export default Edit