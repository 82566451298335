import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import "./UploadImage.css";

const UploadImage = ({ title, onUpload, clear = false, file ,listType='picture-circle'}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    try {
      if (file) {
        const url = new URL(file);
        const fileName = url.pathname.split('/').pop();
        setFileList([
          {
            uid: "-1",
            name: fileName,
            status: "done",
            url: file,
            thumbUrl: file // เพิ่ม thumbUrl สำหรับ preview
          }
        ]);
      } else {
        setFileList([]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [file]);

  useEffect(() => {
    if (clear) {
      setFileList([]);
    }
  }, [clear]);

  const handleBeforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList([
        {
          ...file,
          status: 'done',
          url: reader.result,
          thumbUrl: reader.result
        }
      ]);
      onUpload([file]);
    };
    return false;
  };

  const handleChangeUploadImage = (info) => {
    const { status } = info.file;
    if (status === 'removed') {
      setFileList([]);
      onUpload([]);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadButton = (
    <div >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>รูปภาพ</div>
    </div>
  );

  return (
    <div  style={{height: "85px"}}  >
      {/* <p className='text-sm font-medium text-gray-700'><span  style={{ color: 'red' }}>* </span>{title}</p> */}
      <Upload
        name="logo"
        listType={listType}
        className="avatar-uploader my-upload"
        showUploadList={true}
        beforeUpload={handleBeforeUpload}
        onChange={handleChangeUploadImage}
        fileList={fileList}
        maxCount={1}
        accept="image/*"
     
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
    </div>
  );
};

export default UploadImage;