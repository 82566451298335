export const genNumberTable =  (data)=>{
    return data?.map((item, index) => {
          //add number to object 
          const {...rest } = item;
          return {
          ...rest,
          number: index + 1
          };
      })
  }
  