import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { GET,POST,PUT } from '../services/index';

export const useMutationRegister = () => {
  return useMutation(["register"],
    async (payload) => {
    //  const {search} = payload
     const path = `/api/v2/authen/register/back-office`
      const endPoin = path;
      const  {result}  = await POST(endPoin,payload);
      return result;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('token',data.token)
        window.location.reload();
      },
      onError: (error) => {
        message.error('สมัครสมาชิก ไม่สำเร็จ')
        console.log("error :>> ", error);
      },
    }
  );
};



export const useMutationLogin = () => {
    const navigate = useNavigate();
    return useMutation(["login"],
      async (payload) => {
       const path = `/api/v2/authen/login/back-office`
        const endPoin = path;
        const  {result}  = await POST(endPoin,payload);
        return result;
      },
      {
        onSuccess: (data) => {
          localStorage.setItem('token',data.token)
          // navigate('/');
          window.location.href = '/login';
        },
        onError: (error) => {
          message.error(error.response.data?.message)
          console.log("error :>> ", error.response.data?.message);
        },
      }
    );
};

export const useFetchGetProfile= () => {
  return useQuery(["get-profil"], async () => {
    const endPoin =  `/api/v2/authen/get-profile-back-office`;
    const { result } = await GET(endPoin);
    return result.profile;
  });
};


export const useMutationUpdateAccount = () => {
  return useMutation(["updateAccount"],
    async (payload) => {
      const {id,body} =  payload
      // const endPoin = `/api/v2/authen/update-account/${id}`;
      const endPoin = `/api/v2/authen/update-account`
      await PUT(endPoin,body);
    }
  );
};

export const useMutationForgetPassword = () => {
  return useMutation(["ForgetPassword"],
    async ({ body}) => {
      const endPoint = `/api/v2/authen/forget-password`;
      return await POST(endPoint, body);
    }
  );
};



export const useMutationChangePassword = () => {
  return useMutation(["ChangePassword"],
    async ({ body, token }) => {
      const endPoint = `/api/v2/authen/change-password`;
      return await PUT(endPoint, body, token);
    }
  );
};



export const useFetchVerify= (token) => {
  return useQuery(["getVerify",token], async () => {
    const endPoin =  `/api/v2/authen/app-offline/agent/verify-register?token=${token}`;
    const  result  = await GET(endPoin);
    // console.log(result)
    return result;
  });
};