import React, { useState } from 'react';
import { Form, Input, Select, InputNumber, DatePicker, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

function FormPromotion({ formRef, onFinish, initialValues = {} }) {
  console.log('initialValues', initialValues);
  const [discountType, setDiscountType] = useState(initialValues.discountType || 'percent');

  const convertRangePickerData = (rangPickerData) => {
    return rangPickerData?.map(date => dayjs(date));
  };

  const handleDiscountTypeChange = (value) => {
    setDiscountType(value);
  };

  return (
    <Form
      name="vehicleForm"
      ref={formRef}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        ...initialValues,
        rangPicker: convertRangePickerData(initialValues?.rangPicker),
      }}
    >
      <div>
        <Form.Item
          name="rangPicker"
          label="ระยะเวลา"
          rules={[{ required: true, message: 'กรุณาระบุวันระยะเวลา' }]}
        >
          <RangePicker className="w-full" placeholder={['เริ่ม', 'สิ้นสุด']} />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          name="name"
          label="ชื่อโปรโมชั่น"
          rules={[{ required: true, message: 'กรุณาระบุชื่อโปรโมชั่น' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="grid lg:gap-x-5 lg:grid-cols-4">
        <Form.Item
          name="code"
          label="โปรโมชั่นโค้ด"
          rules={[{ required: true, message: 'กรุณาระบุรหัสโปรโมชั่น' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="discount"
          label={`ส่วนลด (${discountType === 'percent' ? '%' : 'บาท'})`}
          rules={[{ required: true, message: 'กรุณาระบุจำนวนส่วนลด' }]}
        >
          <InputNumber className="w-full" min={1} defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="discountType"
          label="ประเภทส่วนลด"
          rules={[{ required: true, message: 'กรุณาเลือกประเภทส่วนลด' }]}
        >
          <Select defaultValue={discountType} onChange={handleDiscountTypeChange}>
            <Select.Option value="percent">เปอร์เซ็นต์</Select.Option>
            <Select.Option value="baht">บาท</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="quota"
          label="โควต้า"
          rules={[{ required: true, message: 'กรุณาระบุโควต้า' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <p className="ml-3 mb-3">ลูกค้าที่เข้าร่วม</p>
      <Form.List name="emailList">
        {(fields, { add, remove }) => (
          <>
            <div className="max-h-[150px] overflow-y-auto pr-3">
              {fields.map(({ key, name, ...restField }) => (
                <Form.Item
                  {...restField}
                  key={key}
                  className="m-0 p-0 w-full"
                >
                  <div className="flex items-center gap-3">
                    <Form.Item
                      className="flex-1"
                      name={[name, 'email']}
                      rules={[{ required: true, message: 'กรุณาระบุอีเมล' }]}
                    >
                      <Input
                        className="w-full"
                        placeholder="email"
                      />
                    </Form.Item>
                    <MinusCircleOutlined className="mt-[-20px]" onClick={() => remove(name)} />
                  </div>
                </Form.Item>
              ))}
            </div>
            <Form.Item>
              <Button className="w-[170px]" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                เพิ่ม
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
}

export default FormPromotion;


// import React from 'react';
// import { Form, Input, Select, Checkbox, InputNumber,DatePicker,TimePicker,Button} from 'antd';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import dayjs from 'dayjs';
// const { RangePicker } = DatePicker;
// function FormPromotion({ formRef, onFinish,initialValues={} }) {

//   const convertRangePickerData = (rangPickerData) => {
//     return rangPickerData?.map(date => dayjs(date));
//   };
//   return (
//     <Form name="vehicleForm" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={{
//       ...initialValues,
//       rangPicker: convertRangePickerData(initialValues?.rangPicker)

//     }
//       }>
//        <div>
//           <Form.Item name="rangPicker" label="ระยะเวลา" rules={[{ required: true, message: 'กรุณาระบุวันระยะเวลา' }]}>
//             <RangePicker className="w-full" placeholder={["เริ่ม", "สิ้นสุด"]} />
//           </Form.Item>
//       </div>
//       <div>
//       <Form.Item name="name" label="ชื่อโปรโมชั่น" rules={[{ required: true, message: 'กรุณาระบุชื่อโปรโมชั่น' }]}>
//           <Input />
//         </Form.Item>
//       </div>
//       <div className='grid lg:gap-x-5 lg:grid-cols-2 '>
//         <Form.Item name="code" label="โปรโมชั่นโค้ด" rules={[{ required: true, message: 'กรุณาระบุรหัสโปรโมชั่น' }]}>
//           <Input />
//         </Form.Item>
//         <Form.Item name="discount" label="ส่วนลด" rules={[{ required: true, message: 'กรุณาระบุจำนวนส่วนลด' }]}>
//           <InputNumber className='w-full' defaultValue={0} />
//         </Form.Item>
//       </div>
//       <p className='ml-3 mb-3'>ลูกค้าที่เข้าร่วม</p>
//       <Form.List name="emailList">
//           {(fields, { add, remove }) => (
//             <>
//               <div className="max-h-[150px]  overflow-y-auto pr-3" >
//                 {fields.map(({ key, name, ...restField }) => (
//                   <Form.Item
//                     {...restField}
//                     key={key}
//                     className= 'm-0 p-0 w-full'
//                   >
//                     <div className="flex items-center gap-3">
//                       <Form.Item className='flex-1'  name={[name, 'email']}    rules={[{ required: true, message: 'กรุณาระบุ เวลาเริ่มต้น' }]}  >
//                         <Input
//                           className="w-full"
//                           placeholder="email"
//                         />
//                       </Form.Item>
                     
//                     <MinusCircleOutlined className='mt-[-20px]' onClick={() => remove(name)} />
//                     </div>
//                   </Form.Item>
//                 ))}
//               </div>
//               <Form.Item>
//                 <Button className="w-[170px]" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
//                   เพิ่ม
//                 </Button>
//               </Form.Item>
//             </>
//           )}
//         </Form.List>
//     </Form>
//   );
// }

// export default FormPromotion;
