import React, { memo,useState } from 'react';
import { Table, Space, Tag,Popconfirm , Select, DatePicker } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import dayjs from 'dayjs';
import "../../App.css";
const { Option } = Select;
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const { RangePicker } = DatePicker;
const ScheduleTable = ({ data, loading,onEdit, onDelete ,onDateChange}) => {
  const { currentColor,setGetProfile} = useStateContext();
  const columns = [
    // {
    //   title: 'ลำดับ',
    //   align: 'center',
    //   width: 60,
    //   dataIndex: 'number',
    //   key: 'number',
    //   onHeaderCell: () => ({ style: headerStyle }),
    // },
    {
      title: 'หมายเลขรอบโดยสาร',
      align: 'center',
      dataIndex: 'scheduleID',
      key: 'scheduleID',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'บริษัท',
      align: 'center',
      dataIndex: 'company',
      key: 'company',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'ต้นทาง',
      align: 'center',
      dataIndex: 'originTh',
      key: 'originTh',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'ปลายทาง',
      align: 'center',
      dataIndex: 'destinationTh',
      key: 'destinationTh',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'ท่าเรือต้นทาง',
      align: 'center',
      dataIndex: 'stationOrigin',
      key: 'stationOrigin',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'ท่าเรือปลายทาง',
      align: 'center',
      dataIndex: 'stationDestination',
      key: 'stationDestination',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'รอบโดยสาร',
      align: 'center',
      dataIndex: 'startTime',
      key: 'startTime',
      onHeaderCell: () => ({ style: headerStyle }),
      render: (text) => dayjs(text, 'HH:mm:ss').format('HH:mm'),
    },
    {
      title: 'ชื่อยานพหนะ',
      align: 'center',
      dataIndex: 'vehicleNameTh',
      key: 'vehicleNameTh',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    // {
    //   title: 'คนขับ',
    //   align: 'center',
    //   dataIndex: 'driver',
    //   key: 'driver',
    //   onHeaderCell: () => ({ style: headerStyle }),
    // },
    // {
    //   title: 'ราคา (ปกติ)',
    //   align: 'center',
    //   dataIndex: 'price',
    //   key: 'price',
    //   onHeaderCell: () => ({ style: headerStyle }),
    // },
    // {
    //   title: 'ราคา(คนในพื้นที่)',
    //   align: 'center',
    //   dataIndex: 'localPrice',
    //   key: 'localPrice',
    //   onHeaderCell: () => ({ style: headerStyle }),
    // },
    // {
    //   title: 'วันเดินทาง',
    //   align: 'center',
    //   dataIndex: 'day',
    //   key: 'day',
    //   onHeaderCell: () => ({ style: headerStyle }),
    //   render: (day, record) => {
    //     if (record.scheduleType === 'specificDays') {
    //       return (
    //         <>
    //           {day?.map((day,index) => {
    //             let color = 'geekblue';
    //             if (day === 'ส.' || day === 'อา.') {
    //               color = 'volcano';
    //             }
    //             return (
    //               <Tag color={color} key={index}>
    //                 {day}
    //               </Tag>
    //             );
    //           })}
    //         </>
    //       );
    //     } else if (record.scheduleType === 'everyday') {
    //       return (
    //         <Tag color='green' key='everyday'>
    //           ทุกวัน
    //         </Tag>
    //       );
    //     }
    //     return null; // กรณีอื่นๆ ที่ไม่มีข้อมูลวันเดินทาง
    //   },
    // },
    {
        title: 'วันเดินทาง',
        dataIndex: 'startDate',
        align: 'center',
        key: 'startDate',
        onHeaderCell: () => ({ style: headerStyle }),
        render: (text) => dayjs(text).format('D/M/YYYY')
    },
    // {
    //     title: 'วันสิ้นสุด',
    //     align: 'center',
    //     dataIndex: 'endDate',
    //     key: 'endDate',
    //     onHeaderCell: () => ({ style: headerStyle }),
    //     render: (text) => dayjs(text).format('D/M/YYYY') 
    // },
    {
      title: 'จำนวนตั๋ว',
      align: 'center',
      dataIndex: 'quotaTickets',
      key: 'quotaTickets',
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>

          <Popconfirm
            title="ลบข้อมูลการเดินทาง"
            description="ยืนยันการลบข้อมูลการเดินทาง ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
              <div className="text-red-500 cursor-pointer" >
                ลบ
              </div>

           </Popconfirm>
      
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  // State for filtering
  const [filteredCompany, setFilteredCompany] = useState('ทั้งหมด');
  const [filteredProvince, setFilteredProvince] = useState('ทั้งหมด');

  // Get unique companies and provinces for filters
  const companies = ['ทั้งหมด', ...new Set(data?.map((item) => item.company))];
  const provinces = ['ทั้งหมด', ...new Set(data?.map((item) => item.province))];

  // Filtered data
  const filteredData = data?.filter((item) => {
    return (
      (filteredCompany === 'ทั้งหมด' || item.company === filteredCompany) &&
      (filteredProvince === 'ทั้งหมด' || item.province === filteredProvince)
    );
  });


  return (
    <div>
       <div className="mb-4 flex space-x-4 justify-end">
       <DatePickerProvider>
           <RangePicker 
             defaultValue={[dayjs(), dayjs().endOf('month')]}
             format="YYYY-MM-DD"
             onChange={onDateChange}
           />
         </DatePickerProvider>
        <Select
          placeholder="เลือกบริษัท"
          onChange={(value) => setFilteredCompany(value)}
          value={filteredCompany}
          style={{ width: 160 }}
        >
          {companies.map((company) => (
            <Option key={company} value={company}>
              {company}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="เลือกจังหวัด"
          onChange={(value) => setFilteredProvince(value)}
          value={filteredProvince}
          style={{ width: 160 }}
        >
          {provinces.map((province) => (
            <Option key={province} value={province}>
              {province}
            </Option>
          ))}
        </Select>
      </div>


    <div className='overflow-x-auto'>
    <Table
      className=' border-1 rounded-lg border-gray-200 w-full overflow-auto'
      dataSource={genNumberTable(filteredData)}
      columns={columns}
      pagination={{ pageSize: 10 ,showSizeChanger: false,}}
      rowKey="scheduleID"
      loading={loading}
      scroll={{ x: 'max-content' }} 
      rowClassName={record => !record.disable && "disabled-row"}
      
    />
    </div>
    </div>
  );
};

export default memo(ScheduleTable) ;
