import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FormVehicle from '../../components/Form/FormVehicle';
import { uploadImage } from '../../function/uploadImage';

function Add({ formRef }) {
  // const handleUploadImageBoat = async (values) => {
  //   const fileImage = values[0];
  //   const uploadData = await uploadImage(fileImage)
  //   console.log(uploadData)
  //   formRef.current.setFieldsValue({ imageVehicle: uploadData?.fullpath });
  // }

  const initialValues = {
    capacity: 0,
  };

  return (
    <div className='mt-5 pb-3'>
      <FormVehicle
        formRef={formRef}
        // uploadImageBoat={handleUploadImageBoat}
        initialValues={initialValues}
      />
    </div>
  );
}
export default Add