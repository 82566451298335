import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FormLocation from '../../components/Form/FormLocation';
import { uploadImage } from '../../function/uploadImage';

function Add({ formRef }) {


  const initialValues = {
  };

  return (
    <div className='mt-5 pb-3'>
      <FormLocation
        formRef={formRef}
        initialValues={initialValues}
      />
    </div>
  );
}
export default Add