import React from 'react';
import { Table, Space, Tag,Popconfirm } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const BoatTable = ({ data, onEdit, onDelete,loading }) => {
  const { currentColor} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'รูปภาพ', 
      align: 'center',
      width: 200, 
      dataIndex: 'imageVehicle', 
      key: 'imageVehicle', 
      render: (text) => <img className='object-cover rounded-lg shadow-lg' src={text} alt="Boat" style={{ width: '130px',height:'80px' }} /> ,onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'บริษัท', 
      align: 'center', 
      dataIndex: 'company', 
      key: 'company',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'ชื่อยานพาหนะ', 
      align: 'center', 
      dataIndex: 'nameTh', 
      key: 'nameTh',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'ประเภท', 
      align: 'center', 
      dataIndex: 'subType', 
      key: 'subType' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'จำนวนที่นั่ง', 
      align: 'center', 
      dataIndex: 'capacity', 
      key: 'capacity',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>

          <Popconfirm
            title="ลบข้อมูลยานพาหนะ"
            description="ยืนยันการลบข้อมูลยานพาหนะ ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
              <div className="text-red-500 cursor-pointer" >
                ลบ
              </div>

           </Popconfirm>
      
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return( 
  <div className='overflow-x-auto'>
  <Table 
    className=' border-1 rounded-lg border-gray-200'   
    loading={loading}    
    dataSource={genNumberTable(data)} 
    columns={columns}  
    pagination={{ pageSize: 10}} 
    rowKey={'BoatID'} 
    scroll={{ x: 'max-content' }}  
    />
    </div>
    )
};

export default BoatTable;
