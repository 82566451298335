import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST } from '../services/index';
import {boatData} from '../data/mock/boat'
import {scheduleData} from '../data/mock/schedule'

export const useFetchBoatAll = () => {
    let path=''
    path = `/api/v1/boat/get-boat`
    return useQuery(["getBoat"], async () => {
      const endPoin = path;
    //   const { result } = await GET(endPoin);
      const {result} = boatData
      return result;
    }
  );
};



// export const useFetchBooking = ({bookingID}) => {
//     let path=''
//     path = `/api/v1/booking/get-booking?search=${bookingID}`
//     return useQuery(["getBooking"], async () => {
//       const endPoin = path;
//       const { result } = await GET(endPoin);
//       return result;
//     }
//   );
// };

// export const useMutationBooking = () => {
//   return useMutation(["booking"],
//     async (payload) => {
//      const {search} = payload
//      const path = `/api/v1/booking/get-booking?search=${search}`
//       const endPoin = path;
//       const { result } = await GET(endPoin);
//       return result.booking;
//     },
//     {
//       onSuccess: (responseData) => {
//         console.log("Booking successful :", responseData);
//       },
//       onError: (error) => {
//         console.log("error :>> ", error);
//       },
//     }
//   );
// };
