import React from 'react';
import { Form, Input, Select, Checkbox, InputNumber } from 'antd';
import {bank} from "../../data/bank";
import { useFetchCustomerAll } from '../../hooks/useFetchCustomer';

function FormCustomer({ formRef, onFinish,initialValues={},edit=false }) {
  const { data: customerAll, isLoading:isLoadingCustomerAll,refetch:refetchCustomerAll} = useFetchCustomerAll();
  const customerOptions = customerAll?.map((val)=>{
    return { label:`บริษัท ${val.company} | ${val.customerId}`,value:val.companyId}
  })

  const handleChangSelectCustomer = (value)=>{
    console.log('value',value)
  }

  return (
    <Form name="picture" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
       {!edit ? (
          <Form.Item name="companyId" label="บริษัท" rules={[{ required: true, message: 'กรุณาเลือกบริษัท' }]}>
          <Select
            loading={isLoadingCustomerAll}
            placeholder="กรุณาเลือกบริษัท"
            options={customerOptions}
            onChange={handleChangSelectCustomer}
          >
          </Select>
          </Form.Item>
       ):null}
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="name" label="ธนาคาร" rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}>
          <Select
            placeholder="เลือกธนาคาร"
            className="w-full h-10 rounded-lg" 
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {bank.map(bank => (
              <Select.Option key={bank.code} value={bank.thai_name}>
                {bank.thai_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="accountNumber" label="เลขที่บัญชี " rules={[{ required: true, message: 'กรุณาระบุเลขบัญชี' }]}>
        <Input
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="012356890125"  
        />
        </Form.Item>
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="accountHolderName" label="ชื่อเจ้าของบัญชี " rules={[{ required: true, message: 'กรุณาระบุเลขบัญชี' }]}>
        <Input
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="กรอกชื่อ"  
        />
        </Form.Item>
        <Form.Item name="commission" label="ค่าคอมมิชชั่น" rules={[{ required: true, message: 'กรุณาระบุ ค่าคอมมิชชั่น' }]}>
          <InputNumber className='w-28' defaultValue={0} addonAfter={<div>%</div>} />
        </Form.Item>

    </Form>
  );
}

export default FormCustomer;
