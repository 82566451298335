

import React, { useState, useRef } from 'react';
import { Header } from '../../components';
import { Button, message, Tabs } from 'antd';
import AgentTable from '../../components/Table/AgentTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { useFetchAgentAll,useFetchAgentRequests ,useFetchMyAgents,useMutationActiveAgent,useMutationCustomerApproved, useMutationCustomerRequestsAgent,useMutationcancelCustomerRequestsAgent} from "../../hooks/useFetchAgent";

const Agent = () => {
  const titlePage = 'รายชื่อตัวแทนขายทั้งหมด';
  const { data: agentAll, isLoading: isLoadingAgentAll, refetch: refetchAgentAll } = useFetchAgentAll();

  const { isLoading: isLoadingCustomerRequestsAgent, mutate: postCustomerRequestsAgent } = useMutationCustomerRequestsAgent();
  const { isLoading: isLoadingcancelCustomerRequestsAgent, mutate: postcancelCustomerRequestsAgent } = useMutationcancelCustomerRequestsAgent();

  const handleRequest = async (record) => {
    const {agentId,invite} = record
    const payload = {"agentId":agentId}
    if (!invite){
      await postCustomerRequestsAgent(payload,{
        onSuccess: () => {
          message.success('เชิญตัวแทนสำเร็จ')
          refetchAgentAll()
        },
        onError: (error) => {
          message.error(error.message)
        },
      })
    }else{
      await postcancelCustomerRequestsAgent(payload,{
        onSuccess: () => {
          message.success('ยกเลิกตัวแทนสำเร็จ')
          refetchAgentAll()
        },
        onError: (error) => {
          message.error(error.message)
        },
      })
    }
  };




  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className="flex justify-between">
        <Header category="App" title={`${titlePage}`} />
      </div>
      <AgentTable data={agentAll} onClickBtn={handleRequest} loading={isLoadingAgentAll} isInvite={false} />
    </div>
  );
};

export default Agent;


// import React, { useState, useRef } from 'react';
// import { Header } from '../../components';
// import { Button, message, Tabs } from 'antd';
// import AgentTable from '../../components/Table/AgentTable';
// import { useStateContext } from '../../contexts/ContextProvider';
// import { useFetchAgentAll,useFetchAgentRequests ,useFetchMyAgents,useMutationActiveAgent,useMutationCustomerApproved, useMutationCustomerRequestsAgent} from "../../hooks/useFetchAgent";

// const Agent = () => {
//   const titlePage = 'ตัวแทนขาย';
//   const { data: agentAll, isLoading: isLoadingAgentAll, refetch: refetchAgentAll } = useFetchAgentAll();
//   const { data: agentRequests, isLoading: isLoadingAgentRequests, refetch: refetchAgentRequests } = useFetchAgentRequests();
//   const { data: myAgents, isLoading: isLoadingMyAgents, refetch: refetchMyAgents } = useFetchMyAgents();

//   const { isLoading: isLoadingCustomerApproved, mutate: postCustomerApproved } = useMutationCustomerApproved();
//   const { isLoading: isLoadingActiveAgent, mutate: postActiveAgent } = useMutationActiveAgent();
//   const { isLoading: isLoadingCustomerRequestsAgent, mutate: postCustomerRequestsAgent } = useMutationCustomerRequestsAgent();

//   const handleApproved =async (record) => {
//     const {agentId} = record
//     const payload = {"agentId":agentId}
//     await postCustomerApproved(payload,{
//       onSuccess: () => {
//         message.success('อนุมัติสำเร็จ')
//         refetchAgentRequests()
//       },
//       onError: (error) => {
//         message.error(error.message)
//       },
//     })
//   };

//   const handleRequest = async (record) => {
//     const {agentId} = record
//     const payload = {"agentId":agentId}
//     await postCustomerRequestsAgent(payload,{
//       onSuccess: () => {
//         message.success('เชิญตัวแทนสำเร็จ')
//         refetchAgentAll()
//       },
//       onError: (error) => {
//         message.error(error.message)
//       },
//     })
//   };

//   const handleSwitchChange = async (record) => {
//     const {agentId,active} = record
//     const payload = {"agentId":agentId,"active":!active}
//     await postActiveAgent(payload,{
//       onSuccess: () => {
//         message.success('อัพเดทสำเร็จ')
//         refetchMyAgents()
//       },
//       onError: (error) => {
//         message.error(error.message)
//       },
//     })
//   };

//   const items = [
//     {
//       key: '1',
//       label: 'ตัวแทนขายของฉัน',
//       children: (
//         <AgentTable data={myAgents} loading={isLoadingMyAgents} isSwitch={true} onSwitchChange={handleSwitchChange} />
//       ),
//     },
//     {
//       key: '2',
//       label: 'รายชื่อตัวแทนทั้งหมด',
//       children: (
//         <AgentTable data={agentAll} onClickBtn={handleRequest} loading={isLoadingAgentAll} isInvite={false} />
//       ),
//     },
//     {
//       key: '3',
//       label: 'รายการขอ',
//       children:(
//         <AgentTable data={agentRequests} onClickBtn={handleApproved} loading={isLoadingAgentRequests} isInvite={true} />
//       )
//     },
//   ];

//     // Function to handle tab change and refetch data
//     const handleTabChange = (key) => {
//       switch (key) {
//         case '1':
//           refetchMyAgents();
//           break;
//         case '2':
//           refetchAgentAll();
//           break;
//         case '3':
//           refetchAgentRequests();
//           break;
//         default:
//           break;
//       }
//     };

//   return (
//     <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
//       <div className="flex justify-between">
//         <Header category="App" title={`${titlePage}`} />
//       </div>
//       <Tabs type="card" defaultActiveKey="1" items={items}  onChange={handleTabChange}/>
//     </div>
//   );
// };

// export default Agent;
