import React,{useRef,useState,useEffect} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from '../../components';
import { Button,message } from 'antd';
import LocationTable from '../../components/Table/LocationTable';
import { Modal } from "../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import { useStateContext } from '../../contexts/ContextProvider';
import { useFetchLocationAll,useMutationAddLocation,useMutationLocationByID,useMutationUpdateLocation,useMutationDeleteLocation,useMutationCheckStationInschedule} from "../../hooks/useFetchLocation";
import Add from './Add';
import Edit from './Edit';

function Location() {
  const { currentColor,btnColor} = useStateContext();
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const [dataEdit, setDataEdit] = useState(null);
  const { data: locationAll, isLoading:isLoadingLocationAll,refetch:refetchLocationAll} = useFetchLocationAll();
  const {mutate:fetchLocationByID} = useMutationLocationByID();
  const {mutate:fetchCheckStationInschedule} = useMutationCheckStationInschedule();
  const { isLoading:isLoadingAddLocation,mutate:fetchAddLocation} = useMutationAddLocation();
  const { isLoading:isLoadingUpdateLocation,mutate:fetchUpdateLocation} = useMutationUpdateLocation();
  const { isLoading:isLoadingDeleteLocation,mutate:fetchDeleteLocation} = useMutationDeleteLocation();
 
  const titlePage = "สถานที่"
  console.log('isLoadingUpdateLocation',isLoadingUpdateLocation)

   
  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      try {
        const values = await formAddRef.current.validateFields();
        console.log(values)

        let body = values
        await fetchAddLocation(body, {
          onSuccess:async () => {
            message.success('เพิ่มข้อมูลสำเร็จ')
            await refetchLocationAll()
            await formAddRef.current.submit();
            await hideAddModal();
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='สร้าง' cancelText='ยกเลิก' width={900}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading={isLoadingAddLocation}>
        <Add formRef={formAddRef} /> 
      </Modal>
    )
  },[isLoadingAddLocation]);


  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {

      try {
        const values = await formEditRef.current.validateFields();
        message.loading('กรุณารอซักครู่...', 0);
        const body = values
        const id = dataEdit.locationId
        const payload = {id, body}
        fetchUpdateLocation(payload,{
          onSuccess: async () => {
            message.destroy(); 
            message.success('แก้ไขข้อมูลสำเร็จ')
            await refetchLocationAll()
            await formEditRef.current.submit();
            await hideEditModal()
          },
          onError: (error) => {
            message.destroy(); 
            message.error(error?.response.data?.message)
          },
        })
    
      } catch (error) {
        console.error('Form validation error:', error);
      }
   
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={900}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading={isLoadingUpdateLocation}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
      
  )},[dataEdit,isLoadingUpdateLocation]);
  //#endregion


  const handleEdit = (record) => {
    fetchLocationByID(record.locationId,{
      onSuccess: (data) => {
        setDataEdit(data)
        showEditModal()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  const handleDelete = (record) => {
    fetchDeleteLocation(record.locationId,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchLocationAll()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  return (
   <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
          <Button  onClick={showAddModal} style={{ backgroundColor: btnColor ,color:'#FFFF'}}>เพิ่ม</Button>
      </div>
        <LocationTable data={locationAll} onEdit={handleEdit} onDelete={handleDelete} loading={isLoadingLocationAll} />
    </div>
  );
}

export default Location;