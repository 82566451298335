import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';
import {boatData} from '../data/mock/boat'
import {scheduleData} from '../data/mock/schedule'

export const useFetchAgentAll = () => {
    return useQuery(["getAgentAlll"], async () => {
      const endPoin = `/api/v2/agent/get-agent-all`;
      const { result } = await GET(endPoin);
      return result.agent;
    },{
      cacheTime:0
    });
};


export const useFetchAgentRequests = () => {
  return useQuery(["get-agent-requests"], async () => {
    const endPoin = `/api/v2/agent/get-agent-requests`;
    const { result } = await GET(endPoin);
    return result.agent;
  },{
    cacheTime:0
  });
};

export const useFetchMyAgents = () => {
  return useQuery(["get-my-agent"], async () => {
    const endPoin = `/api/v2/agent/get-my-agents`;
    const { result } = await GET(endPoin);
    return result.agent;
  },{
    cacheTime:0
  });
};

export const useMutationCustomerApproved = () => {
  return useMutation(["customerApproved "],
    async (payload) => {
      const endPoin = `/api/v2/agent/customer-approved`;
      await POST(endPoin,payload);
    },{
      cacheTime:0
    });
};

export const useMutationActiveAgent = () => {
  return useMutation(["customerActiveAgent"],
    async (payload) => {
      const endPoin = `/api/v2/agent/customer-active-agent`;
      await PUT(endPoin,payload);
    },
    {
      cacheTime:0
    });
};


export const useMutationCustomerRequestsAgent = () => {
  return useMutation(["customerRequestsAgent"],
    async (payload) => {
      const endPoin = `/api/v2/agent/customer-requests-agent`;
      await POST(endPoin,payload);
    },
    {
      cacheTime:0
    });
};

export const useMutationcancelCustomerRequestsAgent = () => {
  return useMutation(["customerRequestsAgent"],
    async (payload) => {
      const endPoin = `/api/v2/agent/cancel-customer-requests-agent`;
      await POST(endPoin,payload);
    },
    {
      cacheTime:0
    });
};