import React from 'react';
import FormPromotion from '../../components/Form/FormPromotion';


function Edit({ formRef,data}) {

  
  return (
    <div className='mt-5 pb-3'>
      <FormPromotion
        formRef={formRef}
        initialValues={data}
      />
    </div>
  );
}
export default Edit