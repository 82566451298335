import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FormPromotion from '../../components/Form/FormPromotion';


function Add({ formRef }) {
 
  const initialValues = {
    capacity: 0,
  };
  return (
    <div className='mt-5 pb-3'>
      <FormPromotion
        formRef={formRef}
        initialValues={initialValues}
      />
    </div>
  );
}
export default Add