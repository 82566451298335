import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link,useParams } from "react-router-dom";
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import PaymentSaleHistoryTable from '../../components/Table/PaymentSaleHistoryTable';
import {useFetchPaymentSaleHistoryByEmployee} from "../../hooks/useFetchPayment";

import { DatePicker, Button ,Breadcrumb,Typography,message,Modal} from 'antd';
import dayjs from 'dayjs';
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useModal } from "react-modal-hook";
const { RangePicker } = DatePicker;

function SaleHistory() {
  const {id} = useParams();
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const { data: payment, isLoading: isLoadingPayment, refetch:refetchPayment } = useFetchPaymentSaleHistoryByEmployee(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'),id);

  const titlePage = 'ประวัติการขาย';
  const { Text } = Typography;
  const { currentColor,btnColor} = useStateContext();
  const navigate =  useNavigate()
  const componentRef = useRef();

  const breadcrumbItems = [
    { title: <Link to="/payment-sale-info">{'ข้อมูลการขาย'}</Link> },
    { title: <Text strong>{titlePage}</Text> },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `
  });


  const onDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  useEffect(() => {
    refetchPayment(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
  }, [dateRange]);



  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between items-center mb-5'>
        <Header category="App" title={`${titlePage}`} />
        <div className='flex items-center space-x-4'>
          <DatePickerProvider>
            <RangePicker 
              defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
              format="YYYY-MM-DD"
              onChange={onDateChange}
            />
          </DatePickerProvider>
          <Button 
            type="primary" 
            icon={<PrinterOutlined />} 
            onClick={handlePrint}
            style={{ backgroundColor: btnColor, borderColor: btnColor }}
          >
            Print
          </Button>

        </div>
      </div>
      <Breadcrumb style={{ margin: "1rem 0" }} items={breadcrumbItems} />
      <div className="normal-view">
        <PaymentSaleHistoryTable data={payment} loading={isLoadingPayment} />
      </div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>{titlePage}</h1>
          <PaymentSaleHistoryTable data={payment} loading={false} forPrint={true} />
        </div>
      </div>
    </div>
  );
}

export default SaleHistory;