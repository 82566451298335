import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { Button } from '.';
import { userProfileData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';

const UserProfile = () => {
  const { currentColor ,getProfile,handleClose} = useStateContext();
  const navigate = useNavigate();
  
  const handleLogout=()=>{
    localStorage.clear('token')
    navigate('/login');
    // window.location.href = '/login';
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 shadow-lg">
      <div className="flex justify-between items-center">
        <p className="font-normal text-lg dark:text-gray-200">โปรไฟล์ผู้ใช้</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          onClick={handleClose} 
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        {/* <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        /> */}
          <Avatar  size={90} icon={<UserOutlined />} src={getProfile?.picture} />
        <div>
          <p className="font-light  text-xl dark:text-gray-200">{`${getProfile?.name}`}</p>
          <p className="text-gray-500 text-sm font-light  dark:text-gray-400">รหัสประจำตัว:  {getProfile?.customerId || getProfile?.employeeId }</p> 
          <p className="text-gray-500 text-sm dark:text-gray-400">ตำแหน่ง: {getProfile?.position}</p>
          <p className="text-gray-500 text-sm font-light  dark:text-gray-400">อีเมล์: {getProfile?.email }</p>
          <p className="text-gray-500 text-sm font-light  dark:text-gray-400">บริษัท:  {getProfile?.company }</p> 
          <p className="text-gray-500 text-sm font-light  dark:text-gray-400">จังหวัด:  {getProfile?.province }</p> 

        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
           <div
           key={index}
           className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer dark:hover:bg-[#42464D]"
           onClick={() => {
            navigate(item.route);
            handleClose();
           }} // นำทางไปยังหน้าที่กำหนด
         >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-light  dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400"> {item.desc} </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <Button
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
          onClick={handleLogout}
        />
      </div>
    </div>

  );
};

export default UserProfile;
