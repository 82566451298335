import React from 'react';
import { Table, Space,Avatar,Popconfirm ,Tag} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const LocationTable = ({ data, onEdit, onDelete,loading }) => {
  const { currentColor,setGetProfile} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'จังหวัด', 
      align: 'center', 
      dataIndex: 'province', 
      key: 'province',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
   
    { 
      title: 'สถานที่', 
      align: 'center', 
      dataIndex: 'name', 
      key: 'name',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'ท่าเรือ',
      align: 'center',
      dataIndex: 'station',
      key: 'station',
      onHeaderCell: () => ({ style: headerStyle }),
      render: (stations, record) => (
        <div className='flex flex-wrap'>
          {stations?.map((station, index) => (
            <Tag color="green" key={index}>
              {station.name}
            </Tag>
          ))}
        </div>
      )
    },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>

          <Popconfirm
            title="ลบข้อมูลสถานที่"
            description="ยืนยันการลบข้อมูลสถานที่ ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
              <div className="text-red-500 cursor-pointer" >
                ลบ
              </div>

           </Popconfirm>
      
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return ( 
    <div className='overflow-x-auto'>
    <Table 
      className=' border-1 rounded-lg border-gray-200' 
      dataSource={genNumberTable(data)}
      columns={columns}  
      pagination={{ pageSize: 8}} 
      rowKey={'locationId'}
      loading={loading}
      scroll={{ x: 'max-content' }}  
      
      />
      </div>
    );
};

export default LocationTable;
