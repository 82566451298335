
import React, { useState, useRef } from 'react';
import { Header } from '../../components';
import { Button, message, Tabs } from 'antd';
import AgentTable from '../../components/Table/AgentTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { useFetchAgentAll,useFetchAgentRequests ,useFetchMyAgents,useMutationActiveAgent,useMutationCustomerApproved, useMutationCustomerRequestsAgent} from "../../hooks/useFetchAgent";

const Agent = () => {
  const titlePage = 'ตัวแทนขายของฉัน';

  const { data: myAgents, isLoading: isLoadingMyAgents, refetch: refetchMyAgents } = useFetchMyAgents();

  const { isLoading: isLoadingActiveAgent, mutate: postActiveAgent } = useMutationActiveAgent();



  const handleSwitchChange = async (record) => {
    const {agentId,active} = record
    const payload = {"agentId":agentId,"active":!active}
    await postActiveAgent(payload,{
      onSuccess: () => {
        message.success('อัพเดทสำเร็จ')
        refetchMyAgents()
      },
      onError: (error) => {
        console.log(error)
        message.error(error?.response?.data?.message||error.message )
      },
    })
  };


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className="flex justify-between">
        <Header category="App" title={`${titlePage}`} />
      </div>
      <AgentTable data={myAgents} loading={isLoadingMyAgents} isSwitch={true} onSwitchChange={handleSwitchChange} />
    </div>
  );
};

export default Agent;
