import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FormEmployee from '../../components/Form/FormEmployee';
import { uploadImage } from '../../function/uploadImage';

function Add({ formRef }) {
  const handleUploadImage = async (values) => {
    const fileImage = values[0];
    const uploadData = await uploadImage(fileImage)
    console.log(uploadData)
    formRef.current.setFieldsValue({ picture: uploadData?.fullpath });
  }

  const initialValues = {
    dashboard: false,    // Already checked
    payments: false, // Not checked
    employees: false,    // Already checked
    vehicles: false, // Not checked
    schedule: false,  // Already checked
    ticket: false,  // Not checked
    business: false, // Already checked
  };

  return (
    <div className='mt-5 pb-3'>
      <FormEmployee
        formRef={formRef}
        uploadImage={handleUploadImage}
        initialValues={initialValues}
      />
    </div>
  );
}
export default Add