import React,{useState,useRef} from 'react';
import { Header } from '../../../components';
import { Button,message } from 'antd';
import BankTable from '../../../components/Table/BankTable';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Modal } from "../../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import { useFetchCustomerAll,useMutationAddCustomer,useMutationDeleteCustomer,useMutationUpdateCustomer} from "../../../hooks/useFetchCustomer";
import { useFetchBankAll,useMutationAddBank,useMutationDeleteBank,useMutationUpdateBank} from "../../../hooks/useFetchPayment";
import { useFetchVehicleAll,useMutationVehicleByID,useMutationVehicle,useMutationDeleteVehicle,useMutationUpdateVehicle} from "../../../hooks/useFetchVehicle";
import Add from './Add';
import Edit from './Edit';
// import { transformData} from './response';
// import { validateFieldsImage} from '../../function/validateFieldsImage';

const BankInfoAdmin = ({titlePage}) => {
  // const titlePage = 'ข้อมูลธนาคาร'
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const { currentColor} = useStateContext();
  const [dataEdit, setDataEdit] = useState(null);
  const { data: bankAll, isLoading:isLoadingBankAll,refetch:refetchBankAll} = useFetchBankAll();
  const { isLoading:isLoadingfetchAddBank,mutate:fetchAddBank} = useMutationAddBank();
  const { isLoading:isLoadingfetchDeleteBank,mutate:fetchDeleteBank} = useMutationDeleteBank();
  const { isLoading:isLoadingfetchUpdateBank,mutate:fetchUpdateBank} = useMutationUpdateBank();

  const { data: vehicleAll, isLoading:isLoadingVehicleAll,refetch:refetchVehicleAll} = useFetchVehicleAll();
  const { isLoading:isLoadingAddCustomer,mutate:mutateAddCustomer} = useMutationAddCustomer();
  const { isLoading:isLoadingUpdateCustomer,mutate:mutateUpdateCustomere} = useMutationUpdateCustomer();
  const { isLoading:isLoadingDeleteCustomer, mutate:mutateDeleteCustomer} = useMutationDeleteCustomer();


  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      try {
        const value =  await formAddRef.current.validateFields();
        console.log(value)
        const body = value
        await fetchAddBank(body, {
          onSuccess:async () => {
            refetchBankAll()
            formAddRef.current.submit();
            hideAddModal();
            message.success('เพิ่มข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
  
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='สร้าง' cancelText='ยกเลิก' width={500}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading={isLoadingfetchAddBank}>
        <Add formRef={formAddRef} />
      </Modal>
    )
  });

  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {
      try {
        const value =  await formEditRef.current.validateFields();
        console.log(value)
        const id = dataEdit.id
        let body = value;
        body.companyId = dataEdit.companyId
        const payload = {id, body}
        await fetchUpdateBank(payload, {
          onSuccess:() => {
            refetchBankAll()
            formEditRef.current.submit();
            hideEditModal()
            message.success('แก้ไขข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
    
      } catch (error) {
        console.error('Form validation error:', error);
      }
    
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={500}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading={isLoadingUpdateCustomer}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
  )},[dataEdit]);
  //#endregion

  const handleEdit = (record) => {
    setDataEdit(record);
    showEditModal()
  };

  const handleDelete = (record) => {
    console.log('Delete action for:', record);

    fetchDeleteBank(record.id,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchBankAll()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
        {/* <Button onClick={showAddModal} color="white" bgColor={currentColor} text="เพิ่ม" size="[8px]" borderRadius="8px" high="[30px]" width="[90px]" /> */}
          <Button onClick={showAddModal} style={{ backgroundColor: currentColor ,color:'#FFFF'}}>เพิ่ม</Button>
      </div>
      <BankTable data={bankAll} onEdit={handleEdit} onDelete={handleDelete} loading = {isLoadingBankAll} />
    </div>
  );
};

export default BankInfoAdmin;
