import React,{useRef,useState,useEffect} from 'react';
import { Header } from '../../components';
import { Button,message,Spin,DatePicker } from 'antd';
import ScheduleTable from '../../components/Table/scheduleTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal } from "../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import DatePickerProvider from "../../components/DatePickerProvider/DatePickerProvider";
import { useFetchScheduleAll,useMutationScheduleByID,useMutationDeleteSchedule,useMutationSchedule,useMutationUpdateSchedule} from "../../hooks/useFetchSchedule";
import { transformData} from './response';
import Add from './Add';
import Edit from './Edit';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

function Schedule() {
  const titlePage = 'รอบโดยสาร'
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const { currentColor,btnColor} = useStateContext();
  const [dataEdit, setDataEdit] = useState(null);
  // const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]); //ทั้งเดือน
  // const [dateRange, setDateRange] = useState([dayjs(), dayjs()]); //;เฉพาะวันนี้

  const [dateRange, setDateRange] = useState([dayjs(), dayjs().endOf('month')]); //;วันนี้ถึงสิ้นเดือน

  const { data: scheduleAll, isLoading:isLoadingSchedule,refetch:refetchSchedule} = useFetchScheduleAll(
    dateRange[0].format('YYYY-MM-DD'), 
    dateRange[1].format('YYYY-MM-DD')
  );
  const { isLoading:isLoadingScheduleByID,mutate:mutateScheduleByID} = useMutationScheduleByID();
  const { isLoading:isLoadingUpdateSchedule,mutate:mutateUpdateSchedule} = useMutationUpdateSchedule();
  const { isLoading:isLoadingDeleteSchedule,mutate:mutateDeleteSchedule} = useMutationDeleteSchedule();
  const { mutate:fetchAddSchedule , isLoading:isLoadingAddSchedule} = useMutationSchedule();

  const onDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  useEffect(() => {
    refetchSchedule(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'));
  }, [dateRange]);


 
  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      try {
        const values = await formAddRef.current.validateFields();
        if (values.destinationLocation ===values.originLocation ){
          message.error('กรุณาเลือกสถานีต้นทางและปลายทางที่แตกต่างกัน')
          return
        }
        let body = await transformData(values);
        body.sort = scheduleAll.length + 1
        console.log(body)
        message.loading('กรุณารอซักครู่...', 0);
        await fetchAddSchedule(body, {
          onSuccess:async () => {
            message.destroy(); 
            await refetchSchedule()
            await formAddRef.current.submit();
            await hideAddModal();
            message.success('เพิ่มข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.destroy(); 
            message.error(error.message)
          },
        })
 
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='สร้าง' cancelText='ยกเลิก' width={500}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading = {isLoadingAddSchedule}>
        <Add formRef={formAddRef}  refetchSchedule={refetchSchedule}/>
      </Modal>
    )
  },[scheduleAll,isLoadingAddSchedule]);


  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {

      try {
        const values = await formEditRef.current.validateFields();
        if (values.destinationLocation ===values.originLocation ){
          message.error('กรุณาเลือกสถานีต้นทางและปลายทางที่แตกต่างกัน')
          return
        }
        const id = dataEdit.scheduleID
        let body = await transformData(values);
        body.sort = dataEdit.sort
        body.companyId=dataEdit.companyId
        const payload = {id, body}
        message.loading('กรุณารอซักครู่...', 0);
        mutateUpdateSchedule(payload,{
          onSuccess: async () => {
            message.destroy(); 
          
            await refetchSchedule()
            await formEditRef.current.submit();
            await hideEditModal()
            message.success('แก้ไขข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.destroy(); 
            message.error(error.message)
          },
        })
    
      } catch (error) {
        console.error('Form validation error:', error);
      }
   
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={500}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading = {isLoadingUpdateSchedule}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
      
  )},[dataEdit,isLoadingUpdateSchedule]);
  //#endregion

  const handleEdit = (record) => {
    mutateScheduleByID(record.scheduleID,{
      onSuccess: (data) => {
        setDataEdit(data)
        showEditModal()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  const handleDelete = (record) => {
    mutateDeleteSchedule(record.scheduleID,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchSchedule()
      },
      onError: (error) => {
        message.error(error.response.data.message)
      },
    })
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between items-center mb-5'>
        <Header category="App" title={`${titlePage}`}  />
        <div className='flex items-center space-x-4'>
        {/* <DatePickerProvider>
           <RangePicker 
             defaultValue={[dayjs(), dayjs().endOf('month')]}
             format="YYYY-MM-DD"
             onChange={onDateChange}
           />
         </DatePickerProvider> */}
          <Button onClick={showAddModal} loading={isLoadingAddSchedule} style={{ backgroundColor: btnColor ,color:'#FFFF'}}>เพิ่ม</Button>
        </div>
      </div>
      <ScheduleTable data={scheduleAll} onEdit={handleEdit} onDelete={handleDelete} loading={isLoadingSchedule} onDateChange={onDateChange} />
    </div>
  );
};

export default Schedule;