import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Header } from '../../components';
import { Upload, Input, Switch, Button ,Form,message} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useStateContext } from '../../contexts/ContextProvider';
import UploadImageBtn from "../../components/Upload/UploadImage/UploadImageBtn";
import { uploadImage } from '../../function/uploadImage';
import { useMutationUpdateAccount,useFetchGetProfile} from "../../hooks/useAuthen";

function AccountSettings() {
  const titlePage = 'การตั้งค่าบัญชี'
  const { currentColor} = useStateContext();
  const [picture, setPicture] = useState(null);
  const [form] = Form.useForm(); // Ant Design's form instance

  const { isLoading:isLoadingUpdateAccount,mutate:mutateUpdateAccount} = useMutationUpdateAccount();
  const { data:getProfile,refetch:fetchProfile} = useFetchGetProfile();
  console.log(getProfile)
  const onFinish = async (values) => {
    console.log('Success:', values);
    // Handle form submission
    const id = getProfile?.loginId
    const body = values
    const payload = {id, body}
    await mutateUpdateAccount(payload, {
      onSuccess:() => {
        message.success('แก้ไขข้อมูลสำเร็จ')
        // fetchProfile()
        window.location.href = '/account-settings';
      },
      onError: (error) => {
        message.error(error.message)
      },
    })



  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    // message.error(errorInfo)
  };

  useEffect(() => {
    // if (getProfile) {
      // Set the form values once getProfile is available
      form.setFieldsValue({
        name: getProfile?.name,
        surname: getProfile?.surname,
        email: getProfile?.email,
        picture:getProfile?.picture
      });
    // }
  }, [getProfile]);

  const handleUploadImage =async (values)=>{
    const fileImage = values[0];
    const uploadData = await uploadImage(fileImage)
    console.log(uploadData)
    setPicture(uploadData?.fullpath )
    form.setFieldsValue({ picture: uploadData?.fullpath }); // Corrected line
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <div className="w-full">
          <Header category="App" title={`${titlePage}`}  />

          <div className="tab-content" id="tabs-tabContent">
            <div
              className="tab-pane fade show active"
              id="tabs-home"
              role="tabpanel"
              aria-labelledby="tabs-home-tab"
            >
            <Form
                 form={form}
                name="accountSettings"
                layout="vertical"
                // initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                   >
                    <Form.Item name="picture">
                  <div className="flex items-center mb-6">
                    <div className="flex ">
                      <img
                        src={picture || getProfile?.picture}
                        className="rounded-full w-32 mr-10"
                        alt="Avatar"
                      />
                    </div>
                    <div className="flex flex-col justify-items-start">
                      <h1 className="text-gray-700">เพิ่มรูปโปรไฟล์</h1>
                      <p className="py-2 text-sm font-medium text-gray-500">
                        กรุณาอัพโหลดรูปภาพ (อัตราส่วน 1:1)
                      </p>
                      <UploadImageBtn
                      onUpload={handleUploadImage}
                      file={form.getFieldsValue().picture}
                      />
                    </div>
                  </div>
                  </Form.Item>
              <div className="grid md:grid-cols-2 max-w-[1250px] m-auto gap-8">
                <div className="flex flex-col justify-left md:items-start w-full px-2 py-5">

                  <h1 className="text-xl text-gray-600 md:flex mb-5">
                    ข้อมูลทั่วไป
                  </h1>
                  <div className="mb-4 w-full">
                    <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="name" label="ชื่อ" rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}>
                      <Input
                        id="tradername"
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder="ชื่อ"
                        maxLength={30}
                      />
                    </Form.Item>
                  </div>
                  <div className="mb-4 w-full">
                  <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="surname" label="นามสกุล" rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}>
                      <Input
                        id="tradername"
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder="ชื่อ"
                        maxLength={30}
                      />
                    </Form.Item>
                  </div>
                  <div className="mb-4 w-full">
                  <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="email" label="อีเมล์" rules={[{ required: true, message: 'กรุณาระบุชื่อบริษัท' }]}>
                      <Input
                      id="email"
                      type="email"
                      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                      placeholder="your@email.com"
                    /> 
                    </Form.Item>
                  </div>
                </div>



                <div className="flex flex-col justify-left md:items-start w-full px-2 py-5">
                    <h1 className="text-xl text-gray-600 md:flex mb-5">
                      การตั้งค่าความปลอดภัย
                    </h1>
                    <div className="mb-4 w-full">
                      <Form.Item
                      className="block mb-2 text-sm font-medium text-gray-700"
                        label="เปลี่ยนรหัสผ่าน"
                        name="password"
                        rules={[{ required: false, message: 'กรุณากรอกรหัสผ่านใหม่' }]}
                      >
                        <Input.Password
                          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                          placeholder="รหัสผ่านใหม่"
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-4 w-full">
                    <Form.Item
                    className="block mb-2 text-sm font-medium text-gray-700"
                      label="ยืนยันรหัสผ่าน"
                      name="confirmPassword"
                      dependencies={['password']}
                      rules={[
                        { required: false, message: 'กรุณายืนยันรหัสผ่าน' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder="ยืนยันรหัสผ่านใหม่"
                      />
                    </Form.Item>
                    </div>
                  </div>
                </div>
              <div className="flex justify-end mt-6">
                <Button type="primary"   htmlType="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  บันทึกการเปลี่ยนแปลง
                </Button>
              </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;