import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';
export const useFetchLocationAll = () => {
    return useQuery(["getLocationAllV2"], async () => {
      const endPoin = `/api/v2/location/location/get-location-all`;
      const { result } = await GET(endPoin);
      return result.location;
    },{
      cacheTime:0
    }
  );
};

export const useMutationLocationByID = () => {
  return useMutation(["getLocationByID"],
    async (payload) => {
      const endPoin = `/api/v2/location/locationId/${payload}`;
      const { result } = await GET(endPoin);
      return result;
    }
  );
};




export const useMutationAddLocation = () => {
  return useMutation(["addLocation"],
    async (payload) => {
      const endPoin = `/api/v2/location`;
      await POST(endPoin,payload);
    },
  );
};


export const useMutationCheckStationInschedule = () => {
  return useMutation(["useMutationCheckStationInschedule"],
    async (payload) => {
      const endPoin = `/api/v2/location/check-station-in-schedule`;
      await POST(endPoin,payload);
    },
  );
};



export const useMutationUpdateLocation = () => {
  return useMutation(["updateLocation"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v2/location/${id}`;
      await PUT(endPoin,body);
    }
  );
};

export const useMutationDeleteLocation = () => {
  return useMutation(["deleteLocation"],
    async (payload) => {
      const endPoin = `/api/v2/location/${payload}`;
      await DELETE(endPoin);
    }
  );
};

export const useMutationLocationByCompany = () => {
  return useMutation(["getLocationByCompany"],
    async (payload) => {
      const endPoin = `/api/v2/location/company/${payload}`;
      const { result } = await GET(endPoin);
      return result;
    }
  );
};