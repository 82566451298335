import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';

const Button = ({ icon, bgColor, color, bgHoverColor, size, text, borderRadius, high, width, onClick }) => {
  const { setIsClicked, initialState } = useStateContext();

  const handleClick = () => {
    setIsClicked(initialState);
    if (onClick) onClick();
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`flex items-center justify-center text-${size} p-3 w-min-[100px] w-${width} h-${high} text-center hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon} <span className="ml-1">{text}</span>
    </button>
  );
};

export default Button;
