// import React, { useState } from 'react';
// import { Form, Input, Select, Checkbox, InputNumber } from 'antd';
// import UploadImageDrag from "../Upload/UploadImage/UploadImage";
// import { useFetchCustomerAll} from "../../hooks/useFetchCustomer";
// import { useStateContext } from '../../contexts/ContextProvider';
// import { uploadImage } from '../../function/uploadImage';


// function FormVehicle({ formRef, onFinish,initialValues={} ,edit=false}) {

//   const { data: customerAll, isLoading:isLoadingCustomerAll,refetch:refetchCustomerAl} = useFetchCustomerAll();
//   const {  getProfile } = useStateContext();
//   const [loading, setLoading] = useState(false); // Loading state

//   const customerOptions = customerAll?.map((val)=>{
//     return { label:`บริษัท ${val.company} | ${val.customerId}`,value:val.companyId,province:val.province}
//   })


//   const uploadImageBoat = async (values) => {
//     const fileImage = values[0];
//     const uploadData = await uploadImage(fileImage)
//     console.log(uploadData)
//     formRef.current.setFieldsValue({ imageVehicle: uploadData?.fullpath });
//   }

//   return (
//     <Form name="vehicleForm" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
//          {getProfile?.role === 'one2ticketEmployee' && !edit?    
//           <div>
//             <Form.Item name="companyId" label="บริษัท" rules={[{ required: true, message: 'กรุณาระบุยานพาหนะ' }]}>
//               <Select
//                 loading={isLoadingCustomerAll}
//                 placeholder="กรุณาเลือกบริษัท"
//                 options={customerOptions}
//                 // onChange={handleChangSelectCustomer}
//               >
//               </Select>
//               </Form.Item>
//           </div>
//         :null}
//       <div className='grid lg:gap-x-5 lg:grid-cols-2 '>
//         <Form.Item name="nameTh" label="ชื่อ (ไทย)" rules={[{ required: true, message: 'กรุณาระบุชื่อ (ไทย)' }]}>
//           <Input />
//         </Form.Item>
//         <Form.Item name="nameEn" label="ชื่อ (อังกฤษ)" rules={[{ required: true, message: 'กรุณาระบุชื่อ (อังกฤษ)' }]}>
//           <Input />
//         </Form.Item>
//         <Form.Item name="vehicleTypeId" label="ชนิด" rules={[{ required: true }]}>
//           <Select>
//             <Select.Option value={7}>Catamaran</Select.Option>
//             <Select.Option value={6}>Speed Boat</Select.Option>
//             <Select.Option value={8}>Long Tail Boat</Select.Option>
//           </Select>
//         </Form.Item>
//         <Form.Item name="capacity" label="ที่นั่ง" rules={[{ required: true, message: 'กรุณาระบุจำนวนที่นั่ง' }]}>
//           <InputNumber className='w-full' defaultValue={0} />
//         </Form.Item>
//       </div>
//       <br/>
//       <p>สิ่งอำนวยความสะดวก</p>
//       <div className='grid grid-cols-3 ]'>
//         <Form.Item name="airCondition" valuePropName="checked">
//           <Checkbox>Air Condition</Checkbox>
//         </Form.Item>
//         <Form.Item name="wc" valuePropName="checked">
//           <Checkbox>WC</Checkbox>
//         </Form.Item>
//         <Form.Item name="restaurant" valuePropName="checked">
//           <Checkbox>ร้านอาหาร/เครื่องดื่ม</Checkbox>
//         </Form.Item>
//         <Form.Item name="vipRoom" valuePropName="checked">
//           <Checkbox>VIP Room</Checkbox>
//         </Form.Item>
//         <Form.Item name="disabledPerson" valuePropName="checked">
//           <Checkbox>คนพิการ</Checkbox>
//         </Form.Item>
//       </div>
//       <div className='grid gap-8 lg:grid-cols-1 bg-red-green mb-20'>
//       <Form.Item name="imageVehicle">
//         <UploadImageDrag
//           key={1}
//           title="รูป ยานพหนะ"
//           onUpload={uploadImageBoat}
//           file={initialValues?.imageVehicle}
//         />
//         </Form.Item>
//       </div>
//     </Form>
//   );
// }

// export default FormVehicle;

import React, { useState } from 'react';
import { Form, Input, Select, Checkbox, InputNumber, Spin } from 'antd';
import UploadImageDrag from "../Upload/UploadImage/UploadImage";
import { useFetchCustomerAll } from "../../hooks/useFetchCustomer";
import { useStateContext } from '../../contexts/ContextProvider';
import { uploadImage } from '../../function/uploadImage';

function FormVehicle({ formRef, onFinish, initialValues = {}, edit = false }) {
  const { data: customerAll, isLoading: isLoadingCustomerAll, refetch: refetchCustomerAll } = useFetchCustomerAll();
  const { getProfile } = useStateContext();

  const [loading, setLoading] = useState(false); // Loading state

  const customerOptions = customerAll?.map((val) => {
    return { label: `บริษัท ${val.company} | ${val.customerId}`, value: val.companyId, province: val.province };
  });

  const uploadImageBoat = async (values) => {
    setLoading(true); // Set loading to true when upload starts
    try {
      const fileImage = values[0];
      const uploadData = await uploadImage(fileImage);
      console.log(uploadData);
      formRef.current.setFieldsValue({ imageVehicle: uploadData?.fullpath });
    } catch (error) {
      console.error('Image upload failed:', error);
      // Optionally, you can show an error message here
    } finally {
      setLoading(false); // Reset loading state after upload completes
    }
  };

  return (
    <Form name="vehicleForm" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      {getProfile?.role === 'one2ticketEmployee' && !edit ?
        <div>
          <Form.Item name="companyId" label="บริษัท" rules={[{ required: true, message: 'กรุณาระบุยานพาหนะ' }]}>
            <Select
              loading={isLoadingCustomerAll}
              placeholder="กรุณาเลือกบริษัท"
              options={customerOptions}
            >
            </Select>
          </Form.Item>
        </div>
        : null}
      <div className='grid lg:gap-x-5 lg:grid-cols-2 '>
        <Form.Item name="nameTh" label="ชื่อ (ไทย)" rules={[{ required: true, message: 'กรุณาระบุชื่อ (ไทย)' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="nameEn" label="ชื่อ (อังกฤษ)" rules={[{ required: true, message: 'กรุณาระบุชื่อ (อังกฤษ)' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="vehicleTypeId" label="ชนิด" rules={[{ required: true }]}>
          <Select>
            <Select.Option value={7}>Catamaran</Select.Option>
            <Select.Option value={6}>Speed Boat</Select.Option>
            <Select.Option value={8}>Long Tail Boat</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="capacity" label="ที่นั่ง" rules={[{ required: true, message: 'กรุณาระบุจำนวนที่นั่ง' }]}>
          <InputNumber className='w-full' defaultValue={0} />
        </Form.Item>
      </div>
      <br />
      <p>สิ่งอำนวยความสะดวก</p>
      <div className='grid grid-cols-3'>
        <Form.Item name="airCondition" valuePropName="checked">
          <Checkbox>Air Condition</Checkbox>
        </Form.Item>
        <Form.Item name="wc" valuePropName="checked">
          <Checkbox>WC</Checkbox>
        </Form.Item>
        <Form.Item name="restaurant" valuePropName="checked">
          <Checkbox>ร้านอาหาร/เครื่องดื่ม</Checkbox>
        </Form.Item>
        <Form.Item name="vipRoom" valuePropName="checked">
          <Checkbox>VIP Room</Checkbox>
        </Form.Item>
        <Form.Item name="disabledPerson" valuePropName="checked">
          <Checkbox>คนพิการ</Checkbox>
        </Form.Item>
      </div>
      <div className='grid gap-8 lg:grid-cols-1 bg-red-green mb-20'>
        <Form.Item name="imageVehicle">
          <UploadImageDrag
            key={1}
            title="รูป ยานพหนะ"
            onUpload={uploadImageBoat}
            file={initialValues?.imageVehicle}
            loading={loading} // Pass loading state to the component
          />
        </Form.Item>
      </div>
    </Form>
  );
}

export default FormVehicle;
