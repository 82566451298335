import React, { useState, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, TimePicker, Input, Select, InputNumber, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useFetchVehicleAll,useFetchVehicleByCompany} from "../../hooks/useFetchVehicle";
// import { useFetchLocationAll,} from "../../hooks/useFetchSchedule";
import { useMutationLocationByCompany,} from "../../hooks/useFetchLocation";
import { useFetchCustomerAll} from "../../hooks/useFetchCustomer";
import { useMutationEmployeeByCompany} from "../../hooks/useFetchEmployee";
import DatePickerProvider from "../DatePickerProvider/DatePickerProvider";
import { useStateContext } from '../../contexts/ContextProvider';
const { Option } = Select;
const { RangePicker } = DatePicker;

const format = 'HH:mm';

const dayMapping = {
  'จ.': 'Monday',
  'อ.': 'Tuesday',
  'พ.': 'Wednesday',
  'พฤ.': 'Thursday',
  'ศ.': 'Friday',
  'ส.': 'Saturday',
  'อา.': 'Sunday'
};

const FromSchedule = ({ formRef, onFinish, initialValues = {} ,edit=false}) => {
  const { data: vehicleAll, isLoading:isLoadingVehicleAll,refetch:refetchVehicleAll} = useFetchVehicleAll();
  const { data: vehicleByCompany, isLoading:isLoadingVehicleByCompany,mutate:fetchVehicleByCompany} = useFetchVehicleByCompany(null);


  // const { data: locationAll,isLoading:isLoadingLocationAl} = useFetchLocationAll();
  const { data: locationAll,isLoading:isLoadingLocationAl,mutate:fetchLocation} = useMutationLocationByCompany();
  const { data: customerAll, isLoading:isLoadingCustomerAll,refetch:refetchCustomerAll} = useFetchCustomerAll();
  const { data: employeeAll, isLoading:isLoadingEmployeeByCompany,mutate:fetchEmployeeByCompany} = useMutationEmployeeByCompany();

  const [scheduleType, setScheduleType] = useState(initialValues.scheduleType);
  const [originStationOptions, setOriginStationOptions] = useState([]);
  const [destinationStationOptions, setDestinationStationOptions] = useState([]);
  const {  getProfile } = useStateContext();
  const [quotaTickets, setQuotaTickets] = useState(initialValues.quotaTickets || 0);
  // console.log('locationAll',locationAll)
  const [form] = Form.useForm();

  console.log(employeeAll)

  // useEffect(() => {
  //   // if(getProfile?.role === "customer"){
  //   //   console.log('innnnnnnn')
  //   //   // fetchLocation(getProfile.companyId)
  //   //   fetchLocation(3)
  //   // }
  //   fetchLocation(getProfile.companyId)
  //   console.log('getProfile',getProfile)
  //   console.log('companyId',getProfile.companyId)
  //   // fetchLocation(value)
  // }, [getProfile])
  

  useEffect(() => {
    fetchLocation(initialValues?.companyId || getProfile.companyId)
    fetchVehicleByCompany(initialValues?.companyId)
    fetchEmployeeByCompany(initialValues?.companyId  || getProfile.companyId)
  }, [initialValues])
  //#region Options
  const dayOptions = ['จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.', 'อา.'].map(day => ({
    label: day,
    value: dayMapping[day]
  }));
  
  const scheduleOptions = [
    { label: 'ทุกวัน', value: 'everyday' },
    { label: 'ระบุรายวัน', value: 'specificDays' },
  ];
  
  const locationOptions =locationAll?.map(loc => ({
    label: loc.name,
    value: loc.locationId,
  }));

  const vehicleOptions = getProfile?.role === 'customer'? vehicleAll:vehicleByCompany

  const vehicleOptionsAll = vehicleOptions?.map((val)=>{
    return { 
      label:val.nameTh,
      value:val.id ,
      image:val.imageVehicle,
      dataTicket: val.capacity}
  })

  const customerOptions = customerAll?.map((val)=>{
    return { label:`บริษัท ${val.company} | ${val.customerId}`,value:val.companyId,province:val.province}
  })

  const driver = employeeAll?.filter(item=>item.position === 'คนขับเรือ')
  const driverOptions = driver?.map((val)=>{
    return { label:`${val.name} ${val.surname} | ${val.employeeId}`,value:`${val.name} ${val.surname}`,image:val.picture}
  })

  console.log(driverOptions)
 
 
  //#endregion

  useEffect(() => {
    if(!isLoadingLocationAl){
      if (initialValues.originLocation) {
        handleLocationChange(initialValues.originLocation, setOriginStationOptions);
      }
      if (initialValues.destinationLocation) {
        handleLocationChange(initialValues.destinationLocation, setDestinationStationOptions);
      }
    }
  }, [initialValues,isLoadingLocationAl]);

  const handleLocationChange = (locationId, setStationOptions) => {
    const location = locationAll?.find(loc => loc.locationId === locationId);
    setStationOptions(location ? location.station.map(st => ({ label: st.name, value: st.stationId })) : []);
  };

  const convertTimeData = (timeData) => {
    if (Array.isArray(timeData)) {
      return timeData.map(item => ({
        startTime: item?.startTime ? dayjs(item?.startTime, format) : null,
        duration: item?.duration ? dayjs(item?.duration, format) : null
      }));
    }
    return [];
  };

  const convertRangePickerData = (rangPickerData) => {
    return rangPickerData?.map(date => dayjs(date));
  };

  const handleChangSelectCustomer = (value)=>{
    console.log('value',value)
    fetchVehicleByCompany(value)
    fetchLocation(value)
    fetchEmployeeByCompany(value)
  }


  const handleChangSelectVehicle = (value) => {
    const selectedVehicle = vehicleOptionsAll.find(item => item.value === value);
    console.log('selectedVehicle',selectedVehicle)

    if (selectedVehicle) {
      console.log('inn')
      const dataTicket = selectedVehicle.dataTicket || 0;
      console.log(dataTicket)

      // ใช้ form.setFieldsValue เพื่ออัปเดตฟิลด์ quotaTickets
      form.setFieldsValue({
        quotaTickets: dataTicket,
      });
      setQuotaTickets(dataTicket);
    }
  };

  return (
    <Form
      ref={formRef}
      form={form} // เพิ่ม form={form}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        ...initialValues,
        rangPicker: convertRangePickerData(initialValues?.rangPicker),
        time: convertTimeData(initialValues?.time),
        quotaTickets: initialValues.quotaTickets || 0, // กำหนดค่าเริ่มต้น
      }}
    >
      <div className="grid">
        {getProfile?.role === 'one2ticketEmployee' && !edit?    
          <div>
            <Form.Item name="companyId" label="บริษัท" rules={[{ required: true, message: 'กรุณาระบุยานพาหนะ' }]}>
              <Select
                loading={isLoadingCustomerAll}
                placeholder="กรุณาเลือกบริษัท"
                options={customerOptions}
                onChange={handleChangSelectCustomer}
              >
              </Select>
              </Form.Item>
          </div>
        :null}
     
        <div>
        <DatePickerProvider>
          <Form.Item name="rangPicker" label="ระยะเวลา" rules={[{ required: true, message: 'กรุณาระบุวันระยะเวลา' }]}>
            <RangePicker disabled = {edit? true:false} className="w-full" placeholder={["เริ่ม", "สิ้นสุด"]} />
          </Form.Item>
          </DatePickerProvider>
        </div>
        <div className="grid lg:grid-cols-1 lg:gap-4">
          <Form.Item  style={{ display: "none"}} name="scheduleType" label="ระบุวัน" rules={[{ required: true, message: 'กรุณาเลือกระบุวัน' }]}>
            <Select options={scheduleOptions} onChange={value => setScheduleType(value)} value={'everyday'} placeholder="ระบุวัน" />
          </Form.Item>
          {scheduleType === 'specificDays' && (
            <div className='mt-[-30px]'>
              <Form.Item name="day" label="วัน" rules={[{ required: true, message: 'กรุณาระบุวัน' }]}>
                <Select mode="tags" options={dayOptions} placeholder="Select Day" />
              </Form.Item>
            </div>
          )}
        </div>
        <br/>
        <div className="grid lg:grid-cols-1 lg:gap-0">
         <Form.Item name="driver" label="ชื่อคนขับ" rules={[{ required: true, message: 'กรุณาระบุชื่อคนขับ' }]}>
            {/* <Input className="w-full" placeholder="กรุณาระบุชื่อคนขับ"/> */}
            <Select
            className='h-[50px]'
            loading={isLoadingEmployeeByCompany}
            placeholder="กรุณาเลือกคนขับ"
          >
            {driverOptions?.map((vehicle) => (
              <Option key={vehicle.value} value={vehicle.value} className='h-[50px] items-center'>
                <div  style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={vehicle.image} // เพิ่มฟิลด์ image ที่มี URL ของรูปภาพ
                    alt={vehicle.label}
                    className='w-[30px] h-[30px]  rounded-full mr-2'
                  />
                  {vehicle.label}
                </div>
              </Option>
            ))}
          </Select>
          </Form.Item>
          <Form.Item name="vehicle" label="ยานพาหนะ" rules={[{ required: true, message: 'กรุณาระบุยานพาหนะ' }]}>
          <Select
            className='h-[50px]'
            loading={isLoadingVehicleByCompany}
            placeholder="กรุณาเลือกยานพาหนะ"
            onChange={handleChangSelectVehicle}
          >
            {vehicleOptionsAll?.map((vehicle) => (
              <Option key={vehicle.value} value={vehicle.value} className='h-[50px] items-center'>
                <div  style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={vehicle.image} // เพิ่มฟิลด์ image ที่มี URL ของรูปภาพ
                    alt={vehicle.label}
                    className='w-[30px] h-[30px]  rounded-full mr-2'
                  />
                  {vehicle.label}
                </div>
              </Option>
            ))}
          </Select>
          </Form.Item>
        </div>
        <div className="grid lg:grid-cols-2 lg:gap-4">
          <Form.Item name="originLocation" label="ต้นทาง" rules={[{ required: true, message: 'กรุณาระบุสถานีต้นทาง' }]}>
            <Select options={locationOptions}  placeholder="กรุณาเลือกสถานที่" onChange={(value) => handleLocationChange(value, setOriginStationOptions)} loading={isLoadingLocationAl} />
          </Form.Item>
          <Form.Item name="originStation" label="สถานีต้นทาง" rules={[{ required: true, message: 'กรุณาระบุสถานีปลายทาง' }]}>
            <Select options={originStationOptions} placeholder="กรุณาเลือกสถานี" />
          </Form.Item>
        </div>
        <div className="grid lg:grid-cols-2 lg:gap-4">
          <Form.Item name="destinationLocation" label="ปลายทาง" rules={[{ required: true, message: 'กรุณาระบุสถานีต้นทาง' }]}>
            <Select options={locationOptions} placeholder="กรุณาเลือกสถานที่" onChange={(value) => handleLocationChange(value, setDestinationStationOptions)} loading={isLoadingLocationAl} />
          </Form.Item>
          <Form.Item name="destinationStation" label="สถานีปลายทาง" rules={[{ required: true, message: 'กรุณาระบุสถานีปลายทาง' }]}>
            <Select options={destinationStationOptions} placeholder="กรุณาเลือกสถานี" />
          </Form.Item>
        </div>
        <div className="grid lg:grid-cols-3 lg:gap-4">
          <Form.Item name="price" label="ราคา(มาตราฐาน)" rules={[{ required: true, message: 'กรุณาระบุราคา(มาตราฐาน)' }]}>
            <InputNumber className="w-full" min={1} addonAfter={<div>บาท</div>} />
          </Form.Item>
          <Form.Item name="localPrice" label="ราคา(คนเกาะ)" rules={[{ required: true, message: 'กรุณาระบุราคา(สำหรับคนเกาะ)' }]}>
            <InputNumber className="w-full" min={1} addonAfter={<div>บาท</div>} />
          </Form.Item>
          <Form.Item name="quotaTickets" label="จำนวนตั๋ว"  
          rules={[
            { required: true, message: 'กรุณาระบุ จำนวนตั๋ว' },
          ]}>
            <InputNumber className="w-full" min={1} max={quotaTickets} addonAfter={<div>ใบ</div>} />
          </Form.Item>
        </div>
        <br/>
        <Form.List name="time">
          {(fields, { add, remove }) => (
            <>
              <div className="max-h-[150px]  overflow-y-auto pr-3" >
                {fields.map(({ key, name, ...restField }) => (
                  <Form.Item
                    {...restField}
                    key={key}
                    // label={key === 0 ? 'เวลาออก' : ''}
                    rules={[{ required: true, message: 'Missing time' }]}
                    className= 'm-0 p-0 w-full'
                  >
                    <div className="flex items-center gap-3">
                      <Form.Item className='flex-1'  label={key === 0 ? 'เวลาออก' : ''} name={[name, 'startTime']}    rules={[{ required: true, message: 'กรุณาระบุ เวลาเริ่มต้น' }]}  >
                        <TimePicker
                          className="w-full"
                          placeholder="เวลาออก"
                          format={format}
                          suffixIcon={'นาฬิกา'}
                        />
                      </Form.Item>
                      <Form.Item className='flex-1'   label={key === 0 ? 'เวลาในการเดินทาง' : ''} name={[name, 'duration']}   rules={[{ required: true, message: 'กรุณาระบุ เวลาในการเดินทาง' }]}>
                        <TimePicker
                          className="w-full"
                          placeholder="เวลาในการเดินทาง"
                          format={format}
                          suffixIcon={'ชม.'}
                        />
                      </Form.Item>
                      {key !== 0 ? ( <MinusCircleOutlined className='mt-[-20px]' onClick={() => remove(name)} />):null }    
                    </div>
                  </Form.Item>
                ))}
              </div>
              <Form.Item>
                {!edit ? ( <Button className="w-[170px]" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  เพิ่ม
                </Button>):null}
               
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </Form>
  );
};

export default FromSchedule;

