import React from 'react';
import { Table, Space, Tag,Popconfirm } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import dayjs from 'dayjs';

const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const summaryStyle = {
  fontWeight: 'bold',
  backgroundColor: '#f0f0f0',
};


const SaleInfoInfoTable = ({ data, onInfo, onPay,onHistorySale,loading,forPrint = false }) => {
  const { currentColor} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'รหัส', 
      align: 'center', 
      dataIndex: 'id', 
      key: 'id',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'ชื่อ', 
      align: 'center', 
      dataIndex: 'fullName', 
      key: 'fullName' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'ตำแหน่ง', 
      align: 'center', 
      dataIndex: 'position', 
      key: 'position' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'จำนวนที่ขายทั้งหมด', 
      align: 'center', 
      dataIndex: 'totalBooking', 
      key: 'totalBooking' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'จำนวนตั๋วค้างชำระ',
      children: [
        {
          title: 'ทั่วไป',
          align: 'center', 
          width:100,
          dataIndex: 'totalPriceQty',
          key: 'totalPriceQty',
        },
        {
          title: 'ท้องถิ่น',
          align: 'center', 
          width:100,
          dataIndex: 'totalLocalPriceQty',
          key: 'totalLocalPriceQty',
        },
        {
          title: 'รวม',
          align: 'center', 
          width:100,
          dataIndex: 'totalQty',
          key: 'totalQty',
        },
      ],
    },
    { 
      title: 'ยอดเงินรวม', 
      align: 'center', 
      dataIndex: 'totalAmount', 
      key: 'totalAmount',
      onHeaderCell: () =>{return {style:headerStyle}},
      render: (text) => Number(text)?.toLocaleString('en-US'),  
    },
    {
      title: 'ประวัติการขาย',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onHistorySale(record)}>
            ดู
          </div>
         
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    },
    {
      title: 'ชำระเงิน',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onPay(record)}>
            ชำระ
          </div>
          <div className="text-blue-500 cursor-pointer" onClick={() => onInfo(record)}>
            ประวัติ
          </div>
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
 
  ];
  const sumNumeric = (arr, key) => arr?.reduce((sum, curr) => sum + (Number(curr[key]) || 0), 0);

  // คำนวณผลรวมสำหรับคอลัมน์ที่ต้องการ
  const totalPriceQty = sumNumeric(data, 'totalPriceQty');
  const totalLocalPriceQty = sumNumeric(data, 'totalLocalPriceQty');
  const totalBooking = sumNumeric(data, 'totalBooking');
  const totalAmount = sumNumeric(data, 'totalAmount');
  const totalQty = sumNumeric(data, 'totalQty');

  
  // ฟังก์ชันช่วยในการแสดงผลตัวเลขทศนิยม 2 ตำแหน่ง
  const formatNumber = (number) => Number(number).toFixed(2);
  
  
  return (
    
    <div className="payment-info-table">
       <style jsx>{`
        .payment-info-table .ant-table-summary > tr > td {
          background-color: #f0f0f0 !important;
        }
        .payment-info-table .ant-table-summary > tr > td.ant-table-cell {
          font-weight: bold !important;
        }
        @media print {
          .payment-info-table .ant-table-summary > tr > td {
            background-color: #f0f0f0 !important;
          }
          .payment-info-table .ant-table-summary > tr > td.ant-table-cell {
            font-weight: bold !important;
          }
        }
      `}</style>
          <div className='overflow-x-auto'>
      <Table 
        className='border-1 rounded-lg border-gray-200'   
        loading={loading}    
        dataSource={genNumberTable(data)} 
        columns={columns}  
        pagination={forPrint ? false : { pageSize: 10 }}
        rowKey={'id'} 
        scroll={{ x: 'max-content' }}  
        summary={() => (
          <Table.Summary fixed={true}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4} style={summaryStyle}>รวมทั้งหมด</Table.Summary.Cell>
              <Table.Summary.Cell index={5} align="center" style={summaryStyle}>{totalBooking}</Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="center" style={summaryStyle}>{totalPriceQty}</Table.Summary.Cell>
              <Table.Summary.Cell index={7} align="center" style={summaryStyle}>{totalLocalPriceQty}</Table.Summary.Cell>
              <Table.Summary.Cell index={8} align="center" style={summaryStyle}>{totalLocalPriceQty}</Table.Summary.Cell>
              <Table.Summary.Cell index={9} align="center" style={summaryStyle}>{totalAmount?.toLocaleString()}</Table.Summary.Cell>
              <Table.Summary.Cell index={10} align="center" style={summaryStyle}></Table.Summary.Cell>
              <Table.Summary.Cell index={11} align="center" style={summaryStyle}>{''}</Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      </div>
    </div>
  );
};

export default SaleInfoInfoTable;
