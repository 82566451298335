// In the data/boat.js file

export const boatData ={
result: [
    {
      BoatID: 1,
      BoatName: 'รุ่งแสง 1',
      ImageUrl:'https://lh3.googleusercontent.com/d/1KDNo6L8y-aFpsSa9wokwLELuFRrjLJPb',
      Type: 'speedboat',
      Capacity: 6,
      airCondition: false,
      wc: false
    },
    {
      BoatID: 2,
      BoatName: 'รุ่งแสง 2',
      ImageUrl:'https://lh3.googleusercontent.com/d/15vioHPewVHmsRZ-YzLnySOftqIoWrDJL',
      Type: 'speedboat',
      Capacity: 10,    
      airCondition: true,
      wc: false
    },
    {
      BoatID: 3,
      BoatName: 'รุ่งแสง 3',
      ImageUrl:'https://lh3.googleusercontent.com/d/1HDZSqkfM9jMdiQMHsrLi_vIpVpvnG2eL',
      Type: 'speedboat',
      Capacity: 4,
      airCondition: true,
      wc: true
    }
  ]
};
  