import React from 'react';
import { Table, Space,Avatar,Popconfirm } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const EmployeeTable = ({ data, onEdit, onDelete ,loading}) => {
  const { currentColor,setGetProfile} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'รูปภาพ', 
      align: 'center',
      width: 50, 
      dataIndex: 'picture', 
      key: 'picture', 
      render: (text) => <Avatar icon={<UserOutlined />} className='object-cover rounded-full shadow-lg size-14' src={text} alt="Boat" /> ,onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'รหัสพนักงาน', 
      align: 'center', 
      dataIndex: 'employeeId', 
      key: 'employeeId',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'บริษัท', 
      align: 'center', 
      dataIndex: 'company', 
      key: 'company',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'ชื่อ', 
      align: 'center', 
      dataIndex: 'name', 
      key: 'name',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'นามสกุล', 
      align: 'center', 
      dataIndex: 'surname', 
      key: 'surname',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'ตำแหน่ง', 
      align: 'center', 
      dataIndex: 'position', 
      key: 'position',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    {
      title: 'อีเมล์', 
      align: 'center', 
      dataIndex: 'email', 
      key: 'email' ,
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    // {
    //   title: 'จังหวัด', 
    //   align: 'center', 
    //   dataIndex: 'province', 
    //   key: 'province' ,
    //   onHeaderCell: () =>{return {style:headerStyle}}
    // },
    {
      title: 'การจัดการ',
      fixed: 'right',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
            แก้ไข
          </div>

          <Popconfirm
            title="ลบข้อมูลพนักงาน"
            description="ยืนยันการลบข้อมูลพนักงาน ?"
            onConfirm={() => onDelete(record)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
          >
              <div className="text-red-500 cursor-pointer" >
                ลบ
              </div>

           </Popconfirm>
      
        </Space>
      ),
      onHeaderCell: () => ({ style: headerStyle }),
    }
  ];

  return ( 
    <div className='overflow-x-auto'>
      <Table 
        loading={loading}   
        className=' border-1 rounded-lg border-gray-200' 
        dataSource={genNumberTable(data)}
        columns={columns}  
        pagination={{ pageSize: 8}} 
        rowKey={'CustomerID'}
        scroll={{ x: 'max-content' }}  
        />
     </div>
    );
};

export default EmployeeTable;
