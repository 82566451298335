import React from 'react';
import { Table } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import dayjs from 'dayjs';
// const headerStyle = {
//   color: '#525252', // Color of the header text
// };

const headerStyle = {
  color: '#FFFFFF', // สีของข้อความใน header
  backgroundColor: '#F05323', // สีพื้นหลังของ header
};

const ScheduleTable = ({ data }) => {
  const columns = [
    { 
      title: 'รายการ', 
      align: 'center', 
      key: 'originDestination', // Use a unique key
      onHeaderCell: () => ({ style: headerStyle }), 
      render: (text, record) => (
        <div >
          <div>{record?.boatName}</div>
          <div> {`${record?.originPier} - ${record?.destinationPier}`}</div>
        </div>
      ),
    },
    { 
      title: 'เวลาออก', 
      align: 'center', 
      dataIndex: 'departureTime', 
      key: 'departureTime',
      onHeaderCell: () => ({ style: headerStyle }) ,
      render: (text) => dayjs(text, 'HH:mm:ss').format('HH:mm'),
    },
    { 
      title: 'จำนวนที่นั่ง', 
      align: 'center', 
      dataIndex: 'capacity', 
      key: 'capacity', 
      onHeaderCell: () => ({ style: headerStyle }),
      render: (text, record) => (
        <div>{`${record.capacity}/${record.quotaTickets}`}</div>
      ),
    },
  ];

  return ( 
    <div className='overflow-x-auto'>
      <Table 
        className='border-1 rounded-lg border-gray-200' 
        dataSource={genNumberTable(data)}
        columns={columns}  
        pagination={{ pageSize: 3 }} 
        scroll={{ x: 'max-content' }}  
      />
    </div>
  );
};

export default ScheduleTable;
