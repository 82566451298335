import React,{useState,useRef} from 'react';
import { Header } from '../../components';
import { Button,message } from 'antd';
import EmployeeTable from '../../components/Table/EmployeeTable';
import { boatData } from '../../data/mock/boat';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal } from "../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import { useFetchCustomerAll,useMutationAddCustomer,useMutationDeleteCustomer,useMutationUpdateCustomer} from "../../hooks/useFetchCustomer";
import { useFetchEmployeeAll,useMutationAddEmployee,useMutationDeleteEmployee,useMutationUpdateEmployee} from "../../hooks/useFetchEmployee";
import { useFetchVehicleAll,useMutationVehicleByID,useMutationVehicle,useMutationDeleteVehicle,useMutationUpdateVehicle} from "../../hooks/useFetchVehicle";
import Add from './Add';
import Edit from './Edit';
import { transformData} from './response';
import { validateFieldsImage} from '../../function/validateFieldsImage';

const Employee = () => {
  const titlePage = 'พนักงาน'
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const { currentColor,getProfile,btnColor} = useStateContext();
  const [dataEdit, setDataEdit] = useState(null);
  const { data: customerAll, isLoading:isLoadingCustomerAll,refetch:refetchCustomerAll } = useFetchCustomerAll();
  const { data: employeeAll, isLoading:isLoadingEmployeeAll,refetch:refetchEmployeeAll } = useFetchEmployeeAll();

  const { data: vehicleAll, isLoading:isLoadingVehicleAll,refetch:refetchVehicleAll} = useFetchVehicleAll();
  const { isLoading:isLoadingAddCustomer,mutate:mutateAddEmployee} = useMutationAddEmployee();
  const { isLoading:isLoadingUpdateEmployee,mutate:mutateUpdateEmployee} = useMutationUpdateEmployee();
  const { isLoading:isLoadingDeleteEmployee, mutate:mutateDeleteEmployee} = useMutationDeleteEmployee();
  const permissionsList = ['dashboard','payments', 'employees', 'vehicles', 'schedule', 'ticket', 'business','promotion','review','location'];

  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      let loadingMessage = null; 
      message.loading('กรุณารอซักครู่...', 0);
      try {
        const value =  await formAddRef.current.validateFields();
        let body = transformData(value)
        // body.role =  getProfile.role === 'one2ticketEmployee'? 'one2ticketEmployee' :'customerEmployee'
        body.permissions = Object.keys(value)
        .filter(key => permissionsList.includes(key) && value[key]); // กรองเฉพาะ permissions ที่ถูกเลือก
        console.log('body',body)
        await mutateAddEmployee(body, {
          onSuccess:() => {
            message.destroy(); 
            refetchEmployeeAll()
            formAddRef.current.submit();
            hideAddModal();
            message.success('เพิ่มข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.destroy(); 
            message.error(error?.response.data?.message)
          },
        })
  
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='ส่งอีเมลเชิญผู้ใช้งาน' cancelText='ยกเลิก' width={600}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading={isLoadingAddCustomer}>
        <Add formRef={formAddRef} />
      </Modal>
    )
  });

  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {
      try {
        const value =  await formEditRef.current.validateFields();
        let loadingMessage = null; 
        message.loading('กรุณารอซักครู่...', 0);
        const id = dataEdit.loginId
        let body = transformData(value)
        body.permissions = Object.keys(value)
        .filter(key => permissionsList.includes(key) && value[key]); // กรองเฉพาะ permissions ที่ถูกเลือก
        // console.log('body',body)
        const payload = {id, body}
        await mutateUpdateEmployee(payload, {
          onSuccess:() => {
            message.destroy(); 
            refetchEmployeeAll()
            formEditRef.current.submit();
            hideEditModal()
            message.success('แก้ไขข้อมูลสำเร็จ')
          },
          onError: (error) => {
            message.destroy(); 
            message.error(error?.response.data?.message)
          },
        })
    
      } catch (error) {
        console.error('Form validation error:', error);
      }
    
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={600}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading={isLoadingUpdateEmployee}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
  )},[dataEdit]);
  //#endregion

  const handleEdit = (record) => {
    setDataEdit(record);
    showEditModal()
  };

  const handleDelete = (record) => {
    console.log('Delete action for:', record);

    mutateDeleteEmployee(record.loginId,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchEmployeeAll()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
        {/* <Button onClick={showAddModal} color="white" bgColor={currentColor} text="เพิ่ม" size="[8px]" borderRadius="8px" high="[30px]" width="[90px]" /> */}
          <Button onClick={showAddModal} style={{ backgroundColor: btnColor ,color:'#FFFF'}}>เพิ่ม</Button>
      </div>
      <EmployeeTable data={employeeAll} onEdit={handleEdit} onDelete={handleDelete} loading = {isLoadingEmployeeAll} />
    </div>
  );
};

export default Employee;
