// import React, { useEffect, useState } from 'react';
// import { Upload, message } from 'antd';
// import { InboxOutlined } from '@ant-design/icons';
// import "./UploadImage.css";
// const { Dragger } = Upload;

// const UploadImage = ({ title, onUpload, clear = false, file }) => {

//   const [fileList, setFileList] = useState([]);

//   useEffect(() => {
//     try {
//       if (file) {
//         // ดึงชื่อไฟล์จาก URL
//         const url = new URL(file);
//         const fileName = url.pathname.split('/').pop();
//         setFileList([
//           {
//             uid: "-1",
//             name: fileName ,
//             status: "done",
//             url: file
//           }])
//       } else {
//         setFileList([]);
//       }
      
//     } catch (error) {
//       console.error(error);
//     }
    
//   }, [file]);

//   useEffect(() => {
//     if (clear) {
//       setFileList([]);
//     }
//   }, [clear]);



//   const handleBeforeUpload = (file) => {
//     setFileList([file]);
//     onUpload([file]);
//     return false;
//   };

//   const handleChangeUploadImage = (info) => {
//     const fileList = [...info.fileList];
//     const { status } = info.file;

//     if (status === 'removed') {
//       setFileList([]);
//       onUpload([]);
//     }
//     if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//     setFileList(fileList);
//   };

//   return (
//     <div className='upload-image-container'>
//       <p><span style={{ color: 'red'}}>* </span>{title}</p>
//       <Dragger
//         accept=".png,.jpg,.jpeg,.gif"
//         style={{ padding: '20px',marginTop:'10px'}}
//         multiple={false}
//         fileList={fileList}
//         onChange={handleChangeUploadImage}
//         beforeUpload={handleBeforeUpload}
//         name="file"
//         listType="picture"
//       >
//         <p className="ant-upload-drag-icon">
//           <InboxOutlined />
//         </p>
//         <p className="ant-upload-text">คลิกหรือลากไฟล์เพื่ออัปโหลด</p>
//         <p className="ant-upload-hint">รองรับการอัปโหลดไฟล์ประเภท .png, .jpg, .jpeg, .gif เท่านั้น</p>
//       </Dragger>
//     </div>
//   );
// };

// export default UploadImage;
import React, { useEffect, useState } from 'react';
import { Upload, message, Progress } from 'antd'; // Import Progress for progress bar
import { InboxOutlined } from '@ant-design/icons';
import "./UploadImage.css";
const { Dragger } = Upload;

const UploadImage = ({ title, onUpload, clear = false, file, loading }) => {
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0); // State for tracking progress

  useEffect(() => {
    try {
      if (file) {
        // Extracting filename from URL
        const url = new URL(file);
        const fileName = url.pathname.split('/').pop();
        setFileList([
          {
            uid: "-1",
            name: fileName,
            status: "done",
            url: file
          }
        ]);
      } else {
        setFileList([]);
      }
      
    } catch (error) {
      console.error(error);
    }
    
  }, [file]);

  useEffect(() => {
    if (clear) {
      setFileList([]);
      setUploadProgress(0); // Reset progress on clear
    }
  }, [clear]);

  const handleBeforeUpload = (file) => {
    setFileList([file]);
    uploadFile(file); // Start uploading the file
    return false; // Prevent automatic upload
  };

  const uploadFile = async (file) => {
    // Simulating file upload progress
    const mockUpload = () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 100) {
              clearInterval(interval);
              resolve(true);
              return 100;
            }
            return prev + 10; // Increment progress by 10% for demonstration
          });
        }, 100); // Adjust timing as needed
      });
    };

    await mockUpload();

    // Simulate successful upload
    message.success(`${file.name} อัพโหลดสำเร็จ`);
    onUpload([file]); // Pass the uploaded file back
    setUploadProgress(0); // Reset progress after upload is complete
  };

  const handleChangeUploadImage = (info) => {
    const fileList = [...info.fileList];
    const { status } = info.file;

    if (status === 'removed') {
      setFileList([]);
      onUpload([]);
    }
    if (status === "error") {
      message.error(`${info.file.name} อัพโหลดไม่สำเร็จ`);
    }
    setFileList(fileList);
  };

  return (
    <div className='upload-image-container'>
      <p><span style={{ color: 'red'}}>* </span>{title}</p>
      <Dragger
        accept=".png,.jpg,.jpeg,.gif"
        style={{ padding: '20px', marginTop: '10px' }}
        multiple={false}
        fileList={fileList}
        onChange={handleChangeUploadImage}
        beforeUpload={handleBeforeUpload}
        name="file"
        listType="picture"
        disabled={loading} // Disable the dragger while loading
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">คลิกหรือลากไฟล์เพื่ออัปโหลด</p>
        <p className="ant-upload-hint">รองรับการอัปโหลดไฟล์ประเภท .png, .jpg, .jpeg, .gif เท่านั้น</p>
      </Dragger>
      {uploadProgress > 0 && <Progress percent={uploadProgress} status="active" />} {/* Show progress bar only when progress is more than 0 */}
    </div>
  );
};

export default UploadImage;
