import React from 'react';
import { useNavigate, Link,useParams } from "react-router-dom";
import { Header } from '../../components';
import { Button,Breadcrumb ,Typography} from 'antd';
import { useStateContext } from '../../contexts/ContextProvider';
import PaymentHistoryReceivingMoney from '../../components/Table/PaymentHistoryReceivingMoney';
import { useFetchPaymentHistoryReceivingMoney } from '../../hooks/useFetchPayment';

function HistoryReceivingMoney() {
const { currentColor,getProfile} = useStateContext();

  const { Text } = Typography;
  const titlePage = "ประวัติการรับเงิน"
  const { data: paymentCompanyHistory, isLoading: isLoadingPaymentCompanyHistory, refetch:refetchPaymentCompanyHistory } = useFetchPaymentHistoryReceivingMoney();
  
  const breadcrumbItems = [
    { title:  getProfile?.role === 'customer' ? <Link to="/payment-sale-info">{'ข้อมูลการขาย'}</Link> :<Link to="/payment-info">{'ข้อมูลยอดเงิน'}</Link>},
    { title: <Text strong>{titlePage}</Text> },
  ];

  return (
   <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
      </div>
      {/* <Breadcrumb style={{ margin: "1rem 0" }} items={breadcrumbItems} /> */}
      <PaymentHistoryReceivingMoney data={paymentCompanyHistory}  loading={isLoadingPaymentCompanyHistory}/> 
      
    </div>
  );
}

export default HistoryReceivingMoney;
