import { useQuery, useMutation } from "@tanstack/react-query";
import { GET,POST ,DELETE,PUT} from '../services/index';





export const useFetchPaymentAll = (startDate, endDate) => {
  return useQuery(
    ['getPaymentAll', startDate, endDate], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-all?startDate=${startDate}&endDate=${endDate}`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useFetchPaymentAdminAll = () => {
  return useQuery(
    ['getPaymentAll'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-admin-all`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchPaymentSaleInfoAll = (startDate, endDate) => {
  return useQuery(
    ['getPaymentAll'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-sale-info-all`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchPaymentDetailByEmployee = (startDate, endDate,employeeId) => {
  return useQuery(
    ['getPaymentDetailByEmployee', startDate, endDate,employeeId], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-detail-by-employee?startDate=${startDate}&endDate=${endDate}&userId=${employeeId}`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useFetchPaymentDetailByCompany = (startDate, endDate,companyId) => {
  return useQuery(
    ['getPaymentDetailByEmployee', startDate, endDate,companyId], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-detail-by-company?startDate=${startDate}&endDate=${endDate}&companyId=${companyId}`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useFetchPaymentSaleHistoryByEmployee = (startDate, endDate,employeeId) => {
  return useQuery(
    ['PaymentSaleHistoryByEmployee', startDate, endDate,employeeId], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-sale-history-by-employee?startDate=${startDate}&endDate=${endDate}&userId=${employeeId}`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchPaymentHistory = (employeeId) => {
  return useQuery(
    ['getPaymentHistory',employeeId], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-history/${employeeId}`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useFetchPaymentCompanyHistory = (companyId) => {
  return useQuery(
    ['getPaymentCompanyHistory',companyId], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-company-history/${companyId}`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};


export const useFetchPaymentHistoryReceivingMoney= () => {
  return useQuery(
    ['getPaymentHistoryReceivingMoney'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-payment-history-receiving-money`;
      const { result } = await GET(endpoint);
      return result?.payment;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useMutationUpdateDarf = ()=> {
  return useMutation(["update-darf"],
    async (payload) => {
      const endPoin = `/api/v2/payment/update-darf-payment-detail`;
      await POST(endPoin,payload);
    },
  );
};

export const useMutationInsertPaymentTransection = ()=> {
  return useMutation(["insert-payment-transection"],
    async (payload) => {
      const endPoin = `/api/v2/payment/insert-payment-transection`;
      await POST(endPoin,payload);
    },
  );
};

export const useFetchBankAll = () => {
  return useQuery(
    ['getBankAll'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-bank-all`;
      const { result } = await GET(endpoint);
      return result?.bank;
    },
    {
      cacheTime: 0,
    }
  );
};

export const useFetchBankByCompany = () => {
  return useQuery(
    ['getBank-by-company'], // Pass parameters to the query key
    async () => {
      const endpoint = `/api/v2/payment/get-bank-by-company`;
      const { result } = await GET(endpoint);
      return result?.bank;
    },
    {
      cacheTime: 0,
    }
  );
};



export const useMutationAddBank = ()=> {
  return useMutation(["addBank"],
    async (payload) => {
      const endPoin = `/api/v2/payment/create-bank`;
      await POST(endPoin,payload);
    },
  );
};

export const useMutationUpdateBank = () => {
  return useMutation(["updateBank"],
    async (payload) => {
      const {id,body} =  payload
      const endPoin = `/api/v2/payment/update-bank/${id}`;
      await PUT(endPoin,body);
    }
  );
};

export const useMutationDeleteBank = ()=> {
  return useMutation(["deleteBank"],
    async (payload) => {
      const endPoin = `/api/v2/payment/delete-bank/${payload}`;
      await DELETE(endPoin);
    }
  );
};