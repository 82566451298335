import React, { useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Select ,Button,Divider,Space} from 'antd';
import { provinces } from "../../data/provinces";
import UploadImageAvatar from "../Upload/UploadImage/UploadImageAvatar";
import { useStateContext } from '../../contexts/ContextProvider';

function FormEmployee({ formRef, onFinish, uploadImage, initialValues = {}, edit = false }) {
  const {  getProfile } = useStateContext();

  const positionsOneTotickets = ['CEO', 'IT', 'Admin', 'Manager', 'Marketing']; // เพิ่มตำแหน่งที่นี่
  const positionCustomer = ['คนขายตั๋ว','คนตรวจบัตร','คนขับเรือ']
  const positions = getProfile?.role === 'customer'? positionCustomer:positionsOneTotickets

  const [items, setItems] = useState(positions);
  const [name, setName] = useState('');
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (name) {
      setItems((prevItems) => [...prevItems, name]);
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  
  // const permissionsList = ['dashboard','payments', 'employees', 'vehicles', 'schedule', 'ticket', 'business'];
   // Access permissions options


   const permissionsCustomer = [
    { label: 'ข้อมูล', value: 'dashboard' },
    { label: 'การเงิน', value: 'payments' },
    { label: 'พนักงาน', value: 'employees' },
    { label: 'ยานพาหนะ', value: 'vehicles' },
    { label: 'รอบโดยสาร', value: 'schedule' },
    { label: 'บัตรโดยสาร', value: 'ticket' },
    { label: 'ข้อมูลธุรกิจ', value: 'business' }
  ];

  const permissionsOne2Tickets = [
    { label: 'ข้อมูล', value: 'dashboard' },
    { label: 'การเงิน', value: 'payments' },
    { label: 'พนักงาน', value: 'employees' },
    { label: 'ลูกค้า', value: 'customer' },
    { label: 'สถานที่', value: 'location' },
    { label: 'ยานพาหนะ', value: 'vehicles' },
    { label: 'รอบโดยสาร', value: 'schedule' },
    { label: 'บัตรโดยสาร', value: 'ticket' },
    { label: 'รีวิว', value: 'review' },
    { label: 'รหัสส่วยลด', value: 'promotion' },

  ];


  const permissions = getProfile?.role === 'customer' ? permissionsCustomer:permissionsOne2Tickets


  return (
    <Form name="picture" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <div className='flex justify-center items-center'>
        <Form.Item name="picture">
          <UploadImageAvatar
            title='รูปภาพ'
            key={1}
            onUpload={uploadImage}
            file={initialValues?.picture}
          />
        </Form.Item>
      </div>
      <div className='grid lg:gap-x-5 lg:grid-cols-1 '>
        <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="name" label="ชื่อ" rules={[{ required: true, message: 'กรุณาระบุชื่อ' }]}>
          <Input
            id="tradername"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="ชื่อ"
          />
        </Form.Item>
        <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="surname" label="นามสกุล" rules={[{ required: true, message: 'กรุณาระบุนามสกุล' }]}>
          <Input 
            id="tradername"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="นามสกุล"
          />
        </Form.Item>
        
        {/* เพิ่ม Select สำหรับเลือกจังหวัด */}
        {/* <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="province" label="จังหวัด" rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}>
          <Select
            placeholder="เลือกจังหวัด"
            className="w-full h-10 rounded-lg" 
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {provinces.map(province => (
              <Select.Option key={province} value={province}>
                {province}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}

        {/* เพิ่ม Select สำหรับเลือกตำแหน่งพนักงาน */}
        
        <Form.Item
      className="block mb-2 text-sm font-medium text-gray-700"
      name="position"
      label="ตำแหน่งพนักงาน"
      rules={[{ required: true, message: 'กรุณาเลือกตำแหน่ง' }]}
    >
      <Select
        placeholder="เลือกตำแหน่ง"
        className="w-full h-10 rounded-lg"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder="กรุณากรอกตำแหน่งใหม่"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()} // Prevent closing dropdown on keydown
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                เพิ่มตำแหน่ง
              </Button>
            </Space>
          </>
        )}
        options={items.map((item) => ({
          label: item,
          value: item,
        }))}
      />
    </Form.Item>
 
        {/* <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="position" label="ตำแหน่งพนักงาน" rules={[{ required: true, message: 'กรุณาเลือกตำแหน่ง' }]}>
          <Select
            placeholder="เลือกตำแหน่ง"
            className="w-full h-10 rounded-lg"
          >
            {positions.map(position => (
              <Select.Option key={position} value={position}>
                {position}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="email" label="อีเมล์" rules={[{ required: true, message: 'กรุณาระบุอีเมล์' }]}>
          <Input
            id="tradername"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="อีเมล์"
          />
        </Form.Item>


        {/* Access Permissions Section */}
        <div>
          <p className='font-bold mt-3 ml-2'>สิทธิ์การเข้าถึง</p>
          <div className='grid grid-cols-4 gap-x-4'>
            {permissions?.map(permission => (
              <Form.Item name={permission?.value} valuePropName="checked" key={permission?.value}>
                <Checkbox>{permission?.label}</Checkbox>
              </Form.Item>
            ))}
          </div>
        </div>

    
        { getProfile?.role === 'customer'? 
        <div>
          <p className=' font-bold mt-3 ml-2'>สิทธิ์ใช้งาน App</p>
          <div className='grid grid-cols-3 ]'>
          <Form.Item name="scan" valuePropName="checked">
           <Checkbox>สแกนตั๋ว</Checkbox>
          </Form.Item>
          <Form.Item name="saleTickets" valuePropName="checked">
            <Checkbox>ขายตั๋ว</Checkbox>
          </Form.Item>
        </div>
       </div>
        :null}
       

     
        {/* <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="password" label="รหัสผ่าน" rules={[{ required: edit ? false : true, message: 'กรุณาระบุรหัสผ่าน' }]}>
          <Input.Password
            id="tradername"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            placeholder="รหัสผ่าน"
          />
        </Form.Item> */}
      </div>
    </Form>
  );
}

export default FormEmployee;


// import React from 'react';
// import { Form, Input, Select, Checkbox, InputNumber } from 'antd';
// import {provinces} from "../../data/provinces";
// import UploadImageAvatar from "../Upload/UploadImage/UploadImageAvatar";

// function FormEmployee({ formRef, onFinish, uploadImage,initialValues={},edit=false }) {
//   console.log(edit)

//   return (
//     <Form name="picture" ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
//        <div className='flex justify-center items-center'>
//         <Form.Item name="picture">
//           <UploadImageAvatar
//           title='รูปภาพ'
//             key={1}
//             onUpload={uploadImage}
//             file={initialValues?.picture}
//           />
//           </Form.Item>
//        </div>
//       <div className='grid lg:gap-x-5 lg:grid-cols-1 '>

       
//         <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="name" label="ชื่อ" rules={[{ required: true, message: 'กรุณาระบุชื่อ' }]}>
//         <Input
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="ชื่อ"
            
//         />
//         </Form.Item>
//         <Form.Item className='className="block mb-2 text-sm font-medium text-gray-700' name="surname" label="นามสกุล" rules={[{ required: true, message: 'กรุณาระบุนามสกุล' }]}>
//           <Input 
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="นามสกุล"
//             />
//         </Form.Item>
//            {/* เพิ่ม Select สำหรับเลือกจังหวัด */}
//         <Form.Item className="block mb-2 text-sm font-medium text-gray-700" name="province" label="จังหวัด" rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}>
//           <Select
//             placeholder="เลือกจังหวัด"
//             className="w-full h-10 rounded-lg" 
//             showSearch
//             optionFilterProp="children"
//             filterOption={(input, option) =>
//               option.children.toLowerCase().includes(input.toLowerCase())
//             }
//           >
//             {provinces.map(province => (
//               <Select.Option key={province} value={province}>
//                 {province}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>

//         <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="email" label="อีเมล์" rules={[{ required: true, message: 'กรุณาระบุอีเมล์' }]}>
//           <Input
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="อีเมล์"
//           />
//         </Form.Item>
//        <Form.Item className='block mb-2 text-sm font-medium text-gray-700' name="password" label="รหัสผ่าน" rules={[{ required: edit? false:true, message: 'กรุณาระบุรหัสผ่าน' }]}>
//           <Input.Password
//             id="tradername"
//             className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
//             placeholder="รหัสผ่าน"
//           />
//         </Form.Item>


       
//       </div>
     
//     </Form>
//   );
// }

// export default FormEmployee;
