import React, { useState } from 'react';
import { Table, Space, Popconfirm, Select, DatePicker ,Tag,Input,Button} from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { formatDateToYMD } from '../../function/formatDateToYMD';
import DatePickerProvider from "../DatePickerProvider/DatePickerProvider";
import dayjs from 'dayjs';
const { Option } = Select;
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const { RangePicker } = DatePicker;

const TicketsTable = ({ data, onEdit, onCancel ,onDateChange}) => {
  const { currentColor,getProfile } = useStateContext();

  const isCustomer = getProfile?.role === 'customer'

  // State for filtering
  const [filteredCompany, setFilteredCompany] = useState('ทั้งหมด');
  const [filteredProvince, setFilteredProvince] = useState('ทั้งหมด');

  // Get unique companies and provinces for filters
  const companies = ['ทั้งหมด', ...new Set(data?.map((item) => item.nameCompany))];
  const provinces = ['ทั้งหมด', ...new Set(data?.map((item) => item.province))];
  const [searchBookingId, setSearchBookingId] = useState(''); // State for searching bookingId

  // Filtered data
  const filteredData = data?.filter((item) => {
    return (
      (filteredCompany === 'ทั้งหมด' || item.nameCompany === filteredCompany) &&
      (filteredProvince === 'ทั้งหมด' || item.province === filteredProvince) &&
      (searchBookingId === '' || item.bookingID.toString().includes(searchBookingId)) // Filter by bookingId
    );
  });

  const columns = [
    { 
      title: 'หมายเลขการจอง',
      align: 'center',  
      dataIndex: 'bookingID',
      key: 'bookingID',
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'ต้นทาง', 
      align: 'center',
      dataIndex: 'originLocation', 
      key: 'originLocation', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'ปลายทาง', 
      align: 'center',
      dataIndex: 'destinationLocation', 
      key: 'destinationLocation', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'รอบโดยสาร', 
      align: 'center',
      dataIndex: 'startTime', 
      key: 'startTime', 
      onHeaderCell: () => ({ style: headerStyle }),
      render: (text) => dayjs(text, 'HH:mm:ss').format('HH:mm'),
    },
    { 
      title: 'วันโดยสาร', 
      align: 'center',
      dataIndex: 'date', 
      key: 'date', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'วันที่จอง', 
      align: 'center',
      dataIndex: 'createDate', 
      key: 'createDate', 
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm'),
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'จำนวนผู้โดยสาร', 
      align: 'center',
      dataIndex: 'totalPassengers', 
      key: 'totalPassengers', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'ยอดเงิน', 
      align: 'center',
      dataIndex: 'costTotlePrice', 
      key: 'costTotlePrice', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'จองตั๋วจาก', 
      align: 'center',
      dataIndex: 'createFrom', 
      key: 'createFrom', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'สร้างโดย', 
      align: 'center',
      dataIndex: 'createBy', 
      key: 'createBy', 
      onHeaderCell: () => ({ style: headerStyle })
    },
    { 
      title: 'สถานะ', 
      align: 'center', 
      dataIndex: 'status', 
      key: 'status',
      fixed: 'right',
      onHeaderCell: () => ({ style: headerStyle }),
      render: (status) => {
        let color = '';
        switch (status) {
          case 'ยกเลิก':
            color = 'red'; // สีสำหรับสถานะยกเลิก
            break;
          case 'ไม่แสดงตัว':
              color = 'red'; // สีสำหรับสถานะยกเลิก
              break;
          case 'ยืนยัน':
            color = 'green'; // สีสำหรับสถานะยืนยัน
            break;
          case 'รอ':
            color = 'orange'; // สีสำหรับสถานะรอ
            break;
          default:
            color = 'gray'; // สีสำหรับสถานะที่ไม่ตรงเงื่อนไข
        }
        return <Tag color={color}>{status}</Tag>;
      }
    },
    isCustomer ? {
      title: 'การจัดการ',
      align: 'center',
      key: 'cancel',
      fixed: 'right',
      render: (_, record) => (
        <Popconfirm
          title="คุณแน่ใจหรือไม่ว่าต้องการยกเลิก?"
          onConfirm={() => onCancel(record)}
          okText="ใช่"
          cancelText="ไม่"
        >
          <a className='text-[#F05323] ' >ยกเลิก</a>
        </Popconfirm>
      ),
    } : {}
  ];
  return (
    <div>
      <div className="mb-4 flex flex-col md:flex-row justify-between w-full">
        <div className="flex-grow mb-2 md:mb-0 w-full">
          <Input
            className=' md:w-[250px]'
            placeholder="ค้นหา หมายเลขการจอง"
            value={searchBookingId}
            onChange={(e) => setSearchBookingId(e.target.value)}
          />
        </div>
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4  md:justify-end w-full">
          <DatePickerProvider>
            <RangePicker
              defaultValue={[dayjs(), dayjs().endOf('month')]}
              format="YYYY-MM-DD"
              onChange={onDateChange}
              className="mb-2 md:mb-0 md:w-[250px]" 
            />
          </DatePickerProvider>
          <Select
            placeholder="เลือกบริษัท"
            onChange={(value) => setFilteredCompany(value)}
            value={filteredCompany}
            className="mb-2 md:mb-0 md:w-[170px]"
          >
            {companies.map((company) => (
              <Option key={company} value={company}>
                {company}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="เลือกจังหวัด"
            onChange={(value) => setFilteredProvince(value)}
            value={filteredProvince}
            className="mb-2 md:mb-0 md:w-[170px]"
          >
            {provinces.map((province) => (
              <Option key={province} value={province}>
                {province}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className='overflow-x-auto'>
        <Table
          className="border-1 rounded-lg border-gray-200"
          dataSource={genNumberTable(filteredData)}
          columns={columns}
          pagination={{ pageSize: 8 }}
          rowKey={'id'}
          scroll={{ x: 'max-content' }}  
        />
      </div>
    </div>
  );
  
};
//

export default TicketsTable;
