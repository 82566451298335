import React from 'react';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import { boatData } from '../../data/mock/boat';
import FromSchedule from '../../components/Form/FromSchedule';

function Edit({ formRef,data}) {
  return (
    <div className='mt-5 pb-3'>
      <FromSchedule  formRef={formRef} initialValues={data} edit={true} />
    </div>
  );
}
export default Edit