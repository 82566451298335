import React from 'react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import 'dayjs/locale/en';  // Import English locale
import thTH from 'antd/lib/locale/th_TH';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// ตั้งค่าภาษาให้กับ dayjs และเปิดใช้งาน plugins ที่จำเป็น
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const DatePickerProvider = ({ children }) => {

  // เปลี่ยน locale ตามภาษาที่เลือก
  const locale =  thTH;
  dayjs.locale('th');

  return (
    <ConfigProvider locale={locale}>
      {children}
    </ConfigProvider>
  );
};

export default DatePickerProvider;
