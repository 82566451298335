import React from 'react';
import { Table, Space, Tag,Popconfirm } from 'antd';
import { genNumberTable } from '../../function/genNumberTable';
import { useStateContext } from '../../contexts/ContextProvider';
import dayjs from 'dayjs';
import { formatDateToYMD } from '../../function/formatDateToYMD';
const headerStyle = {
  color: '#525252', // เปลี่ยนสีตัวอักษรตามที่คุณต้องการ
};

const PaymentHistoryTable = ({ data, onEdit, onDelete,loading }) => {
  const { currentColor} = useStateContext();
  const columns = [
    { 
      title: 'ลำดับ',
      align: 'center',  
      width: 60, 
      dataIndex: 'number',
      key: 'number',
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    {
      title: 'จำนวนตั๋ว',
      children: [
        {
          title: 'ทั่วไป',
          align: 'center', 
          width:100,
          dataIndex: 'generalTickets',
          key: 'generalTickets',
          onHeaderCell: () =>{return {style:headerStyle}}
        },
        {
          title: 'ท้องถิ่น',
          align: 'center', 
          width:100,
          dataIndex: 'localTickets',
          key: 'localTickets',
          onHeaderCell: () =>{return {style:headerStyle}}
        },
        
      ],
      onHeaderCell: () =>{return {style:headerStyle}}
    },
    { 
      title: 'จำนวนตั๋วทั้งหมด', 
      align: 'center', 
      dataIndex: 'totleTicket', 
      key: 'totleTicket',
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'ยอดเงินที่ชำระ', 
      align: 'center', 
      dataIndex: 'totalCost', 
      key: 'totalCost',
      onHeaderCell: () =>{return {style:headerStyle}}, 
      render: (text) => text?.toLocaleString(),
    },
    { 
      title: 'วันที่ชำระ', 
      align: 'center', 
      dataIndex: 'createdAt', 
      key: 'createdAt',
      render: (text) => formatDateToYMD(text),
      onHeaderCell: () =>{return {style:headerStyle}} 
    },
    { 
      title: 'สถานะ', 
      align: 'center', 
      dataIndex: 'status', 
      key: 'status',
      render: (status) => (
        <Tag color="green">
          {status}
        </Tag>
      ),
      onHeaderCell: () => ({ style: headerStyle }), 
      fixed: 'right'
    },
  
    // {
    //   title: 'Actions',
    //   fixed: 'right',
    //   key: 'actions',
    //   align: 'center',
    //   render: (record) => (
    //     <Space size="middle">
    //       <div className="text-blue-500 cursor-pointer" onClick={() => onEdit(record)}>
    //         แก้ไข
    //       </div>

    //       <Popconfirm
    //         title="ลบข้อมูลยานพาหนะ"
    //         description="ยืนยันการลบข้อมูลยานพาหนะ ?"
    //         onConfirm={() => onDelete(record)}
    //         okText="ยืนยัน"
    //         cancelText="ยกเลิก"
    //         okButtonProps={{ style: { backgroundColor: currentColor, borderColor: currentColor } }}
    //       >
    //           <div className="text-red-500 cursor-pointer" >
    //             ลบ
    //           </div>

    //        </Popconfirm>
      
    //     </Space>
    //   ),
    //   onHeaderCell: () => ({ style: headerStyle }),
    // }
  ];

  return( 
    <div className='overflow-x-auto'>
  <Table 
    className=' border-1 rounded-lg border-gray-200'   
    loading={loading}    
    dataSource={genNumberTable(data)} 
    columns={columns}  
    pagination={{ pageSize: 10}} 
    rowKey={'transectionId'} 
    scroll={{ x: 'max-content' }}  
    />
    </div>)
};

export default PaymentHistoryTable;
