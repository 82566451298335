import React,{useState,useRef} from 'react';
import { Header } from '../../components';
import { Button,message } from 'antd';
import PromotionTable from '../../components/Table/PromotionTable';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal } from "../../components/Modal/Modal";
import { useModal } from "react-modal-hook";
import { useFetchBoatAll} from "../../hooks/useFetchBoat";
import { useFetchPromotionAll,useMutationPromotion,useMutationDeletePromotion,useMutationUpdatePromotion} from "../../hooks/useFetchPromotion";
import { transformData} from './response';
import Add from './Add';
import Edit from './Edit';

const Promotion = () => {
  const titlePage = 'โปรโมชั่น ส่วนลด'
  const formAddRef = useRef(null); 
  const formEditRef = useRef(null); 
  const { currentColor,btnColor} = useStateContext();
  const [dataEdit, setDataEdit] = useState(null);
  const { data: promotionAll, isLoading:isLoadingPromotionAll,refetch:refetchPromotionAll} = useFetchPromotionAll();
  // const { isLoading:isLoadingScheduleByID,mutate:mutateScheduleByID} = useMutationScheduleByID();
  const { isLoading:isLoadingUpdatePromotion,mutate:mutateUpdatePromotion} = useMutationUpdatePromotion();
  const { isLoading:isLoadingDeletePromotion, mutate:fetchDeletePromotion} = useMutationDeletePromotion();
  const { isLoading:isLoadingAddPromotion , mutate:fetchAddPromotion} = useMutationPromotion();


  //#region usehook model
  const [showAddModal, hideAddModal] = useModal(() => {   
    const handleOnOk = async () => {
      try {
        const value =  await formAddRef.current.validateFields();

        let body = await transformData(value);
        console.log(body)
        await fetchAddPromotion(body, {
          onSuccess: async() => {
            refetchPromotionAll()
            message.success('เพิ่มข้อมูลสำเร็จ')
            await formAddRef.current.submit();
            hideAddModal();
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
 
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };
    return (
      <Modal title={`เพิ่ม ${titlePage}`} okText='สร้าง' cancelText='ยกเลิก' width={700}  open={true}  onOk={handleOnOk} onCancel={hideAddModal} loading={isLoadingAddPromotion}>
        <Add formRef={formAddRef} />
      </Modal>
    )
  });

  const [showEditModal, hideEditModal] = useModal(() => {
    const handleOnOk = async () => {
      try {
        const value =  await formEditRef.current.validateFields();

        const id = dataEdit.id
        const body = transformData(value)
        const payload = {id, body}
        await mutateUpdatePromotion(payload, {
          onSuccess:async() => {
            refetchPromotionAll()
            message.success('แก้ไขข้อมูลสำเร็จ')
            await formEditRef.current.submit();
            hideEditModal()
          },
          onError: (error) => {
            message.error(error.message)
          },
        })

       
      } catch (error) {
        console.error('Form validation error:', error);
      }
    
    }
    return (
      <Modal title={`แก้ไข ${titlePage}`} okText='ตกลง' cancelText='ยกเลิก' width={700}   open={true} onOk={handleOnOk} onCancel={hideEditModal} loading={isLoadingUpdatePromotion}>
        <Edit formRef={formEditRef} data = {dataEdit} />
      </Modal>
  )},[dataEdit]);
  //#endregion

  const handleEdit = (record) => {
    console.log(record)

    setDataEdit(record);
    showEditModal()
  };

  const handleDelete = (record) => {
    console.log('Delete action for:', record);

    fetchDeletePromotion(record.id,{
      onSuccess: () => {
        message.success('ลบข้อมูลสำเร็จ')
        refetchPromotionAll()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-xl">
      <div className='flex justify-between'>
        <Header category="App" title={`${titlePage}`}  />
        {/* <Button onClick={showAddModal} color="white" bgColor={currentColor} text="เพิ่ม" size="[8px]" borderRadius="8px" high="[30px]" width="[90px]" /> */}
          <Button onClick={showAddModal} style={{ backgroundColor: btnColor ,color:'#FFFF'}}>เพิ่ม</Button>
      </div>
      <PromotionTable data={promotionAll} onEdit={handleEdit} onDelete={handleDelete} loading = {isLoadingPromotionAll} />
    </div>
  );
};

export default Promotion;
